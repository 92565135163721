import { CreativeLayer, CreativeLayerType } from "@core/models/creative.types";

import { Observable, Subject, Subscription, interval, of } from "rxjs";
import { catchError, debounceTime, map } from "rxjs/operators";

import { CreativesEditService } from "./creatives-edit.service";
import {
  CreativesSocketService,
  ImageResponse,
  ImageSpec,
} from "./creatives-socket.service";
import { arrayToCSSImage } from "./image-helpers";
import { calculateLayerHash } from "./layout-helpers";

interface LayerRefreshRequest {
  layer: CreativeLayer;
  identifier: string;
  hash: string;
}

export interface CreativeLiveServiceConfig {
  editor: CreativesEditService;
  company: number;
  account: number;
  proctor?: number;
  session?: string;
  previewFilters?: any;
  resolution?: [number, number];
  fakeProducts?: boolean;
  autoRefresh?: boolean;
}

export class CreativesLiveService {
  editor: CreativesEditService;
  socketService: CreativesSocketService;

  // Contains waiting requests/observables
  movableImageRequest = null;
  layerImageRequests = [];

  private subscriptions = new Map<string, Subscription>();

  private refreshedLayerSubject = new Subject<string>();
  refreshedLayer$ = this.refreshedLayerSubject.asObservable();

  private errorRaisedSubject = new Subject<any>();
  errorRaised$ = this.errorRaisedSubject.asObservable();

  raisedError = null;

  queuedHashes: Record<string, string> = {};
  // Keep track of failed layer hashes we do not wish to retry
  failedHashes = new Set<string>();

  layerImages: Record<string, ImageSpec> = {};

  // // We check layers continously
  // layerCheckInterval: NodeJS.Timeout;
  // // We delay the actual check a bit
  // // to not spam during user typing etc.
  layerCheckTimeout: NodeJS.Timeout;

  layerCheckInterval$ = interval(500);
  layerCheckIntervalSubscription?: Subscription;

  layerCheckSubject = new Subject();

  resolution;

  company: number;
  account: number;
  proctor?: number;

  previewFilters: any = {};
  productOffset = 0;

  autoRefresh = true;
  isWaitingForUpdate = false;

  fakeProducts = false;

  private connectionChanged = new Subject<any>();
  connectionChanged$ = this.connectionChanged.asObservable();

  constructor(config: CreativeLiveServiceConfig) {
    this.editor = config.editor;
    this.company = config.company;
    this.account = config.account;
    this.proctor = config.proctor;
    this.previewFilters = config.previewFilters ?? {};
    this.resolution = config.resolution ?? [500, 500];
    this.fakeProducts = config.fakeProducts ?? false;
    this.autoRefresh = config.autoRefresh ?? true;

    // this.layerImages = Array(this.editor.sequence.layers).fill(undefined);

    // Init the socket connection
    this.socketService = new CreativesSocketService({
      company: this.company,
      account: this.account,
      proctor: this.proctor,
      session: config.session,
    });

    this.socketService.onConnect.subscribe((conn) => {
      this.connectionChanged.next({
        connected: true,
        info: conn,
      });
      this.invalidateLayers();
      this.subscribeToEvents();
      this.checkLayers();
      // this.startLayerChecking();
    });

    this.socketService.onDisconnect.subscribe((disconn) => {
      this.connectionChanged.next({
        connected: false,
        info: disconn,
      });
      this.unsubscribeEvents();
      // this.stopLayerChecking();
      this.invalidateLayers();
    });

    this.layerCheckSubject.pipe(debounceTime(200)).subscribe({
      next: (_) => {
        this._checkLayers();
      },
    });
  }

  destroy() {
    this.unsubscribeEvents();
    this.socketService.destroy();
  }

  loadInitialImages(images: Record<number, { image: string }>) {
    // Inserts cached / initial images
    for (const key of Object.keys(images)) {
      if (!this.layerImages[key]) {
        this.layerImages[key] = {
          hash: "",
          css: `url('${images[key].image}')`,
          response: null,
        };
      }
    }
  }

  // startLayerChecking() {
  //   this.stopLayerChecking();
  //   this.layerCheckIntervalSubscription = this.layerCheckInterval$.subscribe({
  //     next: (_) => this.checkLayers(),
  //   });
  // }

  // stopLayerChecking() {
  //   this.layerCheckIntervalSubscription?.unsubscribe();
  //   this.layerCheckIntervalSubscription = null;
  // }

  _checkLayers() {
    let layersToRender: LayerRefreshRequest[] = Array.from(
      this.editor.iterateLayers(true),
    )
      .map((layer) => {
        const ident = layer.identifier;

        const layerImage = this.layerImages[ident];
        const imageHash = layerImage ? layerImage.hash : undefined;

        const renderHash = this.queuedHashes[ident];
        const currentHash = calculateLayerHash(layer);

        const cellType =
          layer.type === CreativeLayerType.GROUP
            ? ""
            : this.editor.getCellInLayer(layer, 1, 1).type;

        if (cellType === "") {
          this.layerImages[ident] = undefined;
        }

        if (!renderHash && this.failedHashes.has(currentHash)) {
          return undefined;
        }

        if (
          currentHash !== imageHash &&
          currentHash !== renderHash &&
          cellType !== ""
        ) {
          this.queuedHashes[ident] = currentHash;
          return {
            layer: layer,
            identifier: ident,
            hash: currentHash,
          };
        }
        return undefined;
      })
      .filter((l) => l != null);

    layersToRender = layersToRender.filter(
      (ltr) => this.editor.getCellInLayer(ltr.layer, 1, 1).type !== "",
    );

    this.updateWaitStatus();
    if (layersToRender.length > 0) {
      this.refreshLayers(...layersToRender).subscribe();
    }
  }

  checkLayers() {
    if (!this.autoRefresh) {
      return;
    }

    this.layerCheckSubject.next(true);
    // if (this.layerCheckTimeout) {
    //   clearTimeout(this.layerCheckTimeout);
    // }
    // this.layerCheckTimeout = setTimeout(() => this._checkLayers(), 200);
  }

  private subscribeToEvents() {
    this.unsubscribeEvents();

    this.subscriptions.set(
      "settingsChanged",
      this.editor.settingsChanged$.subscribe((change) => {
        if (this.raisedError) {
          this.raisedError = null;
          this.errorRaisedSubject.next(null);
        }
        // Flush errors and try again
        this.failedHashes.clear();
        this.checkLayers();
      }),
    );

    this.subscriptions.set(
      "cellChanged",
      this.editor.cellChanged$.subscribe((cellChange) => {
        // Flush errors and try again
        this.failedHashes.clear();
        this.checkLayers();
      }),
    );

    this.subscriptions.set(
      "layerChanged",
      this.editor.layerChanged$.subscribe((lch) => {
        // Flush errors and try again
        this.failedHashes.clear();
        this.checkLayers();
      }),
    );

    this.subscriptions.set(
      "layerMoved",
      this.editor.layerMoved$.subscribe((layerMove) => {
        // Move around images
        // moveItemInArray(
        //   this.layerImages,
        //   layerMove.fromIndex,
        //   layerMove.toIndex
        // );
      }),
    );

    this.subscriptions.set(
      "layerAddRemove",
      this.editor.layerAddRemove$.subscribe((addRemove) => {
        if (addRemove.action === "ADD") {
          // this.layerImages.unshift(undefined);
        } else {
          // this.layerImages.splice(addRemove.index, 1);
        }
      }),
    );
  }

  private unsubscribeEvents() {
    this.subscriptions.forEach(function (sub, key) {
      sub.unsubscribe();
    });

    this.subscriptions = new Map();
  }

  layersRefreshed(
    layerRequests: LayerRefreshRequest[],
    result: { number: ImageResponse },
  ) {
    layerRequests.forEach((lreq) => {
      const layerIdent = lreq.identifier;

      // Do not use result if not the hash we are waiting for
      if (lreq.hash != this.queuedHashes[layerIdent]) {
        return;
      }

      const hash = this.queuedHashes[layerIdent];
      delete this.queuedHashes[layerIdent];

      const r = result[layerIdent];
      if (r == null) {
        return;
      }

      this.layerImages[layerIdent] = {
        hash: hash,
        css: arrayToCSSImage(r.image, r.format),
        response: r,
      };
      this.refreshedLayerSubject.next(layerIdent);
    });

    if (this.failedHashes.size === 0) {
      this.raisedError = null;
      this.errorRaisedSubject.next(null);
    }

    this.updateWaitStatus();
    return result;
  }

  getEmptyImageIndexes() {
    return Array.from(this.editor.iterateLayers())
      .filter((layer) => this.layerImages[layer["identifier"]] == null)
      .map((layer) => layer["identifier"]);
  }

  updateWaitStatus() {
    this.isWaitingForUpdate = Object.keys(this.queuedHashes).length > 0;
  }

  private _refreshLayers(
    layerRequests: LayerRefreshRequest[],
  ): Observable<any> {
    const layerIdentifiers = layerRequests.map((l) => l.identifier);
    return this.socketService
      .renderLayer(
        this.editor.getSpec(),
        layerIdentifiers,
        this.resolution,
        this.previewFilters,
        this.productOffset,
        this.fakeProducts,
      )
      .pipe(
        map((res) => this.layersRefreshed(layerRequests, res)),
        catchError((err) => {
          this.raisedError = err;
          if (err.info) {
            this.errorRaisedSubject.next(err.info);
          }

          layerRequests.forEach((lreq) => {
            // Remove from queue as we wish to retry
            const qh = this.queuedHashes[lreq.identifier];
            if (lreq.hash === qh) {
              delete this.queuedHashes[lreq.identifier];
              this.failedHashes.add(qh);
            }
          });
          this.updateWaitStatus();
          return of("Caught render error");
        }),
      );
  }

  renderSingleImage() {
    return this.socketService.renderSingleImage(
      this.editor.getSpec(),
      this.resolution,
      this.previewFilters,
      this.productOffset,
      this.fakeProducts,
    );
  }

  public refreshLayers(
    ...layerRequests: LayerRefreshRequest[]
  ): Observable<any> {
    return this._refreshLayers(layerRequests);
  }

  public invalidateCurrentLayer() {
    const idents =
      this.editor.layer != null ? [this.editor.selectedLayerIdentifier] : [];
    if (this.editor.layer?.type === "group") {
      this.editor.layer.layers.forEach((layer) =>
        idents.push(layer.identifier),
      );
    }
    if (this.editor.multiSelectedLayerIdentifiers.size > 0) {
      this.editor
        .multiSelectedLayers()
        .forEach((layer) => idents.push(layer.identifier));
    }
    idents.forEach((ident) => {
      const li = this.layerImages[ident];
      if (!li) {
        return;
      }
      li.hash = null;
    });
  }

  public invalidateLayers() {
    for (const [ident, img] of Object.entries(this.layerImages)) {
      if (!img) {
        continue;
      }
      img.hash = null;
    }

    // this.layerImages.forEach((li) => {
    //   if (!li) {
    //     return;
    //   }
    //   li.hash = null;
    // });
    this.queuedHashes = {};
    this.failedHashes.clear();
  }

  public getLayerCSS(layer: string): string {
    const l = this.layerImages[layer];
    return l == null ? "none" : l.css;
  }

  getLayerSpec(layer: string): ImageSpec | null {
    return this.layerImages[layer] ?? null;
  }

  public getLayerResponse(layer: string): ImageResponse {
    const l = this.layerImages[layer];
    return l == null ? null : l.response;
  }

  public emptyImages() {
    this.layerImages = {};
    this.queuedHashes = {};
  }

  public reset() {
    this.autoRefresh = false;
    this.invalidateLayers();
    this.layerImages = {};
    this.queuedHashes = {};

    this.socketService.resetSession().subscribe((_) => {
      this.autoRefresh = true;
      this.checkLayers();
    });
  }
}
