import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { CreativesSocketService } from "@core/services/creatives/creatives-socket.service";
import {
  IntroOutroEffectPreset,
  PresetEffect,
  effects,
} from "@core/services/creatives/preset-effects";

import { CStepperComponent } from "@theme/@confect/wrappers/stepper/stepper.component";
import { EmptyStateInfo } from "@theme/components/empty-state/empty-state.component";

import { Subject, debounceTime } from "rxjs";

const PRESETS = effects;

export interface AnimationConfig {
  effect_in?: {
    effect: string;
    settings: IntroOutroEffectPreset;
    preset?: string;
  };
  effect?: { effect: string; settings: any }[];
  effect_out?: {
    effect: string;
    settings: IntroOutroEffectPreset;
    preset?: string;
  };
}

@Component({
  selector: "ngx-effects",
  templateUrl: "./effects.component.html",
})
export class EffectsComponent implements OnInit {
  @ViewChild("stepper") stepper: CStepperComponent;
  _animationConfig: AnimationConfig;
  @Input() set animationConfig(to: AnimationConfig) {
    const config = JSON.parse(JSON.stringify(to));

    if (config.effect_in == null && config.effect_out != null) {
      this.selected = "effect_out";
    }
    this._animationConfig = config;
    this.validateCalc();
    this.loading = false;
    this.setup();
  }

  selected: string = "effect_in";
  @Input() set select(to: string) {
    if (!to) {
      return;
    }
    this.selected = to;
    this.setup();
  }

  @Input() duration: number = 1;

  @Input() socket: CreativesSocketService | null = null;

  @Output() save = new EventEmitter<AnimationConfig>();

  changeSubject = new Subject<true>();

  getEffectName = {
    effect_in: "intro",
    effect: "middle",
    effect_out: "outro",
  };

  emptyStateInfo: EmptyStateInfo = {
    description: "There is currently no animation applied to the is layer.",
    title: "No animations applied",
    img: "assets/images/empty-states/designs.png",
  };

  loading = true;

  presets = PRESETS;

  previewUrl: string | null;

  showPresets = false;

  valid = true;

  previewLoading = false;

  constructor() {}

  ngOnInit(): void {
    this.changeSubject
      .asObservable()
      .pipe(debounceTime(500))
      .subscribe({ next: (_) => this.preview() });
    this.changeSubject.next(true);
  }

  setup() {
    if (this._animationConfig == null) {
      return;
    }
    if (this._animationConfig[this.selected] != null) {
      return;
    }
    this.addEffect();
  }

  remove(i?: number) {
    if (this.selected === "effect") {
      this._animationConfig.effect.splice(i, 1);
      if (this._animationConfig.effect.length > 0) {
        return;
      }
    }
    delete this._animationConfig[this.selected];
    const appliedAnimations = Object.keys(this._animationConfig);
    if (appliedAnimations.includes("effect_in")) {
      this.selected = "effect_in";
      return;
    }
    if (appliedAnimations.includes("effect_out")) {
      this.selected = "effect_out";
      return;
    }
    if (appliedAnimations.includes("effect")) {
      this.selected = "effect";
      return;
    }
    delete this.selected;
  }
  add() {
    this._animationConfig.effect.push({ effect: "", settings: {} });
  }

  addEffect() {
    this.showPresets = true;

    this._animationConfig[this.selected] = {
      effect: this.getEffectName[this.selected],
      settings: {},
    };
  }
  setPreset(preset: PresetEffect) {
    const effect = this.getEffectName[this.selected];
    const animationCopy = JSON.parse(JSON.stringify(this._animationConfig));
    animationCopy[this.selected] = {
      effect: effect,
      settings: { ...preset.preset[effect] },
      preset: preset.name,
    };
    this._animationConfig = animationCopy;
    this.changeSubject.next(true);
    // this.stepper.back();
    this.showPresets = false;
  }

  preview() {
    if (!this.socket) {
      return;
    }
    this.previewLoading = true;
    this.socket
      .renderEffectVideo(
        {
          effect: this._animationConfig?.effect,
          effect_in: this._animationConfig?.effect_in,
          effect_out: this._animationConfig?.effect_out,
        },
        [300, 300],
      )
      .subscribe({
        next: (v) => {
          if (v.type === "result") {
            this.previewUrl = v.result;
            this.previewLoading = false;
          }
        },
      });
  }

  validateCalc() {
    const valid =
      (this._animationConfig?.effect_in?.settings.duration ?? 0) +
        (this._animationConfig?.effect_out?.settings.duration ?? 0) <=
      this.duration;

    this.valid = valid;
  }
}
