<ng-container>
  <confect-button
    type="white"
    [disabled]="value === null"
    (click)="resetClicked.emit()"
    >RESET</confect-button
  >

  <div class="container">
    @for (layer of layers; track layer) {
      <div class="m-0">
        <div
          class="mb-1 py-2 px-4 rounded-lg flex items-center hover:bg-gray-300"
          [ngClass]="{
            'bg-primary-500 hover:bg-primary-500':
              value?.layer === layer.identifier,
            'opacity-30	cursor-not-allowed':
              layer.config.grid_config[0].type !== targetType,
          }"
          (click)="layerClicked(layer)"
        >
          @if (cellTypeIcon[layer.config.grid_config[0].type] != null) {
            <div>
              <confect-icon
                [icon]="cellTypeIcon[layer.config.grid_config[0].type]"
                iconClass="mr-2 text-gray-400 group-hover:text-gray-500 text-xl"
              />
            </div>
          }
          <h6
            class="text-sm"
            [class.text-white]="value?.layer === layer.identifier"
          >
            {{ layer.name }}
          </h6>
          <div class="flex justify-end flex-grow">
            <confect-button
              size="xsmall"
              [disabled]="
                value?.layer === layer.identifier ||
                layer.config.grid_config[0].type !== targetType
              "
              (click)="layerClicked(layer)"
              >SELECT</confect-button
            >
          </div>
        </div>
      </div>
    }
  </div>
</ng-container>
