<div class="flex flex-col items-center space-y-2 mb-5">
  @for (not of _colorTracker; track not; let i = $index) {
    <div class="flex items-center space-x-2">
      <div class="w-9 h-9 border-2 border-black rounded-full">
        <input
          class="w-full h-full rounded-full"
          [colorPicker]="_colorPickerValues[i]"
          (colorPickerChange)="colorPickerChange($event, i)"
          [style.background]="_colorPickerValues[i]"
          [cpOutputFormat]="'rgba'"
          [cpPosition]="'left'"
          [cpUseRootViewContainer]="true"
          [cpAlphaChannel]="'always'"
        />
      </div>
      <div class="w-16">
        <confect-input
          [ngModel]="_value.steps[i]"
          (ngModelChange)="stepChange($event, i)"
        />
      </div>
      @if (_value.colors.length > 2) {
        <confect-icon-button
          (click)="removeColor(i)"
          icon="clear_circle_outlined"
          iconClass="text-3xl"
        />
      }
    </div>
  }

  <confect-icon-button
    (click)="addColor()"
    icon="add_circle_outlined"
    iconClass="text-3xl"
  />
  @if (parentSelection != null) {
    <confect-button (click)="browseGradients()" type="white">
      Gradient Library
    </confect-button>
  }
</div>

<ng-template #gradientWindow class="z-40" let-close="close">
  <div class="w-[60vw] h-[60vh]">
    <ngx-creatives-gradient-library
      [gradientType]="parentSelection"
      (gradientSelected)="gradientPresetSelected($event); close()"
    />
  </div>
</ng-template>
