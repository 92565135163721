import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { LINEAR_GRADIENTS } from "./data/linear_gradients_formatted";
import { RADIAL_GRADIENTS } from "./data/radial_gradients_formatted";

@Component({
  selector: "ngx-creatives-gradient-library",
  templateUrl: "./creatives-gradient-library.component.html",
  styleUrls: ["./creatives-gradient-library.component.scss"],
})
export class CreativesGradientLibraryComponent implements OnInit {
  @Input() gradientType: string;
  @Output() gradientSelected = new EventEmitter<any>();

  gradients = [];
  data = [];

  shouldLoadNextPage = true;
  per_page = 20;
  page = 1;

  constructor() {}

  ngOnInit() {
    this.loadRawData();
    this.loadNextPage();
  }

  loadRawData() {
    this.data =
      this.gradientType === "linear" ? LINEAR_GRADIENTS : RADIAL_GRADIENTS;
  }

  loadNextPage() {
    const start = (this.page - 1) * this.per_page;
    let end = start + this.per_page;

    if (end > this.data.length) {
      end = this.data.length;
      this.shouldLoadNextPage = false;
    }

    this.gradients.push(...this.data.slice(start, end));
    this.page++;
  }

  onScroll(event) {
    // Infinite scroll check
    if (event.target.scrollHeight - event.target.scrollTop < 1000) {
      if (this.shouldLoadNextPage) {
        this.loadNextPage();
      }
    }
  }

  selectGradientClicked(gradient) {
    this.gradientSelected.emit(gradient);
  }

  toCSSGradient(gradient) {
    const steps = gradient["steps"];
    const colors = gradient["colors"];

    let res = "";

    for (let i = 0; i < steps.length; i++) {
      if (i !== 0) {
        res = res + ", ";
      }
      const color = "rgba(" + colors[i].join(",") + ")";
      const step = String(parseFloat(steps[i])) + "%";
      const point = color + " " + step;
      res = res + point;
    }

    res = this.gradientType + "-gradient(" + res + ")";
    return res;
  }
}
