import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { AssetsService } from "@core/api/assets.service";

@Component({
  selector: "ngx-product-asset-spec",
  templateUrl: "./product-asset-spec.component.html",
  styleUrls: ["./product-asset-spec.component.scss"],
})
export class ProductAssetSpecComponent implements OnInit {
  @Input() specItem: any = null;
  @Input() value: any = null;

  @Output() valueChange = new EventEmitter<any>();

  OPTIONS = [];

  constructor(private assetsService: AssetsService) {}

  transform(input: string): string {
    const parts = input.split(".");

    if (parts.length === 1) {
      return input;
    }

    // Remove first entry
    parts.splice(0, 1);

    // Return rest joined at .
    return parts.join(".");
  }

  ngOnInit() {
    this.assetsService.getProductAssetPackages().subscribe((res) => {
      this.OPTIONS = res.map((o) => {
        o["display"] = `${o.name} (${this.transform(o.key)})`;
        return o;
      });
      this.value["display"] =
        `${this.value.name} (${this.transform(this.value.key)}) (Deleted)`;

      this.OPTIONS.push(this.value);
    });
  }

  selectionChanged(to) {
    const elem = this.OPTIONS.find((o) => o.id === to);
    if (!elem) {
      return;
    }

    this.valueChange.emit({
      id: elem.id,
      name: elem.name,
      key: elem.key,
    });
  }
}
