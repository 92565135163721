<div
  #innerCanvas
  id="innerCanvas"
  class="relative h-full w-full"
  (contextmenu)="$event.preventDefault()"
  (click)="!isSelecting() && deselect()"
  drag
  [dragMove]="moveSelect"
  [dragStart]="startSelect"
  [dragEnd]="endSelect"
  [sensitivity]="10"
  [dragDisable]="isEditing() || disableDragSelect()"
>
  <div
    #boxGrid
    class="absolute"
    [style.left.px]="
      canvas.width / 2 - (canvas.innerResX / 2) * zoomFactor() + scrollOffset.x
    "
    [style.top.px]="
      canvas.height / 2 - (canvas.innerResY / 2) * zoomFactor() + scrollOffset.y
    "
    [style.width.px]="canvas.innerResX * zoomFactor()"
    [style.height.px]="canvas.innerResY * zoomFactor()"
  >
    <div
      class="border-primary border-2 absolute z-[500] box-content"
      [ngClass]="{ hidden: !isSelecting(), block: isSelecting() }"
      [style.top.%]="selectBox().y"
      [style.left.%]="selectBox().x"
      [style.height.%]="selectBox().height"
      [style.width.%]="selectBox().width"
    >
      <div class="w-full h-full bg-primary opacity-10"></div>
    </div>
    @for (box of boxes(); track box.layer; let i = $index) {
      <div
        class="bounding-box box-content"
        [ngClass]="{
          'hover-bounding-box': showHoverState()[i],
          balloon:
            box.layer === _editor.layer?.identifier ||
            box.layer === 'multiSelection',
          'drag grid-item':
            (box.layer === _editor.layer?.identifier ||
              box.layer === 'multiSelection') &&
            focusBox() == null,
          group: box.children != null,
          hidden: !showBoxes()[i],
        }"
        cdkDrag
        (cdkDragStarted)="dragStart($event)"
        (cdkDragMoved)="dragMove($event)"
        (cdkDragEnded)="dragEnd($event)"
        [cdkDragDisabled]="
          box.layer !== currentLayer()?.layer && box.layer !== 'multiSelection'
        "
        [cdkDragConstrainPosition]="snapMove"
        (mouseenter)="mouseEnter(box.layer)"
        (mouseleave)="mouseLeave(box.layer)"
        [style.left.px]="(box.box.x / 100) * canvas.innerResX * zoomFactor()"
        [style.top.px]="(box.box.y / 100) * canvas.innerResY * zoomFactor()"
        [style.width.px]="
          (box.box.width / 100) * canvas.innerResX * zoomFactor() - 4
        "
        [style.height.px]="
          (box.box.height / 100) * canvas.innerResY * zoomFactor() - 4
        "
        [style.z-index]="
          box.layer === currentLayer()?.layer
            ? 402
            : showChildLayers()[i] || highlightBoxes()[i]
              ? 401
              : 399 - i
        "
        (click)="selectLayer($event, box.layer); grouptool.load()"
        (dblclick)="
          this.currentLayer()?.layer === box.layer && selectBelow($event)
        "
        (contextmenu)="
          (this.currentLayer() == null ||
            this.currentLayer().layer !== box.layer) &&
            selectLayer($event, box.layer);
          context.emit($event)
        "
      >
        @if (
          (box.layer === _editor.layer?.identifier ||
            box.layer === "multiSelection") &&
          focusBox() == null
        ) {
          <div class="relative w-full h-full">
            <div class="resize-l" (mousedown)="beginResize('l', $event)">
              <div
                class="handle-l transition-colors delay-50 shadow-md"
                [ngClass]="{ hidden: box.box.height < 15 / zoomFactor() }"
              ></div>
            </div>

            <div class="resize-r" (mousedown)="beginResize('r', $event)">
              <div
                class="handle-r transition-colors delay-50 shadow-md"
                [ngClass]="{ hidden: box.box.height < 15 / zoomFactor() }"
              ></div>
            </div>

            <div class="resize-t" (mousedown)="beginResize('t', $event)">
              <div
                class="handle-t transition-colors delay-50 shadow-md"
                [ngClass]="{ hidden: box.box.width < 15 / zoomFactor() }"
              ></div>
            </div>

            <div class="resize-b" (mousedown)="beginResize('b', $event)">
              <div
                class="handle-b transition-colors delay-50 shadow-md"
                [ngClass]="{ hidden: box.box.width < 15 / zoomFactor() }"
              ></div>
            </div>

            <div
              class="resize-tl transition-colors delay-50 shadow-md"
              (mousedown)="beginResize('tl', $event)"
            ></div>
            <div
              class="resize-tr transition-colors delay-50 shadow-md"
              (mousedown)="beginResize('tr', $event)"
            ></div>
            <div
              class="resize-bl transition-colors delay-50 shadow-md"
              (mousedown)="beginResize('bl', $event)"
            ></div>
            <div
              class="resize-br transition-colors delay-50 shadow-md"
              (mousedown)="beginResize('br', $event)"
            ></div>
            <div class="drag drag-handle" cdkDragHandle></div>
          </div>
        }
      </div>
    }
  </div>
  @for (snapline of _snaplines(); track snapline.id) {
    <div
      class="absolute bg-secondary"
      [style.left.px]="snapline.left + scrollOffset.x"
      [style.top.px]="snapline.top + scrollOffset.y"
      [style.width.px]="snapline.width"
      [style.height.px]="snapline.height"
      [style.z-index]="400"
      [ngClass]="{
        hidden: !snapline.show || !this.isEditing(),
        block: snapline.show && this.isEditing(),
      }"
    ></div>
  }

  <div
    uploadImage
    (fileDropped)="onFileDropped($event)"
    class="grid-container layer-preview-container cursor-default"
    [style.left.px]="
      canvas.width / 2 - (canvas.innerResX / 2) * zoomFactor() + scrollOffset.x
    "
    [style.top.px]="
      canvas.height / 2 - (canvas.innerResY / 2) * zoomFactor() + scrollOffset.y
    "
    [style.width.px]="canvas.innerResX * zoomFactor()"
    [style.height.px]="canvas.innerResY * zoomFactor()"
    #grid
  >
    <!-- Edit mode -->
    @if (showGuidelines && isEditing()) {
      @for (a of _guidelines; track a) {
        <div class="v-guideline -translate-x-[1px]" [style.left.%]="a"></div>
      }
      @for (a of _guidelines; track a) {
        <div class="h-guideline -translate-y-[1px]" [style.top.%]="a"></div>
      }
    }
    @for (img of images(); track img.layer; let i = $index) {
      @if (showMovableLayer || movableLayers()[img.layer] === false) {
        <div
          class="bounding-box"
          [ngClass]="{ hidden: !showBoxes()[i] }"
          cdkDrag
          [style.background-image]="img.css | safe: 'style'"
          [style.left.px]="
            ((currentImage()?.layer === img.layer
              ? currentImage().image.x
              : img.image.x) /
              100) *
              canvas.innerResX *
              zoomFactor() -
            2
          "
          [style.top.px]="
            ((currentImage()?.layer === img.layer
              ? currentImage().image.y
              : img.image.y) /
              100) *
              canvas.innerResY *
              zoomFactor() -
            2
          "
          [style.width.px]="
            ((currentImage()?.layer === img.layer
              ? currentImage().image.width
              : img.image.width) /
              100) *
            canvas.innerResX *
            zoomFactor()
          "
          [style.height.px]="
            ((currentImage()?.layer === img.layer
              ? currentImage().image.height
              : img.image.height) /
              100) *
            canvas.innerResY *
            zoomFactor()
          "
          [style.opacity.px]="
            focusBox() != null &&
            focusBox()?.layer !== img.layer &&
            !focusBox().children?.includes(img.layer)
              ? 30
              : 100
          "
          [style.z-index]="img.layer === focusBox()?.layer ? 300 : 299 - i"
        ></div>
      }
    }
  </div>
</div>
<ngx-balloon-menu
  #balloon
  [style.z-index]="1000"
  [parent]="currentLayerElement()"
  [show]="
    currentLayer()?.children != null &&
    !this.isEditing() &&
    this.focusBox() == null
  "
  [space]="-10"
>
  <ngx-creatives-multi-select-tools
    #grouptool
    [bar]="true"
    [editor]="_editor"
  />
</ngx-balloon-menu>
