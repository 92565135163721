<confect-select
  [name]="_specItem.key"
  [title]="_specItem.display"
  [placeholder]="'None'"
  [clearable]="_specItem.default.effect == null"
  placeholder="None"
  [options]="limitedEffects"
  [ngModel]="value.effect"
  (ngModelChange)="effectChanged($event)"
  bindValue="function"
  bindLabel="name"
  [searchable]="_specItem.default.effect == null"
  [icon]="_specItem.icon"
/>

<!-- Show the rest of settings if effectType is selected -->
@if (value.effect != null && value.effect !== "") {
  <ngx-spec-handler
    [specGroup]="{ items: effectSpecs }"
    [specSettings]="value.settings"
    [editor]="editor"
    [parentSelection]="value.effect"
    [helpEnabled]="helpEnabled"
  />
}
