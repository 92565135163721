import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "ngx-string-spec",
  templateUrl: "./string-spec.component.html",
  styleUrls: ["./string-spec.component.scss"],
})
export class StringSpecComponent implements OnInit {
  @Input() specItem: any = null;
  @Input() value: any = null;
  @Output() valueChange = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}
