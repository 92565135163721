<div class="pb-3">
  <!-- [editor]="Editor"
  [config]="ckConfig"
  [data]="value"
  (change)="onChange($event)" -->

  @if (isLoaded) {
    <ckeditor
      #editor
      [config]="ckConfig"
      [data]="value"
      (change)="onChange($event)"
      (ready)="editorReady($event)"
    />
  }
</div>
