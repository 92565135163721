import { DOCUMENT } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { DesignsService } from "@core/api/designs.service";
import { SharedElementsService } from "@core/api/shared-elements.service";
import { CreativeLayer, CreativeLayerType } from "@core/models/creative.types";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import { CreativesLiveService } from "@core/services/creatives/creatives-live.service";

import { CDropdownItem } from "@theme/@confect/components/dropdown/dropdown.component";
import { CModalComponent } from "@theme/@confect/components/modal/modal.component";
import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

import { Subscription } from "rxjs";

export interface LayerNodeInfo {
  layer: CreativeLayer;
  parent?: CreativeLayer;
}

export interface DropAction {
  target: LayerNodeInfo;
  action?: string;
}

@Component({
  selector: "ngx-creatives-edit-timeline-layer-handler",
  templateUrl: "./creatives-edit-timeline-layer-handler.component.html",
  styleUrls: ["./creatives-edit-timeline-layer-handler.component.scss"],
})
export class CreativesEditTimelineLayerHandlerComponent
  implements OnInit, OnDestroy
{
  public cellTypeIcon = {
    text: {
      icon: "text",
    },
    media: {
      icon: "photo_landscape_outlined",
    },
    product: {
      icon: "label_outlined",
    },
    bg: {
      icon: "circle_outlined",
    },
    product_asset: {
      icon: "blend_tool",
    },
    undefined: {
      icon: "error_outlined",
    },
  };

  _layers: CreativeLayer[] = [];
  _live: CreativesLiveService;

  dropdownItems: CDropdownItem[][] = [
    [
      {
        title: "Rename",
        action: "rename",
        icon: "edit",
      },
      {
        title: "Duplicate",
        action: "duplicate",
        icon: "copy",
      },
      {
        title: "Delete",
        action: "delete",
        icon: "delete_outlined",
      },
    ],
  ];

  groupDropdownItems: CDropdownItem[][] = [
    [
      {
        title: "Rename",
        action: "rename",
        icon: "edit",
      },
      {
        title: "Duplicate",
        action: "duplicate",
        icon: "copy",
      },
      {
        title: "Save as Element",
        action: "save",
        icon: "save_outlined",
      },
      {
        title: "Delete",
        action: "delete_group",
        icon: "delete_outlined",
      },
    ],
  ];

  @Input() set layers(to: CreativeLayer[]) {
    this._layers = to;
    if (to) {
      this.clearDragDrop();
    }
  }

  @Input() editor: CreativesEditService;
  @Input() set live(to: CreativesLiveService) {
    this._live = to;
  }
  @Input() specs: any;
  @Input() raisedError: any;
  @Input() showTimeline = true;

  @Input() allowProductActions = true;

  @Output() context = new EventEmitter();

  @ViewChild("layerModal") private layerModal: CModalComponent;

  dropTargetIds = [];
  nodeLookup: Record<string, LayerNodeInfo> = {};
  dropActionTodo: DropAction = null;

  layerChangeSubscription: Subscription = null;

  images;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private designsService: DesignsService,
    private popUpService: CPopupModalService,
    private elementService: SharedElementsService,
  ) {}

  ngOnInit(): void {
    this._live.invalidateLayers();

    this.layerChangeSubscription = this.editor.layerAddRemove$.subscribe(
      (_action) => {
        this.clearDragDrop();
        this.clearDragInfo();
      },
    );
    this._live.refreshedLayer$.subscribe(() => {
      this.getImages();
    });
  }

  getImages() {
    if (this.editor == null || this._live == null) {
      return;
    }
    const layers = Array.from(this.editor.iterateLayers(true));
    this.images = {};
    layers
      .filter((layer) => layer.type !== "group")
      .forEach((layer) => {
        this.images[layer.identifier] = this._live.getLayerSpec(
          layer.identifier,
        );
      });
  }

  ngOnDestroy(): void {
    this.layerChangeSubscription?.unsubscribe();
    this.layerChangeSubscription = null;
  }

  dropdownItemSelected(layer: CreativeLayer, item: CDropdownItem) {
    this.layerHoverEvent(null);

    switch (item.action) {
      case "rename":
        this.editor.editLayer(layer.identifier, this.popUpService);
        break;
      case "duplicate":
        this.editor.duplicateLayer(layer.identifier);
        break;
      case "delete":
        this.editor.removeLayer(layer.identifier);
        break;
      case "delete_group":
        this.editor.removeLayer(layer.identifier);
        break;
      case "save":
        this.elementService.saveLayerGroup(
          layer,
          this.designsService,
          this.popUpService,
        );
        break;
    }
  }

  layerSettings(layer: CreativeLayer) {
    this.editor.selectLayer(layer.identifier);
    this.layerModal.show();
  }

  clearDragDrop() {
    this.nodeLookup = {};
    this.dropTargetIds = [];
    this.prepareDragDrop(this._layers);
  }

  prepareDragDrop(nodes: CreativeLayer[], parent = null) {
    nodes.forEach((node) => {
      this.nodeLookup[node.identifier] = {
        layer: node,
        parent: parent,
      };
      if (node.type === "group") {
        // Only groups have lists of children
        this.dropTargetIds.push(node.identifier);
        this.prepareDragDrop(node.layers, node);
      }
    });
  }

  dragMoved(event, draggedLayer: CreativeLayer) {
    const e = this.document.elementFromPoint(
      event.pointerPosition.x,
      event.pointerPosition.y,
    );

    if (!e) {
      this.clearDragInfo();
      return;
    }

    const container = e.classList.contains("node-item")
      ? e
      : e.closest(".node-item");

    if (!container) {
      this.clearDragInfo();
      return;
    }

    const targetInfo = this.nodeLookup[container.getAttribute("data-id")];

    this.dropActionTodo = {
      target: targetInfo,
    };

    // Inside is not allowed if we drag a whole group
    const draggedIsGroup = draggedLayer.type === CreativeLayerType.GROUP;

    // If both target and dragged are groups, we do not allow inside
    const bothGroups =
      targetInfo.layer.type === CreativeLayerType.GROUP && draggedIsGroup;

    // We do not allow inside on targets in a group
    const targetHasParent = targetInfo.parent != null;

    const noInside = bothGroups || targetHasParent || draggedIsGroup;

    // Do not allow to put groups before/after/inside something that has a parent (is in a group)
    if (draggedIsGroup && targetHasParent) {
      this.clearDragInfo(true);
      return;
    }

    /*
    Logic for before/after target differs, as we do not allow groups in groups
    If target has a parent we split the area in two. If not parent we split in thirds
    Where middle parts means inside target
    */

    // Rect of layer we are dragging near
    const targetRect = container.getBoundingClientRect();

    // Where we will insert before target
    const beforeSplit = targetRect.height / (noInside ? 2 : 3);

    // Where we will insert after target
    const afterSplit = noInside ? beforeSplit : beforeSplit * 2;

    const yPos = event.pointerPosition.y;
    const rectTop = targetRect.top;

    if (yPos - rectTop <= beforeSplit) {
      // before
      this.dropActionTodo["action"] = "before";
    } else if (yPos - rectTop > afterSplit) {
      // after
      this.dropActionTodo["action"] = "after";
    } else {
      // inside
      this.dropActionTodo["action"] = "inside";
    }

    this.showDragInfo();
  }

  drop(event) {
    if (!this.dropActionTodo) return;

    const draggedItemId = event.item.data;
    const draggedLayerInfo = this.nodeLookup[draggedItemId];

    this.editor.history.beginSpecSnapshot();
    this.commitDropAction(draggedLayerInfo, this.dropActionTodo);
    this.editor.history.finishSpecSnapshot();

    this.editor.flushEmptyLayerGroups();

    // Recalculate drag/drop lookups
    this.clearDragDrop();
    this.clearDragInfo(true);
  }

  private removeInsertLayerNode(
    targetNodeInfo: LayerNodeInfo,
    action: string = null,
    insertion: LayerNodeInfo = null,
  ) {
    // Determine if we should use a group list or the outer layer list
    const targetList = targetNodeInfo.parent
      ? targetNodeInfo.parent.layers
      : this._layers;

    // Find index of the target
    const targetNodeIndex = targetList.findIndex(
      (layer) => layer.identifier === targetNodeInfo.layer.identifier,
    );

    // If we have action + insertion we insert before/after the target node
    if (action && insertion) {
      targetList.splice(
        action == "before" ? targetNodeIndex : targetNodeIndex + 1,
        0,
        insertion.layer,
      );
    } else {
      // No action + insertion we just remove target
      targetList.splice(targetNodeIndex, 1);
    }
  }

  commitDropAction(draggedLayerInfo: LayerNodeInfo, action: DropAction) {
    let target = action.target;

    if (action.action !== "inside") {
      // Remove dragged layer from old position
      this.removeInsertLayerNode(draggedLayerInfo);

      // Insert dragged at target position
      this.removeInsertLayerNode(target, action.action, draggedLayerInfo);

      // Tell that we moved a layer
      this.editor.layerMoved(draggedLayerInfo.layer);
      return;
    }

    // Drop inside existing group
    if (target.layer.type === "group") {
      const innerLayerList = target.layer.layers;
      // Insert after last element
      const newTarget = innerLayerList[innerLayerList.length - 1];

      if (!newTarget) {
        this.removeInsertLayerNode(draggedLayerInfo);
        innerLayerList.push(draggedLayerInfo.layer);
        this.editor.layerMoved(draggedLayerInfo.layer);
        return;
      }

      target = this.nodeLookup[newTarget.identifier];
      action.target = target;
      action.action = "after";

      // Send modified action through
      this.commitDropAction(draggedLayerInfo, action);
      return;
    }

    // Create a new group and insert after target
    const layerGroup = {
      parent: null,
      layer: this.editor.emptyLayer(null, CreativeLayerType.GROUP),
    };
    this.removeInsertLayerNode(target, "after", layerGroup);

    // Remove layers from old positions
    this.removeInsertLayerNode(target);
    this.removeInsertLayerNode(draggedLayerInfo);

    // Insert layers inside new group
    layerGroup.layer.layers.push(target.layer, draggedLayerInfo.layer);

    // Notify changes
    this.editor.layerAddRemoveSource.next({
      action: "add",
      ident: layerGroup.layer.identifier,
    });
    this.editor.layerMoved(target.layer);
    this.editor.layerMoved(draggedLayerInfo.layer);
  }

  showDragInfo() {
    this.clearDragInfo();
    if (this.dropActionTodo) {
      this.document
        .getElementById("node-" + this.dropActionTodo.target.layer.identifier)
        .classList.add("drop-" + this.dropActionTodo.action);
    }
  }

  clearDragInfo(dropped = false) {
    if (dropped) {
      this.dropActionTodo = null;
    }
    this.document
      .querySelectorAll(".drop-before")
      .forEach((element) => element.classList.remove("drop-before"));
    this.document
      .querySelectorAll(".drop-after")
      .forEach((element) => element.classList.remove("drop-after"));
    this.document
      .querySelectorAll(".drop-inside")
      .forEach((element) => element.classList.remove("drop-inside"));
  }

  layerHoverEvent(layer: CreativeLayer) {
    this.editor.layerHoverLayerListSubject.next(layer);
  }

  selectLayer(event: MouseEvent, layer: CreativeLayer) {
    // If CMD/CTRL + click + has already selected layer, add to multi select
    this.editor.selectLayerClick(event, layer.identifier);
  }
}
