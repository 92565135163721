export enum EffectSpeedCurve {
  QuadEaseInOut = "QuadEaseInOut",
  QuadEaseIn = "QuadEaseIn",
  QuadEaseOut = "QuadEaseOut",
  CubicEaseInOut = "CubicEaseInOut",
  CubicEaseIn = "CubicEaseIn",
  CubicEaseOut = "CubicEaseOut",
  QuarticEaseInOut = "QuarticEaseInOut",
  QuarticEaseIn = "QuarticEaseIn",
  QuarticEaseOut = "QuarticEaseOut",
  QuinticEaseInOut = "QuinticEaseInOut",
  QuinticEaseIn = "QuinticEaseIn",
  QuinticEaseOut = "QuinticEaseOut",
  SineEaseInOut = "SineEaseInOut",
  SineEaseIn = "SineEaseIn",
  SineEaseOut = "SineEaseOut",
  CircularEaseIn = "CircularEaseIn",
  CircularEaseInOut = "CircularEaseInOut",
  CircularEaseOut = "CircularEaseOut",
  ExponentialEaseInOut = "ExponentialEaseInOut",
  ExponentialEaseIn = "ExponentialEaseIn",
  ExponentialEaseOut = "ExponentialEaseOut",
  ElasticEaseIn = "ElasticEaseIn",
  ElasticEaseInOut = "ElasticEaseInOut",
  ElasticEaseOut = "ElasticEaseOut",
  BackEaseIn = "BackEaseIn",
  BackEaseInOut = "BackEaseInOut",
  BackEaseOut = "BackEaseOut",
  BounceEaseIn = "BounceEaseIn",
  BounceEaseInOut = "BounceEaseInOut",
  BounceEaseOut = "BounceEaseOut",
}

export const EffectSpeedCurveDisplay: Record<EffectSpeedCurve, string> = {
  [EffectSpeedCurve.QuadEaseInOut]: "Quad Ease InOut",
  [EffectSpeedCurve.QuadEaseIn]: "QuadEaseIn",
  [EffectSpeedCurve.QuadEaseOut]: "QuadEaseOut",
  [EffectSpeedCurve.CubicEaseInOut]: "CubicEaseInOut",
  [EffectSpeedCurve.CubicEaseIn]: "CubicEaseIn",
  [EffectSpeedCurve.CubicEaseOut]: "CubicEaseOut",
  [EffectSpeedCurve.QuarticEaseInOut]: "QuarticEaseInOut",
  [EffectSpeedCurve.QuarticEaseIn]: "QuarticEaseIn",
  [EffectSpeedCurve.QuarticEaseOut]: "QuarticEaseOut",
  [EffectSpeedCurve.QuinticEaseInOut]: "QuinticEaseInOut",
  [EffectSpeedCurve.QuinticEaseIn]: "QuinticEaseIn",
  [EffectSpeedCurve.QuinticEaseOut]: "QuinticEaseOut",
  [EffectSpeedCurve.SineEaseInOut]: "SineEaseInOut",
  [EffectSpeedCurve.SineEaseIn]: "SineEaseIn",
  [EffectSpeedCurve.SineEaseOut]: "SineEaseOut",
  [EffectSpeedCurve.CircularEaseIn]: "CircularEaseIn",
  [EffectSpeedCurve.CircularEaseInOut]: "CircularEaseInOut",
  [EffectSpeedCurve.CircularEaseOut]: "CircularEaseOut",
  [EffectSpeedCurve.ExponentialEaseInOut]: "ExponentialEaseInOut",
  [EffectSpeedCurve.ExponentialEaseIn]: "ExponentialEaseIn",
  [EffectSpeedCurve.ExponentialEaseOut]: "ExponentialEaseOut",
  [EffectSpeedCurve.ElasticEaseIn]: "ElasticEaseIn",
  [EffectSpeedCurve.ElasticEaseInOut]: "ElasticEaseInOut",
  [EffectSpeedCurve.ElasticEaseOut]: "ElasticEaseOut",
  [EffectSpeedCurve.BackEaseIn]: "BackEaseIn",
  [EffectSpeedCurve.BackEaseInOut]: "BackEaseInOut",
  [EffectSpeedCurve.BackEaseOut]: "BackEaseOut",
  [EffectSpeedCurve.BounceEaseIn]: "BounceEaseIn",
  [EffectSpeedCurve.BounceEaseInOut]: "BounceEaseInOut",
  [EffectSpeedCurve.BounceEaseOut]: "BounceEaseOut",
};
// EffectSpeedCurveDisplay[EffectSpeedCurve.QuadEaseInOut] = Display

export interface IntroOutroEffectPreset {
  duration: number;
  offset?: number;
  speed_curve?: EffectSpeedCurve;
  initial_scale?: number;
  initial_opacity?: number;
  initial_rotation?: number;
  initial_position_x?: number;
  initial_position_y?: number;
  slide_in_direction?: "top" | "left" | "right" | "bottom";
}

export enum EffectKey {
  INTRO = "intro",
  OUTRO = "outro",
}

export interface PresetEffect {
  name: string;
  key: string;
  effectDurationIn: number; // Not sure what this is, ignore for now
  effectDurationOut: number; // Not sure what this is, ignore for now
  layerOrderingIn: string; // Not needed right now, ignore for now
  layerOrderingOut: string; // Not needed right now, ignore for now
  layerSpacing: "even" | "uneven";
  preset: {
    [EffectKey.INTRO]: IntroOutroEffectPreset;
    [EffectKey.OUTRO]: IntroOutroEffectPreset;
  };
}

export const effects: PresetEffect[] = [
  {
    name: "Fade",
    key: "fade",
    effectDurationIn: 0,
    effectDurationOut: 0,
    layerOrderingIn: "none",
    layerOrderingOut: "none",
    layerSpacing: "uneven",
    preset: {
      outro: {
        offset: 0,
        duration: 0.4,
        speed_curve: EffectSpeedCurve.QuadEaseInOut,
        initial_scale: 100,
        initial_opacity: 0,
        initial_rotation: 0,
        initial_position_x: 0,
        initial_position_y: 0,
        slide_in_direction: null,
      },
      intro: {
        offset: 0,
        duration: 0.4,
        speed_curve: EffectSpeedCurve.QuadEaseInOut,
        initial_scale: 100,
        initial_opacity: 0,
        initial_rotation: 0,
        initial_position_x: 0,
        initial_position_y: 0,
      },
    },
  },
  {
    name: "Slide Right",
    key: "slide_right",
    effectDurationIn: 0,
    effectDurationOut: 0,
    layerOrderingIn: "none",
    layerOrderingOut: "none",
    layerSpacing: "uneven",
    preset: {
      intro: {
        offset: 0,
        duration: 0.4,
        speed_curve: EffectSpeedCurve.QuadEaseInOut,
        initial_scale: 100,
        initial_opacity: 0,
        initial_rotation: 0,
        initial_position_x: 0,
        initial_position_y: 0,
        slide_in_direction: "left",
      },
      outro: {
        offset: 0,
        duration: 0.4,
        speed_curve: EffectSpeedCurve.QuadEaseInOut,
        initial_scale: 100,
        initial_opacity: 0,
        initial_rotation: 0,
        initial_position_x: 0,
        initial_position_y: 0,
        slide_in_direction: "right",
      },
    },
  },
  {
    name: "Slide Left",
    key: "slide_left",
    effectDurationIn: 0,
    effectDurationOut: 0,
    layerOrderingIn: "none",
    layerOrderingOut: "none",
    layerSpacing: "uneven",
    preset: {
      intro: {
        offset: 0,
        duration: 0.4,
        speed_curve: EffectSpeedCurve.QuadEaseInOut,
        initial_scale: 100,
        initial_opacity: 0,
        initial_rotation: 0,
        initial_position_x: 0,
        initial_position_y: 0,
        slide_in_direction: "right",
      },
      outro: {
        offset: 0,
        duration: 0.4,
        speed_curve: EffectSpeedCurve.QuadEaseInOut,
        initial_scale: 100,
        initial_opacity: 0,
        initial_rotation: 0,
        initial_position_x: 0,
        initial_position_y: 0,
        slide_in_direction: "left",
      },
    },
  },
  {
    name: "Rise/Disappear",
    key: "rise_disappear",
    effectDurationIn: 0,
    effectDurationOut: 0,
    layerOrderingIn: "none",
    layerOrderingOut: "none",
    layerSpacing: "uneven",
    preset: {
      intro: {
        offset: 0,
        duration: 0.6,
        speed_curve: EffectSpeedCurve.QuadEaseInOut,
        initial_scale: 100,
        initial_opacity: 0,
        initial_rotation: 0,
        initial_position_x: 0,
        initial_position_y: 5,
      },
      outro: {
        offset: 0,
        duration: 0.6,
        speed_curve: EffectSpeedCurve.QuadEaseInOut,
        initial_scale: 100,
        initial_opacity: 0,
        initial_rotation: 0,
        initial_position_x: 0,
        initial_position_y: 5,
        slide_in_direction: null,
      },
    },
  },
  {
    name: "Smash",
    key: "smash",
    effectDurationIn: 0,
    effectDurationOut: 0,
    layerOrderingIn: "none",
    layerOrderingOut: "none",
    layerSpacing: "uneven",
    preset: {
      outro: {
        offset: 0,
        duration: 0.8,
        speed_curve: EffectSpeedCurve.QuinticEaseInOut,
        initial_scale: 200,
        initial_opacity: 0,
        initial_rotation: -35,
        initial_position_x: 0,
        initial_position_y: 0,
        slide_in_direction: null,
      },
      intro: {
        offset: 0,
        duration: 0.8,
        speed_curve: EffectSpeedCurve.QuinticEaseInOut,
        initial_scale: 200,
        initial_opacity: 0,
        initial_rotation: -35,
        initial_position_x: 0,
        initial_position_y: 0,
        slide_in_direction: null,
      },
    },
  },
  {
    name: "Pop",
    key: "pop",
    effectDurationIn: 0,
    effectDurationOut: 0,
    layerOrderingIn: "none",
    layerOrderingOut: "none",
    layerSpacing: "uneven",
    preset: {
      outro: {
        offset: 0,
        duration: 0.4,
        speed_curve: EffectSpeedCurve.BackEaseIn,
        initial_scale: 0,
        initial_opacity: 100,
        initial_rotation: 0,
        initial_position_x: 0,
        initial_position_y: 0,
        slide_in_direction: null,
      },
      intro: {
        offset: 0,
        duration: 0.4,
        speed_curve: EffectSpeedCurve.BackEaseOut,
        initial_scale: 0,
        initial_opacity: 100,
        initial_rotation: 0,
        initial_position_x: 0,
        initial_position_y: 0,
        slide_in_direction: null,
      },
    },
  },
];
