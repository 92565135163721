import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";

import { ConfectModule } from "@theme/@confect/confect.module";

import { TimelineBlockComponent } from "./timeline/timeline-block/timeline-block.component";
import { TimelineBlockDirective } from "./timeline/timeline-block/timeline-block.directive";
import { TimelineComponent } from "./timeline/timeline.component";

const COMPONENTS = [TimelineComponent, TimelineBlockComponent];
const DIRECTIVES = [TimelineBlockDirective];

@NgModule({
  declarations: [...COMPONENTS, DIRECTIVES],
  imports: [
    CommonModule,
    RouterModule,
    DragDropModule,
    ConfectModule,
    MatTooltipModule,
  ],
  exports: [...COMPONENTS, DIRECTIVES],
})
export class TimelineModule {}
