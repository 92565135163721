import { DragDropModule } from "@angular/cdk/drag-drop";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSliderModule } from "@angular/material/slider";
import { MatTooltipModule } from "@angular/material/tooltip";

import { DesignsComponentModule } from "@components/designs/design-components.module";
import { DragModule } from "@components/drag/drag.module";
import { EffectsModule } from "@components/effects/effects.module";
import { FolderComponentModule } from "@components/folders";
import { TimelineModule } from "@components/timeline/timeline.module";

import { ConfectModule } from "@theme/@confect/confect.module";

import { CKEditorModule } from "ckeditor4-angular";
import { ColorPickerModule } from "ngx-color-picker";

import { ThemeModule } from "../../@theme/theme.module";
import { CreativeEditPropertiesComponent } from "./creatives-edit/components/creative-edit-properties/creative-edit-properties.component";
import { CreativesEditEditorComponent } from "./creatives-edit/components/creatives-edit-editor/creatives-edit-editor.component";
import { CreativesEditGridV1Component } from "./creatives-edit/components/creatives-edit-grid-v1/creatives-edit-grid-v1.component";
import { CreativesEditGridV2Component } from "./creatives-edit/components/creatives-edit-grid-v2/creatives-edit-grid-v2.component";
import { CreativesEditGridV3Component } from "./creatives-edit/components/creatives-edit-grid-v3/creatives-edit-grid-v3.component";
import { CreativesEditGuideComponent } from "./creatives-edit/components/creatives-edit-guide/creatives-edit-guide.component";
import { CreativesEditPopUpEditorOutletComponent } from "./creatives-edit/components/creatives-edit-pop-up-editor/creatives-edit-pop-up-editor-outlet/creatives-edit-pop-up-editor-outlet.component";
import { CreativesEditPopUpEditorComponent } from "./creatives-edit/components/creatives-edit-pop-up-editor/creatives-edit-pop-up-editor.component";
import { CreativesEditTimelineLayerHandlerComponent } from "./creatives-edit/components/creatives-edit-timeline/creatives-edit-timeline-layer-handler/creatives-edit-timeline-layer-handler.component";
import { CreativesEditTimelineComponent } from "./creatives-edit/components/creatives-edit-timeline/creatives-edit-timeline.component";
import { CreativesElementsSidebarComponent } from "./creatives-edit/components/creatives-elements-sidebar/creatives-elements-sidebar.component";
import { CreativesMultiSelectToolsComponent } from "./creatives-edit/components/creatives-multi-select-tools/creatives-multi-select-tools.component";
import { DesignIntroComponent } from "./creatives-edit/components/design-intro/design-intro.component";
import { SharedElementsComponent } from "./creatives-edit/components/shared-elements/shared-elements.component";
import { SharedFontsComponent } from "./creatives-edit/components/shared-fonts/shared-fonts.component";
import { BoolSpecComponent } from "./creatives-edit/specs/inputs/bool-spec/bool-spec.component";
import { ColorSpecComponent } from "./creatives-edit/specs/inputs/color-spec/color-spec.component";
import { EffectsMultipleSpecComponent } from "./creatives-edit/specs/inputs/effects-multiple-spec/effects-multiple-spec.component";
import { EffectsSpecComponent } from "./creatives-edit/specs/inputs/effects-spec/effects-spec.component";
import { GradientSpecComponent } from "./creatives-edit/specs/inputs/gradient-spec/gradient-spec.component";
import { HtmlSpecComponent } from "./creatives-edit/specs/inputs/html-spec/html-spec.component";
import { MediaSpecComponent } from "./creatives-edit/specs/inputs/media-spec/media-spec.component";
import { NumberSpecComponent } from "./creatives-edit/specs/inputs/number-spec/number-spec.component";
import { PercentageSpecComponent } from "./creatives-edit/specs/inputs/percentage-spec/percentage-spec.component";
import { PositionSpecComponent } from "./creatives-edit/specs/inputs/position-spec/position-spec.component";
import { ProductAssetSpecComponent } from "./creatives-edit/specs/inputs/product-asset-spec/product-asset-spec.component";
import { ProductSubscriptionSpecComponent } from "./creatives-edit/specs/inputs/product-subscription-spec/product-subscription-spec.component";
import { RotationSpecComponent } from "./creatives-edit/specs/inputs/rotation-spec/rotation-spec.component";
import { SelectionSpecComponent } from "./creatives-edit/specs/inputs/selection-spec/selection-spec.component";
// Spec input components
import { StringSpecComponent } from "./creatives-edit/specs/inputs/string-spec/string-spec.component";
import { SpecGroupHandlerComponent } from "./creatives-edit/specs/spec-group-handler/spec-group-handler.component";
// Components handling the spec configuration
import { SpecHandlerComponent } from "./creatives-edit/specs/spec-handler/spec-handler.component";
import { CreativesGradientLibraryComponent } from "./creatives-gradient-library/creatives-gradient-library.component";
import { CreativesPreviewWindowComponent } from "./creatives-preview-window/creatives-preview-window.component";
import {
  CreativesRoutingModule,
  routedComponents,
} from "./creatives-routing.module";

@NgModule({
  declarations: [
    ...routedComponents,
    StringSpecComponent,
    NumberSpecComponent,
    PercentageSpecComponent,
    SelectionSpecComponent,
    MediaSpecComponent,
    BoolSpecComponent,
    PositionSpecComponent,
    ColorSpecComponent,
    ProductSubscriptionSpecComponent,
    EffectsSpecComponent,
    SpecHandlerComponent,
    SpecGroupHandlerComponent,
    HtmlSpecComponent,
    GradientSpecComponent,
    CreativesGradientLibraryComponent,
    CreativesPreviewWindowComponent,
    ProductAssetSpecComponent,
    CreativesEditGridV1Component,
    CreativesEditGridV2Component,
    CreativesEditTimelineComponent,
    EffectsMultipleSpecComponent,
    CreativesEditTimelineLayerHandlerComponent,
    SharedElementsComponent,
    CreativesMultiSelectToolsComponent,
    CreativesEditGuideComponent,
    CreativesEditGridV3Component,
    SharedFontsComponent,
    CreativesElementsSidebarComponent,
    CreativesEditEditorComponent,
    RotationSpecComponent,
    CreativeEditPropertiesComponent,
    DesignIntroComponent,
    CreativesEditPopUpEditorComponent,
    CreativesEditPopUpEditorOutletComponent,
  ],
  imports: [
    CommonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatSliderModule,
    MatIconModule,
    ConfectModule,
    ThemeModule,
    DesignsComponentModule,
    DragDropModule,
    CreativesRoutingModule,
    ColorPickerModule,
    CKEditorModule,
    FolderComponentModule,
    TimelineModule,
    EffectsModule,
    DragModule,
  ],
  exports: [
    CreativesEditTimelineComponent,
    SpecGroupHandlerComponent,
    CreativesMultiSelectToolsComponent,
    CreativesEditGridV3Component,
    CreativesEditEditorComponent,
    CreativesEditPopUpEditorOutletComponent,
  ],
})
export class CreativesModule {}
