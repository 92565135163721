import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { ProductsService } from "@core/api/products.service";
import { CreativeEditorMode } from "@core/models/creative.types";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import { CreativesLiveService } from "@core/services/creatives/creatives-live.service";
import { CMath } from "@core/utils/confect-math";

import { CreativeError } from "../../types/creative-error";
import { ACADEMY_ARTICLES } from "../creatives-edit-editor/articles";

@Component({
  selector: "ngx-creative-edit-properties",
  templateUrl: "./creative-edit-properties.component.html",
  styleUrls: ["./creative-edit-properties.component.scss"],
})
export class CreativeEditPropertiesComponent implements OnInit {
  public readonly CreativeEditorMode = CreativeEditorMode;

  previewFiltersChanged = false;

  academyArticles = ACADEMY_ARTICLES;

  filteredCount: number;
  totalCount: number;

  raisedError: CreativeError = null;

  @Input() editorMode: CreativeEditorMode = CreativeEditorMode.IMAGE;
  @Input() editor: CreativesEditService = null;
  @Input() live: CreativesLiveService = null;
  @Input() specs: any = {};
  @Input() helpEnabled = false;
  @Input() extraSettings = {};
  @Input() showPreview: boolean = true;
  @Input() firstDesign: boolean = false;

  @Input() selectedPreviewFilters = {};
  @Input() previewFilterOptions = [];

  @Input() allowSnap: boolean;
  @Output() allowSnapChange = new EventEmitter<boolean>();

  @Output() selectedPreviewFiltersChange = new EventEmitter<any>();

  @Output() elementPicked = new EventEmitter<any>();

  tabsShowProducts = [
    {
      name: "Elements",
      icon: "sticker_outlined",
      id: "elements",
    },
    {
      name: "Products",
      icon: "filter_outlined",
      id: "product",
      disable: this.firstDesign,
    },
    {
      name: "Inspiration",
      icon: "light_on",
      id: "inspiration",
      disable: this.firstDesign,
    },
  ];
  tabsNoPreview = [
    {
      name: "Elements",
      icon: "sticker_outlined",
      id: "elements",
    },
    {
      name: "Inspiration",
      icon: "light_on",
      id: "inspiration",
      disable: this.firstDesign,
    },
  ];

  constructor(private productsService: ProductsService) {}

  ngOnInit(): void {
    this.productCount();
  }

  productFiltersChanged() {
    this.live.previewFilters = this.selectedPreviewFilters;
    this.selectedPreviewFiltersChange.emit(this.selectedPreviewFilters);
    this.previewFiltersChanged = true;
  }

  nextProduct() {
    if (this.previewFiltersChanged) {
      this.previewFiltersChanged = false;
      this.live.productOffset = 0;
    }
    {
      this.live.productOffset += 1;
    }

    this.live.invalidateLayers();
    this.live.checkLayers();
  }
  prevProduct() {
    if (this.previewFiltersChanged) {
      this.previewFiltersChanged = false;
      this.live.productOffset = 0;
    }
    {
      this.live.productOffset -= 1;
    }

    this.live.invalidateLayers();
    this.live.checkLayers();
  }

  randomProduct() {
    if (this.previewFiltersChanged) {
      this.previewFiltersChanged = false;
      this.live.productOffset = 0;
    }
    {
      this.live.productOffset = CMath.randRange(0, this.filteredCount);
    }

    this.live.invalidateLayers();
    this.live.checkLayers();
  }
  productCount() {
    this.productsService
      .getProductsCount({
        product_filter: this.selectedPreviewFilters,
      })
      .subscribe({
        next: (res) => {
          this.filteredCount = res.READY;
        },
      });
    this.productsService.getProductsCount().subscribe({
      next: (res) => {
        this.totalCount = res.READY;
      },
    });
  }

  applyProductFilter() {
    this.live.productOffset = 0;
    this.live.invalidateLayers();
    this.live.checkLayers();
    this.productCount();
    // this.previewProductsSlideover.hide();
  }

  resetProductFilter() {
    this.selectedPreviewFilters = {};
    this.selectedPreviewFiltersChange.emit(this.selectedPreviewFilters);
    this.productFiltersChanged();
    this.applyProductFilter();
    this.productCount();
  }

  goToUrl(url: string) {
    window.open(url, "_blank");
  }
}
