import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ngx-creatives-new",
  templateUrl: "./creatives-new.component.html",
})
export class CreativesNewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
