@if (!bar) {
  @if (allowSave || allowGrouping) {
    <div class="alignment" style="margin-bottom: 20px">
      <div class="w-full"><h6>Options</h6></div>
      <div class="w-full alignment-buttons">
        @if (allowGrouping) {
          <div (click)="groupLayers()">
            <confect-icon icon="folder_outlined" iconClass="text-xl mr-2" />
            Group
          </div>
        }
        @if (allowSave) {
          <div (click)="saveElement()">
            <confect-icon icon="save_outlined" iconClass="text-xl mr-2" />Save
            As Element
          </div>
        }
      </div>
    </div>
  }

  <div class="alignment" style="margin-bottom: 20px">
    <div class="w-full">
      <h6>Match size</h6>
    </div>
    <div class="flex">
      <div class="w-1/2 alignment-buttons">
        <div (click)="horizontalAlign('width')">
          <confect-icon
            icon="arrow_doublesided_horizontal"
            iconClass="text-xl mr-2"
            >Width</confect-icon
          >
        </div>
      </div>
      <div class="w-1/2 alignment-buttons">
        <div (click)="verticalAlign('height')">
          <confect-icon
            icon="arrow_doublesided_vertical"
            iconClass="text-xl mr-2"
            >Height</confect-icon
          >
        </div>
      </div>
    </div>
  </div>

  <div class="w-full flex flex-col alignment">
    <div class="w-full">
      <h6>Align</h6>
    </div>

    <div class="grid grid-cols-2 alignment-buttons">
      <div (click)="verticalAlign('top')">
        <confect-icon icon="align_to_top_outlined" iconClass="text-xl mr-2"
          >Top</confect-icon
        >
      </div>
      <div (click)="horizontalAlign('left')">
        <confect-icon icon="align_to_left_outlined" iconClass="text-xl mr-2"
          >Left</confect-icon
        >
      </div>

      <div (click)="verticalAlign('middle')">
        <confect-icon icon="align_to_middle_outlined" iconClass="text-xl mr-2"
          >Middle</confect-icon
        >
      </div>

      <div (click)="horizontalAlign('center')">
        <confect-icon icon="align_to_center_outlined" iconClass="text-xl mr-2"
          >Center</confect-icon
        >
      </div>

      <div (click)="verticalAlign('bottom')">
        <confect-icon icon="align_to_bottom_outlined" iconClass="text-xl mr-2"
          >Bottom</confect-icon
        >
      </div>
      <div (click)="horizontalAlign('right')">
        <confect-icon icon="align_to_right_outlined" iconClass="text-xl mr-2"
          >Right</confect-icon
        >
      </div>
    </div>
  </div>
}
@if (bar) {
  <div class="flex divide-x">
    @if (allowSave || allowGrouping) {
      <div class="flex">
        @if (allowSave) {
          <confect-icon-button
            (click)="saveElement()"
            icon="save_outlined"
            class="px-2 py-1"
            iconClass="text-2xl hover:text-primary"
            tooltip
            tooltipText="Save as Element"
            tooltipPosition="above"
          />
          @if (isAdmin) {
            <confect-icon-button
              (click)="savePublicElement()"
              icon="earth_east"
              class="px-2 py-1"
              iconClass="text-2xl hover:text-primary"
              tooltip
              tooltipText="Save as Public Element (Admin only)"
              tooltipPosition="above"
            />
          }
        }
        @if (allowGrouping) {
          <confect-icon-button
            (click)="groupLayers()"
            icon="folder_outlined"
            class="px-2 py-1"
            iconClass="text-2xl hover:text-primary"
            tooltip
            tooltipText="Group Layers"
            tooltipPosition="above"
          />
        }
        @if (
          !allowGrouping && editor.multiSelectedLayerIdentifiers.size === 0
        ) {
          <confect-icon-button
            (click)="ungroupLayers(editor.layer.identifier)"
            icon="menu_vs_outlined"
            class="px-2 py-1"
            iconClass="text-2xl hover:text-primary"
            tooltip
            tooltipText="Ungroup Layers"
            tooltipPosition="above"
          />
        }
      </div>
    }

    <div class="flex">
      <confect-icon-button
        (click)="horizontalAlign('width')"
        icon="arrow_doublesided_horizontal"
        class="px-2 py-1"
        iconClass="text-2xl hover:text-primary"
        tooltip
        tooltipText="Match Width"
        tooltipPosition="above"
      />
      <confect-icon-button
        (click)="verticalAlign('height')"
        icon="arrow_doublesided_vertical"
        class="px-2 py-1"
        iconClass="text-2xl hover:text-primary"
        tooltip
        tooltipText="Match Height"
        tooltipPosition="above"
      />
    </div>

    <div class="flex">
      <confect-icon-button
        (click)="verticalAlign('top')"
        icon="align_to_top_outlined"
        class="px-2 py-1"
        iconClass="text-2xl hover:text-primary"
        tooltip
        tooltipText="Align Top"
        tooltipPosition="above"
      />

      <confect-icon-button
        (click)="verticalAlign('middle')"
        icon="align_to_middle_outlined"
        class="px-2 py-1"
        iconClass="text-2xl hover:text-primary"
        tooltip
        tooltipText="Align Middle"
        tooltipPosition="above"
      />

      <confect-icon-button
        (click)="verticalAlign('bottom')"
        icon="align_to_bottom_outlined"
        class="px-2 py-1"
        iconClass="text-2xl hover:text-primary"
        tooltip
        tooltipText="Align Bottom"
        tooltipPosition="above"
      />
    </div>

    <div class="flex">
      <confect-icon-button
        (click)="horizontalAlign('left')"
        icon="align_to_left_outlined"
        class="px-2 py-1"
        iconClass="text-2xl hover:text-primary"
        tooltip
        tooltipText="Align Left"
        tooltipPosition="above"
      />

      <confect-icon-button
        (click)="horizontalAlign('center')"
        icon="align_to_center_outlined"
        class="px-2 py-1"
        iconClass="text-2xl hover:text-primary"
        tooltip
        tooltipText="Align Center"
        tooltipPosition="above"
      />

      <confect-icon-button
        (click)="horizontalAlign('right')"
        icon="align_to_right_outlined"
        class="px-2 py-1"
        iconClass="text-2xl hover:text-primary"
        tooltip
        tooltipText="Align Right"
        tooltipPosition="above"
      />
    </div>
  </div>
}

<ng-template #publicElements let-close="close" let-extra="extra">
  <div class="flex flex-col w-[500px]">
    <h3 class="mb-10 text-center">Save Public Element</h3>
    <confect-input title="Name" [(ngModel)]="extra.element.name" />
    <confect-select
      title="Categories"
      bindValue="key"
      bindLabel="display"
      [clearable]="true"
      [options]="extra.categories"
      [(ngModel)]="extra.element.categories"
      [multiple]="true"
    />
    <div class="justify-center space-x-5 flex mt-10">
      <confect-button (click)="close(false)" type="white"
        >cancel</confect-button
      >
      <confect-button (click)="close(extra.element)" type="primary"
        >Save</confect-button
      >
    </div>
  </div>
</ng-template>
