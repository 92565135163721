<form class="spec-form-container">
  @for (specItem of _specItems; track specItem) {
    @if (
      !(
        editorMode === CreativeEditorMode.IMAGE &&
        specItem.name === "media_mute"
      )
    ) {
      @if (specItem.value_type === "string") {
        <ngx-string-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="setSettingsValue(specItem, $event)"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "html") {
        <ngx-html-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="setSettingsValue(specItem, $event)"
          [editor]="editor"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "number") {
        <ngx-number-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="setSettingsValue(specItem, $event)"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "percentage") {
        <ngx-percentage-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="setSettingsValue(specItem, $event)"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "selection") {
        <ngx-selection-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="setSettingsValue(specItem, $event)"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "media") {
        <ngx-media-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          [enabledMediaSources]="[
            { key: 'uploaded_images', scope: 'private' },
            { key: 'uploaded_videos', scope: 'private' },
            { key: 'uploaded_audio', scope: 'private' },
          ]"
          (valueChange)="
            setSettingsValue(specItem, $event.id, 'id');
            setSettingsValue(specItem, $event.name, 'name');
            setSettingsValue(specItem, $event.source, 'source_key')
          "
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "font") {
        <ngx-media-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          [enabledMediaSources]="[
            { key: 'uploaded_shared_fonts', scope: 'shared' },
            { key: 'uploaded_private_fonts', scope: 'private' },
          ]"
          (valueChange)="
            setSettingsValue(specItem, $event.id, 'id');
            setSettingsValue(specItem, $event.name, 'name');
            setSettingsValue(specItem, $event.source, 'source_key')
          "
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "pattern") {
        <ngx-media-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          [enabledMediaSources]="[
            { key: 'uploaded_patterns', scope: 'shared' },
            { key: 'uploaded_images', scope: 'private' },
          ]"
          [transparencyCheckers]="false"
          [patternMode]="true"
          [showAddButton]="false"
          (valueChange)="
            setSettingsValue(specItem, $event.id, 'id');
            setSettingsValue(specItem, $event.name, 'name');
            setSettingsValue(specItem, $event.source, 'source_key')
          "
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "bool") {
        <ngx-bool-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="setSettingsValue(specItem, $event)"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "color") {
        <ngx-color-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="setSettingsValue(specItem, $event)"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "gradient") {
        <ngx-gradient-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="setSettingsValue(specItem, $event)"
          [parentSelection]="parentSelection"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "position") {
        <ngx-position-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="setSettingsValue(specItem, $event, 'pos')"
          (xValueChange)="setSettingsValue(specItem, $event, 'x')"
          (yValueChange)="setSettingsValue(specItem, $event, 'y')"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
      @if (specItem.value_type === "product_subscription") {
        <ngx-product-subscription-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="
            didSelectRefGridItem($event[0], $event[1], $event[2], $event[3])
          "
          [editor]="editor"
          (resetClicked)="setSettingsValue(specItem, null)"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
          targetType="product"
        />
      }
      @if (specItem.value_type === "media_subscription") {
        <ngx-product-subscription-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="
            didSelectRefGridItem($event[0], $event[1], $event[2], $event[3])
          "
          [editor]="editor"
          (resetClicked)="setSettingsValue(specItem, null)"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
          targetType="media"
        />
      }
      @if (effects.has(specItem.value_type)) {
        <ngx-effects-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          [editor]="editor"
          (valueChange)="setSettingsValueV2(specItem, $event, 'effect')"
          [helpEnabled]="helpEnabled"
        />
      }
      @if (specItem.value_type === "EffectAction") {
        <ngx-effects-multiple-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          [editor]="editor"
          (valueChange)="setSettingsValue(specItem, $event, 'effect')"
          [helpEnabled]="helpEnabled"
        />
      }
      @if (specItem.value_type === "product_asset") {
        <ngx-product-asset-spec
          [specItem]="specItem"
          [value]="getSettingsValue(specItem)"
          (valueChange)="setSettingsValue(specItem, $event)"
          tooltip
          [tooltipText]="specItem.help?.text"
          tooltipPosition="left"
          [tooltipDisable]="!helpEnabled || specItem.help?.text == null"
        />
      }
    }
  }
</form>
