<ngx-folders
  #folders
  [delegate]="this"
  [settings]="settings"
  [selectMode]="true"
  (itemSelected)="mediaSelect.emit($event)"
  mode="submit"
>
  <ng-template folder-item-tmp let-item="item">
    <td
      class="text-sm font-medium text-gray-700 h-16 cursor-pointer group-hover:text-primary"
    >
      {{ item.name }}
    </td>
    <td
      class="text-gray-500 overflow-ellipsis overflow-hidden cursor-pointer group-hover:text-primary"
    >
      <div [ngClass]="['font' + item.id]" class="text-xl">AaBbCcDdEeFfGg</div>
    </td>
  </ng-template>
</ngx-folders>

<div>
  <confect-loading [loading]="folders.loading" />
</div>
@if (
  !folders.isEmpty &&
  !folders.loading &&
  folders?.items[folders?.items.length - 1].items.length === 20
) {
  <div class="w-full flex items-center justify-center">
    <div
      class="flex w-32 bg-slate-100 rounded-md p-2 items-center justify-between"
    >
      <confect-icon-button
        [disabled]="page === 1"
        icon="chevron_left"
        iconClass="text-3xl"
        (click)="prev()"
      />
      <div>{{ page }}</div>
      <confect-icon-button
        [disabled]="!allowNext"
        icon="chevron_right"
        iconClass="text-3xl"
        (click)="next()"
      />
    </div>
  </div>
}
