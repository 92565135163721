import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "ngx-number-spec",
  templateUrl: "./number-spec.component.html",
  styleUrls: ["./number-spec.component.scss"],
})
export class NumberSpecComponent implements OnInit {
  @Input() specItem: any = null;
  @Input() value: any = null;
  @Output() valueChange = new EventEmitter<any>();

  pattern = /[-]?[0-9]*\.?[0-9]+$/;
  isValid = true;

  constructor() {}

  ngOnInit() {}

  checkInput(input, pattern) {
    const m = input.match(pattern);

    if (m === null || m.length === 0) {
      return false;
    }

    if (m[0] === input) {
      return true;
    }

    return false;
  }

  change(to) {
    const v = to.replace(",", ".");
    this.isValid = this.checkInput(to, this.pattern);
    this.valueChange.emit(v);
  }
}
