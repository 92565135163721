<div class="sm:grid sm:grid-cols-2 sm:gap-4 items-center sm:pb-3">
  <label class="block sm:col-span-1">
    <confect-icon
      [icon]="specItem.icon"
      iconClass="text-xl font-medium text-gray-700 mr-2"
    >
      <span class="text-sm">{{ specItem.display }}</span>
    </confect-icon>
  </label>
  <div class="sm:col-span-1">
    <confect-button [block]="true" (click)="browseMedia()">{{
      value.name === "" || value.name == null
        ? "Select " + specItem.display
        : shortMediaName(value.name)
    }}</confect-button>
  </div>
</div>

<ng-template #mediaLibrary let-close="close">
  <div
    class="h-[65vh] w-[65vw]"
    [ngClass]="{ 'divide-y': specItem.value_type !== 'font' }"
  >
    <div class="flex justify-end w-full mb-2 h-[7%]">
      <confect-button size="xlarge" (click)="uploadMedia()"
        >Upload</confect-button
      >
    </div>
    <div class="h-[93%]">
      @if (specItem.value_type === "font") {
        <confect-tab-menu
          (tabChange)="
            $event === 'private'
              ? overview.folders.reloadFolder()
              : shared.folders.reloadFolder()
          "
          [tabs]="[
            { name: 'Google Fonts', icon: 'google', id: 'google' },
            {
              name: 'My Fonts',
              icon: 'account_square_outlined',
              id: 'private',
            },
          ]"
        >
          <ng-template tab-design-tmp let-tab="tab" let-currentTab="currentTab">
            <div
              class="cursor-pointer py-2 px-3 rounded-md flex flex-col items-center justify-center my-2"
              [ngClass]="{
                'bg-primary-100 text-primary': tab.id === currentTab,
                'text-gray-500 hover:text-gray-700 hover:bg-slate-100':
                  tab.id !== currentTab,
              }"
            >
              <confect-icon [icon]="tab.icon" iconClass="text-2xl mr-1">
                {{ tab.name }}
              </confect-icon>
            </div>
          </ng-template>
          <ng-template tab-content-tmp let-currentTab="currentTab">
            <div tab-content [show]="currentTab === 'private'">
              <ngx-media-overview
                #overview
                [radioMode]="false"
                mode="submit"
                [folderType]="'media_fonts'"
                [type]="'font'"
                (mediaSelect)="mediaSelected($event); close(true)"
              />
            </div>
            <div tab-content [show]="currentTab === 'google'">
              <ngx-shared-fonts
                #shared
                (mediaSelect)="mediaSelected($event); close(true)"
                [empty_state]="empty_state"
              />
            </div>
          </ng-template>
        </confect-tab-menu>
      }

      @if (specItem.value_type === "media") {
        <div class="w-full h-full overflow-y-auto overflow-x-hidden">
          <div class="my-2">
            <ngx-media-overview
              #overview
              [radioMode]="false"
              mode="submit"
              [folderType]="'media_visual'"
              [type]="'visual'"
              (mediaSelect)="mediaSelected($event); close(true)"
            />
          </div>
        </div>
      }
    </div>
  </div>
</ng-template>
