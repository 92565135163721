import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { MediaService } from "@core/api/media.service";

import { MediaOverviewComponent } from "@components/designs/media-overview/media-overview.component";

import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";
import { EmptyStateInfo } from "@theme/components/empty-state/empty-state.component";

import { SharedFontsComponent } from "../../../components/shared-fonts/shared-fonts.component";

@Component({
  selector: "ngx-media-spec",
  templateUrl: "./media-spec.component.html",
  styleUrls: ["./media-spec.component.scss"],
})
export class MediaSpecComponent implements OnInit {
  @Input() specItem: any = null;
  @Input() value: any = null;

  @Input() enabledMediaSources: any = [];
  @Input() showAddButton = true;

  @Input() transparencyCheckers = true;
  @Input() patternMode = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("mediaLibrary", { static: true })
  mediaLibrary: TemplateRef<any>;
  @ViewChild("overview") overview: MediaOverviewComponent;
  @ViewChild("shared") shared: SharedFontsComponent;

  mediaModal: any;

  empty_state: EmptyStateInfo = {
    img: "assets/images/empty-states/font-library.png",
    title: "You don't have any fonts.",
    description: "Upload custom fonts to use in your designs ",
  };

  constructor(
    private popUpService: CPopupModalService,
    private mediaService: MediaService,
  ) {}

  ngOnInit() {}

  shortMediaName(name) {
    if (name.length <= 16) {
      return name;
    }
    return name.substring(0, 16) + "...";
  }

  browseMedia() {
    (document.activeElement as any)?.blur();
    this.mediaModal = this.popUpService.template({
      template: this.mediaLibrary,
      showClose: false,
    });
  }

  mediaSelected(media) {
    media.source =
      this.specItem.value_type === "media"
        ? `uploaded_${media.type}s`
        : `uploaded_${media.scope}_${media.type}s`;
    this.valueChange.emit(media);
  }

  uploadMedia() {
    this.mediaService
      .uploadMediaFileInteractive(
        this.specItem.value_type === "media"
          ? ["image/*", "video/*"]
          : [".ttf", ".otf", ".woff", ".woff2"],
        this.specItem.value_type === "media" ? "Media" : "Font",
      )
      .subscribe({
        next: (files: any) => {
          files.forEach((file) => {
            const folderDepth = this.overview.folders.items.length;
            const currentFolder =
              this.overview.folders.items[folderDepth - 1].id;
            this.overview.folders.moveItem(file.id, currentFolder, () => {
              const media = this.overview.getById(file.id);
              this.mediaSelected(media);
              this.mediaModal.close();
            });
          });
        },
      });
  }
}
