<confect-input
  [placeholder]="
    placeholderKey === null
      ? specItem.default
      : specItem.default[placeholderKey]
  "
  [title]="specItem.display"
  [ngModel]="value"
  (ngModelChange)="change($event)"
  [icon]="specItem.icon"
  [compact]="true"
  suffix="%"
/>

<!--
<div class="form-group row">
  <label for="inputSpec" class="col-sm-6 col-form-label">
    <i *ngIf="specItem.icon" class="fas fa-{{ specItem.icon }}"></i>
    {{ specItem.display }}</label
  >
  <div class="col-sm-6">
    <div class="input-group">
      <input
        class="form-control"
        [placeholder]="
          placeholderKey === null
            ? specItem.default
            : specItem.default[placeholderKey]
        "
        [value]="value"
        (input)="change($event.target.value)"
        [class.is-invalid]="!isValid"
      />

      <div class="input-group-append">
        <span class="input-group-text">%</span>
      </div>
    </div>
  </div>
</div>
-->
