import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { PackageInterface, PackageItems } from "../api-models/assets.models";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class AssetsService extends ApiService {
  createAssetPackage(name, packageType = null, key = null): Observable<any> {
    return this.http.post(`${this.endpoint}/asset-library/packages`, {
      name: name,
      package_type: packageType,
      key: key,
    });
  }

  public deleteAssetPackage(packageId): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      `${this.endpoint}/asset-library/packages/${packageId}`,
    );
  }

  public deleteAssetPackageItem(
    packageId: number,
    itemId: number,
  ): Observable<{ success: boolean }> {
    return this.http.delete<{ success: boolean }>(
      `${this.endpoint}/asset-library/packages/${packageId}/items/${itemId}`,
    );
  }

  public getAssetPackageContents(
    packageId: number,
  ): Observable<PackageInterface> {
    return this.http.get<PackageInterface>(
      `${this.endpoint}/asset-library/packages/${packageId}`,
    );
  }

  public getProductAssetFields(): Observable<any> {
    return this.http.get(
      `${this.endpoint}/asset-library/product-assets/fields`,
    );
  }

  public createProductAssetPackage(name, key): Observable<any> {
    return this.createAssetPackage(name, "product", key);
  }

  public getProductAssetPackageContents(
    packageId: number,
  ): Observable<PackageItems> {
    return this.http.get<PackageItems>(
      `${this.endpoint}/asset-library/product-assets/packages/${packageId}`,
    );
  }

  public addAssetPackageItem(
    packageId: number,
    categoryKey,
    value,
    name = null,
  ): Observable<any> {
    return this.http.post(
      `${this.endpoint}/asset-library/packages/${packageId}/items`,
      {
        category_key: categoryKey,
        value: value,
        name: name,
      },
    );
  }

  public getAssetPackageSpec(): Observable<any> {
    return this.http.get(`${this.endpoint}/asset-library/package-specs`);
  }

  public updateAssetPackage(packageId: number, updates): Observable<any> {
    return this.http.post(
      `${this.endpoint}/asset-library/packages/${packageId}`,
      updates,
    );
  }

  // Campaign Resources
  getAssetPackages(): Observable<PackageInterface[]> {
    return this.http.get<PackageInterface[]>(
      `${this.endpoint}/asset-library/packages`,
    );
  }

  getProductAssetPackages(): Observable<any> {
    return this.http.get(
      `${this.endpoint}/asset-library/product-assets/packages`,
    );
  }
}
