import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import { CustomFieldsService } from "@core/services/custom-fields.service";

import { CKEditor4 } from "ckeditor4-angular/ckeditor";

@Component({
  selector: "ngx-html-spec",
  templateUrl: "./html-spec.component.html",
  styleUrls: ["./html-spec.component.scss"],
})
export class HtmlSpecComponent implements OnInit {
  @Input() specItem: any = null;
  @Input() value: any = null;
  @Output() valueChange = new EventEmitter<any>();
  @Input() editor: CreativesEditService;

  isLoaded = false;

  // public Editor = ClassicEditor;

  ckConfig = {
    enterMode: 2,
    line_height: "0.7;0.8;0.9;1;1.1;1.2;1.3;1.4;1.5;1.7;2;2.5;3",
    fontSize_sizes:
      "4px;5px;6px;7px;8px;9px;10px;11px;12px;13px;14px;15px;16px;17px;18px;19px;20px;21px;22px;23px;24px;25px;26px;27px;28px;29px;30px;32px;34px;36px;38px;40px;42px;44px;46px;48px;50px;52px;54px;56px;58px;60px;62px;64px;66px;68px;70px;72px;74px;76px;78px;80px;",
    removeButtons:
      "Source,Save,Templates,NewPage,Preview,Print,PasteFromWord,Paste,Copy,Cut,Find,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Button,ImageButton,HiddenField,Select,Outdent,Indent,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,Link,Unlink,Anchor,Image,Flash,Table,HorizontalRule,SpecialChar,PageBreak,Iframe,ShowBlocks,Maximize,About,Format,PasteText,NumberedList,BulletedList,Bold,Subscript,Superscript,Font,Undo,Redo,JustifyBlock",
    entities: false,
    basicEntities: false,
    extraPlugins: ["placeholder"],
    placeholder_select: {
      placeholders: [
        "Name",
        "Brand",
        "Category",
        "Price",
        "Original price",
        "Savings",
        "Description",
        "Product ID",
        // "custom label 0",
        // "custom label 1",
        // "custom label 2",
        // "custom label 3",
        // "custom label 4",
        // "custom label x",
      ],
      format: "[[%placeholder%]]",
      userFields: ["Hello"],
    },
    extraAllowedContent: "span[confect](confect-placeholder)",
    toolbarGroups: [
      { name: "basicstyles", groups: ["basicstyles"] },
      {
        name: "paragraph",
        groups: ["list", "indent", "blocks", "align", "bidi"],
      },
      { name: "styles" },
      { name: "font1" },
      { name: "text-spacing" },
      { name: "text-spacing2" },
      { name: "colors" },
      { name: "others" },
    ],
  };

  constructor(private cfs: CustomFieldsService) {}

  ngOnInit() {
    this.cfs.getFields().subscribe({
      next: (fields) => {
        // Only getting additional+custom fields (fields w. a group)
        this.ckConfig.placeholder_select.userFields = fields.filter(
          (f) => f.group != null,
        ) as any;
        this.isLoaded = true;
      },
      complete: () => {
        this.isLoaded = true;
      },
    });
  }

  editorReady(_event) {
    const editorFrame = window.document
      .getElementsByClassName("cke_wysiwyg_frame")
      .item(0);

    // Add event listener inside the cke iframe
    (editorFrame as any).contentWindow.addEventListener(
      "keydown",
      (event: KeyboardEvent) => {
        // Dispatch event in the outer window
        if (event.ctrlKey || event.metaKey) {
          if (event.code === "KeyZ") {
            // event.preventDefault();
            if (event.shiftKey) {
              this.editor.history.redo();
            } else {
              this.editor.history.undo();
            }

            // Editor frame is recreated on change, we add listener again?
            this.editorReady(_event);
          }
        }
      },
    );
  }

  onTextChange(newText: string) {
    this.valueChange.emit(newText);
  }

  public onChange(event: CKEditor4.EventInfo) {
    const data = event.editor.getData();
    this.valueChange.emit(data);
  }
}
