import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";

import { Product } from "@core/api-models/products.models";
import { ProductsService } from "@core/api/products.service";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";
import { CreativesLiveService } from "@core/services/creatives/creatives-live.service";
import { arrayToDataURL } from "@core/services/creatives/image-helpers";

import { CPopupModalService } from "@theme/@confect/services/confect-popup-modal.service";

@Component({
  selector: "ngx-design-intro",
  templateUrl: "./design-intro.component.html",
  styleUrls: ["./design-intro.component.scss"],
})
export class DesignIntroComponent implements OnInit {
  step = 0;

  products: Product[] = [];
  @Input() live: CreativesLiveService;
  @Input() editor: CreativesEditService;

  @Output() done = new EventEmitter<any>();
  @Output() noProducts = new EventEmitter<any>();

  hideProducts = false;

  categoryFilter = new Set<string>([
    "brand",
    "saving",
    "price",
    "original_price",
  ]);

  constructor(
    private ps: ProductsService,
    private router: Router,
    private popUpService: CPopupModalService,
  ) {}

  ngOnInit(): void {
    this.ps
      .searchProducts({
        page_size: 9,
        usable_only: true,
      })
      .subscribe({
        next: (prods) => {
          this.products = prods.data;
          if (this.products.length === 0) {
            this.noProducts.emit();
          }
        },
        error: (_err) => {
          this.popUpService.error({});
        },
      });
  }

  renderDesign() {
    const pf = {
      product_id: this.products.map((p) => p.id),
    };

    this.step = 2;

    this.live.socketService
      .renderManyImages(this.editor.getSpec(), [512, 512], pf)
      .subscribe({
        next: (res) => {
          if (res.result == null) {
            return;
          }

          this.hideProducts = true;

          setTimeout(() => {
            this.products = this.products.map((p, i) => {
              p.cdn_image = arrayToDataURL(res.result[i], "image/jpg");
              return p;
            });
            setTimeout(() => {
              this.hideProducts = false;
            }, 100);
          }, 500);

          this.step = 3;
          this.editor.deselectLayer();
        },
        error: (_err) => {
          this.router.navigate(["/"]);
        },
      });
  }

  checkForTemplate() {
    const hasTemplate = Array.from(this.editor.iterateLayers()).length > 1;
    if (hasTemplate) {
      this.renderDesign();
      return;
    }
    this.step = 1;
  }
}
