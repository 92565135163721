<div
  class="flex flex-col justify-center space-y-2 rounded-sm px-5 pb-5 pt-2 bg-white min-w-[350px] ring-2 ring-slate-200"
>
  <div class="flex justify-end">
    <confect-icon-button
      icon="clear"
      iconClass="text-lg hover:text-red"
      (click)="remove.emit()"
    />
  </div>

  @if (_effect.effect !== "intro" && _effect.effect !== "outro") {
    <div class="flex justify-between">
      <div class="w-1/2">Effect</div>
      <div class="w-1/2">
        <confect-select
          [options]="['move', 'rotation2d', 'scale']"
          [(ngModel)]="_effect.effect"
          (ngModelChange)="setDefaults(_effect)"
        />
      </div>
    </div>
  }
  @if (_effect.effect !== "") {
    @if (_effect.effect === "intro" || _effect.effect === "outro") {
      <div class="flex justify-between items-center h-10">
        <div class="w-1/2">Presets</div>
        <div class="w-1/2 flex justify-end">
          <confect-button type="secondary" (click)="presetChange.emit()">{{
            _effect.preset ?? "Custom"
          }}</confect-button>
        </div>
      </div>
    }
    @for (option of spec[_effect.effect].spec; track option) {
      <div class="flex justify-between items-center h-10">
        <div class="w-1/2 flex items-center">
          {{ option.display }}
        </div>
        <div class="w-1/2">
          @if (option.value_type === "percentage") {
            <confect-slider
              [sliderInfo]="{
                interval: {
                  start:
                    option.name === 'y' ||
                    option.name === 'x' ||
                    option.name === 'initial_position_x' ||
                    option.name === 'initial_position_y'
                      ? -100
                      : 0,
                  end:
                    option.name === 'initial_scale' || option.name === 'factor'
                      ? 200
                      : 100,
                },
                stepSize: 1,
              }"
              [(ngModel)]="_effect.settings[option.name]"
              (ngModelChange)="changed()"
            />
          } @else if (option.name === "duration") {
            @if (maxDuration <= 0.2) {
              <div class="text-red">Layer is too short.</div>
            } @else {
              <confect-slider
                [(ngModel)]="_effect.settings.duration"
                (ngModelChange)="changed()"
                [sliderInfo]="{
                  interval: { start: 0.2, end: maxDuration },
                  stepSize: 0.2,
                }"
              />
            }
          } @else if (option.value_type === "number") {
            <confect-input
              type="number"
              [ngModel]="_effect.settings[option.name]"
              (ngModelChange)="
                _effect.settings[option.name] = parseInt($event); changed()
              "
            />
          } @else if (option.name === "slide_in_direction") {
            <div class="flex w-full justify-end">
              <div class="flex justify-center space-x-2">
                <confect-button
                  size="xsmall"
                  (click)="
                    setDirection(_effect.effect === 'outro' ? 'right' : 'left')
                  "
                  [active]="
                    (_effect.effect === 'outro' &&
                      _effect.settings[option.name] === 'right') ||
                    (_effect.settings[option.name] === 'left' &&
                      !(_effect.effect === 'outro'))
                  "
                  type="subtle"
                >
                  <confect-icon iconClass="text-lg" icon="arrow_large_forward"
                /></confect-button>
                <confect-button
                  size="xsmall"
                  (click)="
                    setDirection(_effect.effect === 'outro' ? 'left' : 'right')
                  "
                  [active]="
                    (_effect.effect === 'outro' &&
                      _effect.settings[option.name] === 'left') ||
                    (_effect.settings[option.name] === 'right' &&
                      !(_effect.effect === 'outro'))
                  "
                  type="subtle"
                  ><confect-icon
                    iconClass="text-lg"
                    icon="arrow_large_backward"
                /></confect-button>
                <confect-button
                  size="xsmall"
                  (click)="
                    setDirection(_effect.effect === 'outro' ? 'bottom' : 'top')
                  "
                  [active]="
                    (_effect.effect === 'outro' &&
                      _effect.settings[option.name] === 'bottom') ||
                    (_effect.settings[option.name] === 'top' &&
                      !(_effect.effect === 'outro'))
                  "
                  type="subtle"
                  ><confect-icon
                    iconClass="text-lg"
                    icon="arrow_large_downward"
                /></confect-button>
                <confect-button
                  size="xsmall"
                  (click)="
                    setDirection(_effect.effect === 'outro' ? 'top' : 'bottom')
                  "
                  [active]="
                    (_effect.effect === 'outro' &&
                      _effect.settings[option.name] === 'top') ||
                    (_effect.settings[option.name] === 'bottom' &&
                      !(_effect.effect === 'outro'))
                  "
                  type="subtle"
                  ><confect-icon
                    iconClass="text-lg"
                    icon="arrow_large_upward"
                  />
                </confect-button>
              </div>
            </div>
          } @else if (option.value_type === "selection") {
            <confect-select
              [options]="option.options"
              [(ngModel)]="_effect.settings[option.name]"
              (ngModelChange)="changed()"
              [clearable]="true"
            />
          } @else if (option.value_type === "boolean") {
            <div class="flex items-center justify-end">
              <confect-checkbox
                [(ngModel)]="_effect.settings[option.name]"
                (ngModelChange)="changed()"
              />
            </div>
          }
        </div>
      </div>
    }
  }
</div>
