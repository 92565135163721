<ng-template #layerInnerTemplate let-layer="layer">
  <div
    class="timeline-info-row node-item h-16 flex items-center"
    [attr.data-id]="layer.identifier"
    [attr.id]="'node-' + layer.identifier"
    (click)="selectLayer($event, layer)"
    [class.no-timeline-layer]="!showTimeline"
    [ngClass]="{
      'bg-slate-100 rounded-md':
        editor.selectedLayerIdentifier === layer.identifier ||
        editor.multiSelectedLayerIdentifiers.has(layer.identifier),
    }"
    [class.failed]="raisedError?.info?.layer === layer.identifier"
    (mouseenter)="layerHoverEvent(layer)"
    (contextmenu)="
      selectLayer($event, layer); context.emit({ event: $event, layer: layer })
    "
  >
    <div class="w-full py-0.5 px-1 flex items-center">
      <div class="w-14 h-14 relative">
        <div
          class="w-full h-full bg-slate-400 absolute opacity-25 rounded"
        ></div>
        <div
          class="absolute"
          [style.left.rem]="0.25"
          [style.top.rem]="0.25"
          [style.width]="'calc(100% - 0.5rem)'"
          [style.height]="'calc(100% - 0.5rem)'"
          [style.background-image]="
            images != null && images[layer.identifier]?.css | safe: 'style'
          "
          [style.background-size]="'contain'"
          [style.background-repeat]="'no-repeat'"
          [style.background-position]="'center'"
        ></div>
      </div>

      <!-- @if (cellTypeIcon[layer.config.grid_config[0].type] != null) {
        <span class="layer-action">
          <confect-icon
            [icon]="cellTypeIcon[layer.config.grid_config[0].type].icon"
            iconClass="text-xl text-gray-600"
          />
        </span>
      } -->
      <span
        class="name text-sm pl-2 align-text-center"
        [class.failed]="raisedError?.error_info?.layer === layer.identifier"
      >
        {{ layer.name | truncate: 25 }}
      </span>

      <confect-icon-button
        icon="more_vertical"
        iconClass="text-xl"
        (click)="
          selectLayer($event, layer);
          context.emit({ event: $event, layer: layer })
        "
      />
    </div>
  </div>
</ng-template>

<ng-template #layerGroupTemplate let-layer="layer">
  <!-- *ngIf="node.isExpanded && node.children.length" -->

  <div
    class="layer-group-container node-item"
    [attr.data-id]="layer.identifier"
    [attr.id]="'node-' + layer.identifier"
  >
    <div
      class="group-name-row h-8 flex items-center"
      (click)="selectLayer($event, layer)"
      (mouseenter)="layerHoverEvent(layer)"
    >
      <div
        class="w-full h-8 px-1 pb-2 pt-3 info-col space-x-1"
        (contextmenu)="
          selectLayer($event, layer);
          context.emit({ event: $event, layer: layer })
        "
        [ngClass]="{
          'bg-slate-100 rounded-t-md':
            editor.selectedLayerIdentifier === layer.identifier ||
            editor.multiSelectedLayerIdentifiers.has(layer.identifier),
        }"
      >
        <span class="name text-sm">
          {{ layer.name }}
        </span>

        <div class="font-normal">
          <confect-icon-button
            icon="more_vertical"
            iconClass="text-xl"
            (click)="
              selectLayer($event, layer);
              context.emit({ event: $event, layer: layer })
            "
          />
        </div>
      </div>
    </div>

    <div
      class="node-children"
      cdkDropList
      [cdkDropListData]="layer.layers"
      [id]="layer.identifier"
      [cdkDropListConnectedTo]="dropTargetIds"
      (cdkDropListDropped)="drop($event)"
      [cdkDropListSortingDisabled]="true"
      [ngClass]="{
        'bg-slate-50 rounded-b-md':
          editor.selectedLayerIdentifier === layer.identifier ||
          editor.multiSelectedLayerIdentifiers.has(layer.identifier),
      }"
    >
      <div
        class="ml-3 border-l-2"
        [ngClass]="{
          ' border-slate-100': !(
            editor.selectedLayerIdentifier === layer.identifier ||
            editor.multiSelectedLayerIdentifiers.has(layer.identifier)
          ),
          ' border-slate-50':
            editor.selectedLayerIdentifier === layer.identifier ||
            editor.multiSelectedLayerIdentifiers.has(layer.identifier),
        }"
      >
        @for (child of layer.layers; track child) {
          <div
            cdkDrag
            [cdkDragData]="child.identifier"
            (cdkDragMoved)="dragMoved($event, child)"
            class="pl-3"
          >
            <ng-container
              *ngTemplateOutlet="layerInnerTemplate; context: { layer: child }"
            />
          </div>
        }
      </div>
    </div>
  </div>
</ng-template>

<ng-template #layerTemplate let-layer="layer">
  <!-- Group -->
  @if (layer.type === "group") {
    <ng-container
      *ngTemplateOutlet="layerGroupTemplate; context: { layer: layer }"
    />
  }

  <!-- Layer -->
  @if (layer.type === "layer") {
    <ng-container
      *ngTemplateOutlet="layerInnerTemplate; context: { layer: layer }"
    />
  }
</ng-template>

<!-- [cdkDropListConnectedTo]="dropTargetIds"
(cdkDropListDropped)="drop($event)" -->
<div
  class="flex flex-col w-full"
  cdkDropList
  [cdkDropListData]="_layers"
  [id]="'main'"
  [cdkDropListConnectedTo]="dropTargetIds"
  (cdkDropListDropped)="drop($event)"
  [cdkDropListSortingDisabled]="true"
  (mouseleave)="layerHoverEvent(null)"
>
  @for (layer of _layers; track layer) {
    <div
      cdkDrag
      [cdkDragData]="layer.identifier"
      (cdkDragMoved)="dragMoved($event, layer)"
      class="w-full"
    >
      <ng-container
        *ngTemplateOutlet="layerTemplate; context: { layer: layer }"
      />
    </div>
  }
</div>
