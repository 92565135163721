<div
  class="flex items-center flex-grow h-full group"
  (contextmenu)="
    $event.preventDefault();
    context.emit({ event: $event, block: layerInfo.id })
  "
  (mouseenter)="layerHover.emit(layerInfo.id)"
  (mouseleave)="!grabbed && layerHover.emit(null)"
>
  <div
    class="z-20 text-md pointer-events-none absolute flex items-center justify-center left-0 right-0 text-nowrap"
    [ngClass]="{ 'text-gray-300': hovered, 'text-gray-500 ': !hovered }"
  >
    <confect-icon [icon]="layerInfo.icon" iconClass="text-lg mr-1">{{
      layerInfo.name
    }}</confect-icon>
  </div>

  <div
    class="w-full h-full relative bg-white rounded-md flex justify-between items-center cursor-grab overflow-hidden"
    (mousedown)="
      $event.stopPropagation(); resizMove.emit({ dir: 'mb', event: $event })
    "
  >
    <div
      class="z-20 text-md pointer-events-none absolute flex items-center justify-center left-0 right-0 text-nowrap"
      [ngClass]="{ 'text-gray-700': hovered, 'text-gray-500': !hovered }"
    >
      <confect-icon [icon]="layerInfo.icon" iconClass="text-lg mr-1">{{
        layerInfo.name
      }}</confect-icon>
    </div>
    <div
      class="bg-transparent ring-2 absolute rounded-md w-full h-full z-10 ring-inset"
      [ngClass]="{
        '  ring-primary ': current,
        ' ring-slate-300 ': !current && !hovered,
        'ring-slate-400': !current && hovered,
      }"
    ></div>

    <div
      [style.height.%]="100"
      [style.width.%]="
        duration != null && layerInfo?.effect?.effect_in != null
          ? (layerInfo.effect?.effect_in?.settings.duration / duration) * 100
          : 0
      "
      class="bg-slate-100 rounded-l-md relative"
    >
      <div
        class="h-full z-30 w-3 cursor-ew-resize absolute -left-1.5"
        (mousedown)="
          $event.stopPropagation(); resizMove.emit({ dir: 'lb', event: $event })
        "
      ></div>
    </div>

    <div
      [style.height.%]="100"
      [style.width.%]="
        duration != null && layerInfo?.effect?.effect_out != null
          ? (layerInfo?.effect?.effect_out?.settings?.duration / duration) * 100
          : 0
      "
      class="bg-slate-100 rounded-r-md relative"
    >
      <div
        class="h-full z-30 w-3 cursor-ew-resize absolute -right-1.5"
        (mousedown)="
          $event.stopPropagation(); resizMove.emit({ dir: 'rb', event: $event })
        "
      ></div>
    </div>
  </div>
</div>
