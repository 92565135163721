export const RADIAL_GRADIENTS = [
  {
    steps: [0.0, 100],
    colors: [
      [85, 88, 218, 255],
      [95, 209, 249, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [253, 193, 104, 255],
      [251, 128, 128, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [128, 248, 174, 255],
      [223, 244, 148, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [255, 200, 124, 255],
      [252, 251, 121, 255],
    ],
  },
  {
    steps: [0.0, 81.3],
    colors: [
      [90, 92, 106, 255],
      [32, 45, 58, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [239, 249, 249, 255],
      [182, 199, 226, 255],
    ],
  },
  {
    steps: [0.3, 90.2],
    colors: [
      [74, 98, 110, 255],
      [30, 33, 48, 255],
    ],
  },
  {
    steps: [0.0, 25.5, 62.3, 93.8],
    colors: [
      [237, 161, 193, 255],
      [250, 178, 172, 255],
      [190, 228, 210, 255],
      [215, 248, 247, 255],
    ],
  },
  {
    steps: [0.0, 54.2, 100],
    colors: [
      [253, 239, 132, 255],
      [247, 198, 169, 255],
      [21, 186, 196, 255],
    ],
  },
  {
    steps: [0.0, 99.6],
    colors: [
      [142, 5, 254, 255],
      [33, 250, 214, 255],
    ],
  },
  {
    steps: [74.6],
    colors: [[160, 199, 254, 255]],
  },
  {
    steps: [0.0, 100],
    colors: [
      [3, 235, 255, 255],
      [152, 70, 242, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [69, 86, 102, 255],
      [34, 34, 34, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [161, 255, 206, 255],
      [250, 255, 209, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [151, 10, 130, 255],
      [33, 33, 33, 255],
    ],
  },
  {
    steps: [0.2, 59.1, 100],
    colors: [
      [0, 234, 255, 255],
      [0, 124, 255, 255],
      [198, 0, 255, 255],
    ],
  },
  {
    steps: [0.0, 47.7, 92.3],
    colors: [
      [255, 197, 118, 255],
      [254, 106, 103, 255],
      [240, 23, 23, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [18, 50, 90, 255],
      [207, 199, 252, 255],
    ],
  },
  {
    steps: [0.1, 90.1],
    colors: [
      [164, 38, 199, 255],
      [65, 220, 255, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [102, 116, 236, 255],
      [50, 231, 219, 255],
    ],
  },
  {
    steps: [20.8, 100],
    colors: [
      [64, 0, 126, 255],
      [0, 255, 160, 255],
    ],
  },
  {
    steps: [0.0, 90.1],
    colors: [
      [67, 144, 233, 255],
      [78, 226, 198, 255],
    ],
  },
  {
    steps: [0.0, 91.0],
    colors: [
      [120, 50, 255, 255],
      [50, 150, 250, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [64, 84, 178, 255],
      [219, 2, 234, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [255, 85, 166, 255],
      [255, 154, 98, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [248, 219, 219, 255],
      [229, 248, 250, 255],
    ],
  },
  {
    steps: [0.0, 74.2],
    colors: [
      [14, 72, 222, 255],
      [3, 22, 65, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [226, 240, 254, 255],
      [255, 247, 228, 255],
    ],
  },
  {
    steps: [16.3, 90.0],
    colors: [
      [246, 133, 133, 255],
      [172, 131, 241, 255],
    ],
  },
  {
    steps: [0.0, 0.0, 84.0],
    colors: [
      [215, 223, 252, 255],
      [255, 255, 255, 255],
      [215, 223, 252, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [62, 76, 167, 255],
      [255, 99, 145, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [0, 250, 255, 255],
      [2, 255, 187, 255],
    ],
  },
  {
    steps: [0.0, 99.8],
    colors: [
      [32, 233, 104, 255],
      [13, 167, 236, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [255, 0, 102, 255],
      [80, 5, 35, 255],
    ],
  },
  {
    steps: [16.9, 88.5],
    colors: [
      [95, 75, 139, 255],
      [128, 204, 221, 255],
    ],
  },
  {
    steps: [0.0, 89.8],
    colors: [
      [220, 217, 249, 255],
      [172, 224, 217, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [144, 17, 105, 255],
      [51, 0, 131, 255],
    ],
  },
  {
    steps: [4.3, 78.8],
    colors: [
      [120, 106, 245, 255],
      [187, 7, 7, 255],
    ],
  },
  {
    steps: [0.0, 90.1],
    colors: [
      [149, 219, 254, 255],
      [7, 134, 197, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [55, 209, 255, 255],
      [14, 31, 111, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [116, 203, 199, 255],
      [220, 246, 200, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [255, 143, 143, 255],
      [255, 248, 182, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [250, 250, 250, 255],
      [225, 234, 238, 255],
    ],
  },
  {
    steps: [6.5, 100],
    colors: [
      [233, 79, 79, 255],
      [111, 34, 142, 255],
    ],
  },
  {
    steps: [0.1, 100],
    colors: [
      [241, 241, 242, 255],
      [224, 226, 228, 255],
    ],
  },
  {
    steps: [5.2, 75.3],
    colors: [
      [14, 72, 222, 255],
      [3, 22, 65, 255],
    ],
  },
  {
    steps: [23.8, 66.0],
    colors: [
      [107, 157, 171, 255],
      [92, 122, 158, 255],
    ],
  },
  {
    steps: [0.0, 94.3],
    colors: [
      [254, 158, 94, 255],
      [254, 94, 114, 255],
    ],
  },
  {
    steps: [0.0, 86.8],
    colors: [
      [241, 121, 226, 255],
      [251, 226, 216, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [141, 254, 235, 255],
      [232, 176, 254, 255],
    ],
  },
  {
    steps: [0.0, 97.5],
    colors: [
      [202, 204, 227, 255],
      [89, 89, 99, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [255, 252, 214, 255],
      [255, 102, 102, 112],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [10, 174, 227, 255],
      [124, 182, 255, 255],
    ],
  },
  {
    steps: [0.0, 43.4, 83.1],
    colors: [
      [255, 157, 255, 255],
      [255, 102, 142, 255],
      [255, 31, 56, 255],
    ],
  },
  {
    steps: [0.0, 86.6],
    colors: [
      [169, 224, 127, 255],
      [248, 247, 121, 255],
    ],
  },
  {
    steps: [0.0, 45.1, 100],
    colors: [
      [235, 217, 221, 255],
      [216, 174, 211, 255],
      [145, 130, 196, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [252, 165, 241, 255],
      [181, 255, 255, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [89, 196, 233, 255],
      [193, 181, 232, 255],
    ],
  },
  {
    steps: [13.6, 100],
    colors: [
      [138, 118, 249, 255],
      [75, 252, 235, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [221, 221, 221, 255],
      [25, 46, 152, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [239, 246, 249, 255],
      [206, 239, 253, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [245, 76, 100, 255],
      [247, 129, 97, 255],
    ],
  },
  {
    steps: [7.8, 32.2, 48.1, 61.0, 75.3],
    colors: [
      [255, 148, 148, 255],
      [255, 223, 155, 255],
      [255, 247, 177, 255],
      [216, 255, 177, 255],
      [177, 255, 253, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [255, 168, 114, 255],
      [254, 106, 164, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [166, 226, 229, 255],
      [198, 232, 221, 255],
    ],
  },
  {
    steps: [0.0, 25.8, 50.8, 77.6, 100],
    colors: [
      [191, 224, 251, 255],
      [232, 233, 251, 255],
      [252, 239, 250, 255],
      [234, 251, 251, 255],
      [240, 251, 244, 255],
    ],
  },
  {
    steps: [7.5, 64.7, 90.5],
    colors: [
      [15, 224, 254, 255],
      [139, 255, 114, 255],
      [239, 226, 54, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [87, 213, 255, 255],
      [146, 109, 255, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [255, 156, 156, 255],
      [179, 201, 241, 255],
    ],
  },
  {
    steps: [0.0, 47.3, 88.4],
    colors: [
      [28, 142, 210, 255],
      [113, 192, 244, 255],
      [139, 238, 230, 255],
    ],
  },
  {
    steps: [0.0, 90.1],
    colors: [
      [26, 178, 203, 255],
      [0, 102, 161, 255],
    ],
  },
  {
    steps: [0.0, 90.1],
    colors: [
      [98, 114, 128, 255],
      [52, 63, 51, 255],
    ],
  },
  {
    steps: [],
    colors: [],
  },
  {
    steps: [0.0, 90.5],
    colors: [
      [255, 19, 120, 255],
      [0, 0, 0, 255],
    ],
  },
  {
    steps: [0.0, 79.4],
    colors: [
      [91, 140, 245, 255],
      [240, 131, 252, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [111, 231, 176, 255],
      [154, 198, 242, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [87, 108, 117, 255],
      [37, 50, 55, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [255, 12, 253, 255],
      [255, 241, 53, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [248, 213, 214, 255],
      [243, 242, 229, 255],
    ],
  },
  {
    steps: [100],
    colors: [[191, 0, 255, 255]],
  },
  {
    steps: [0.0, 100],
    colors: [
      [15, 213, 172, 255],
      [34, 182, 198, 255],
    ],
  },
  {
    steps: [0.3, 90.5],
    colors: [
      [82, 107, 248, 255],
      [167, 139, 252, 255],
    ],
  },
  {
    steps: [0.0, 22.9, 76.7, 100],
    colors: [
      [30, 144, 231, 255],
      [56, 113, 209, 255],
      [38, 76, 140, 255],
      [31, 63, 116, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [233, 122, 129, 255],
      [181, 64, 149, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [255, 131, 61, 255],
      [249, 183, 23, 255],
    ],
  },
  {
    steps: [23.2, 45.0, 93.1],
    colors: [
      [69, 237, 244, 255],
      [26, 243, 158, 255],
      [241, 78, 163, 255],
    ],
  },
  {
    steps: [15.6, 80.3],
    colors: [
      [251, 222, 238, 255],
      [206, 201, 226, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [94, 252, 236, 255],
      [0, 40, 143, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [255, 2, 2, 255],
      [254, 78, 172, 255],
    ],
  },
  {
    steps: [0.0, 97.9],
    colors: [
      [88, 242, 174, 255],
      [0, 130, 240, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [137, 210, 253, 255],
      [255, 241, 188, 255],
    ],
  },
  {
    steps: [0.0, 97.4],
    colors: [
      [251, 106, 12, 255],
      [193, 11, 135, 255],
    ],
  },
  {
    steps: [0.0, 90.1],
    colors: [
      [236, 158, 248, 255],
      [131, 83, 241, 255],
    ],
  },
  {
    steps: [0.0, 72.4],
    colors: [
      [186, 162, 252, 255],
      [176, 248, 242, 255],
    ],
  },
  {
    steps: [0.0, 90.1],
    colors: [
      [0, 102, 161, 255],
      [0, 68, 108, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [0, 164, 189, 255],
      [0, 189, 165, 255],
    ],
  },
  {
    steps: [16.3, 100],
    colors: [
      [173, 53, 53, 255],
      [240, 60, 60, 255],
    ],
  },
  {
    steps: [0.3, 90.5],
    colors: [
      [221, 255, 214, 255],
      [214, 254, 254, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [17, 127, 238, 255],
      [190, 47, 252, 255],
    ],
  },
  {
    steps: [100],
    colors: [[36, 212, 219, 255]],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [143, 170, 210, 255],
      [255, 177, 177, 255],
    ],
  },
  {
    steps: [],
    colors: [],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [145, 217, 243, 255],
      [147, 164, 243, 255],
    ],
  },
  {
    steps: [0.0, 51.2, 100],
    colors: [
      [53, 92, 125, 255],
      [108, 91, 123, 255],
      [192, 108, 132, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [208, 89, 109, 255],
      [231, 156, 118, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [221, 49, 49, 255],
      [119, 0, 0, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [255, 65, 149, 255],
      [238, 130, 250, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [126, 70, 195, 255],
      [156, 236, 247, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [134, 47, 199, 255],
      [235, 56, 137, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [126, 155, 212, 255],
      [87, 83, 247, 255],
    ],
  },
  {
    steps: [5.9, 86.0],
    colors: [
      [125, 248, 179, 255],
      [59, 18, 206, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [59, 149, 237, 255],
      [7, 91, 173, 255],
    ],
  },
  {
    steps: [],
    colors: [],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [101, 255, 253, 255],
      [36, 153, 238, 255],
    ],
  },
  {
    steps: [0.0, 48.2, 100],
    colors: [
      [247, 179, 140, 255],
      [229, 118, 127, 255],
      [149, 83, 153, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [0, 144, 243, 255],
      [0, 86, 240, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [89, 195, 195, 255],
      [14, 175, 181, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [255, 107, 6, 255],
      [255, 1, 107, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [251, 230, 59, 255],
      [161, 255, 63, 255],
    ],
  },
  {
    steps: [0.0, 90.1],
    colors: [
      [255, 18, 72, 255],
      [255, 216, 29, 255],
    ],
  },
  {
    steps: [0.0, 71.6, 99.8],
    colors: [
      [237, 120, 153, 255],
      [238, 216, 190, 255],
      [249, 238, 221, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [239, 167, 167, 255],
      [215, 123, 191, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [39, 199, 171, 255],
      [70, 143, 197, 255],
    ],
  },
  {
    steps: [1.6, 97.4],
    colors: [
      [150, 231, 157, 255],
      [21, 191, 236, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [99, 55, 255, 255],
      [39, 170, 255, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [0, 33, 71, 255],
      [47, 183, 186, 255],
    ],
  },
  {
    steps: [100],
    colors: [[59, 188, 241, 255]],
  },
  {
    steps: [0.0, 100],
    colors: [
      [250, 66, 255, 255],
      [255, 213, 136, 247],
    ],
  },
  {
    steps: [12.2, 84.8],
    colors: [
      [242, 11, 54, 255],
      [237, 52, 163, 255],
    ],
  },
  {
    steps: [34.7, 92.3],
    colors: [
      [255, 94, 157, 255],
      [255, 78, 6, 255],
    ],
  },
  {
    steps: [0.0, 99.0],
    colors: [
      [243, 2, 2, 255],
      [57, 4, 4, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [255, 146, 122, 255],
      [254, 216, 124, 255],
    ],
  },
  {
    steps: [],
    colors: [],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [0, 76, 152, 255],
      [37, 169, 212, 216],
    ],
  },
  {
    steps: [14.9, 86.1],
    colors: [
      [79, 0, 196, 255],
      [255, 83, 83, 255],
    ],
  },
  {
    steps: [0.0, 90.1],
    colors: [
      [255, 104, 107, 255],
      [255, 187, 103, 255],
    ],
  },
  {
    steps: [21.4, 50.4, 100],
    colors: [
      [240, 70, 119, 255],
      [255, 160, 128, 255],
      [255, 255, 255, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [148, 241, 246, 255],
      [148, 189, 246, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [23, 156, 214, 255],
      [52, 48, 111, 255],
    ],
  },
  {
    steps: [1.1, 100],
    colors: [
      [26, 42, 244, 255],
      [79, 183, 255, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [199, 244, 100, 255],
      [78, 205, 196, 255],
    ],
  },
  {
    steps: [2.2, 20.2, 58.6, 75.0, 89.6, 96.1],
    colors: [
      [19, 55, 115, 255],
      [32, 7, 80, 255],
      [27, 88, 111, 255],
      [115, 88, 44, 255],
      [99, 19, 90, 255],
      [12, 51, 76, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [250, 138, 138, 255],
      [198, 124, 204, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [237, 220, 140, 255],
      [241, 121, 121, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [244, 238, 124, 255],
      [227, 106, 106, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [215, 248, 247, 255],
      [102, 188, 239, 255],
    ],
  },
  {
    steps: [0.0, 46.7, 100],
    colors: [
      [0, 239, 247, 255],
      [0, 122, 147, 255],
      [0, 29, 72, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [48, 49, 142, 255],
      [94, 93, 223, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [227, 229, 255, 255],
      [135, 145, 255, 255],
    ],
  },
  {
    steps: [39.3, 72.5],
    colors: [
      [194, 227, 255, 255],
      [226, 196, 253, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [41, 166, 255, 255],
      [153, 240, 255, 255],
    ],
  },
  {
    steps: [23.0, 100],
    colors: [
      [26, 196, 203, 255],
      [26, 206, 191, 130],
    ],
  },
  {
    steps: [0.0, 44.2, 100],
    colors: [
      [255, 188, 15, 255],
      [255, 115, 0, 255],
      [154, 0, 255, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [255, 110, 112, 255],
      [255, 116, 142, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [255, 246, 63, 255],
      [226, 0, 0, 255],
    ],
  },
  {
    steps: [0.1, 57.6],
    colors: [
      [255, 243, 242, 255],
      [255, 247, 220, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [255, 36, 36, 255],
      [103, 114, 213, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [73, 88, 231, 255],
      [77, 19, 124, 247],
    ],
  },
  {
    steps: [13.0, 75.7],
    colors: [
      [254, 172, 245, 255],
      [233, 34, 210, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [17, 23, 58, 255],
      [58, 85, 148, 255],
    ],
  },
  {
    steps: [0.0, 90.2],
    colors: [
      [201, 221, 162, 255],
      [249, 223, 130, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [254, 158, 45, 255],
      [215, 78, 32, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [22, 79, 141, 255],
      [13, 16, 47, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [225, 255, 12, 255],
      [255, 36, 36, 255],
    ],
  },
  {
    steps: [0.0, 67.0],
    colors: [
      [87, 152, 235, 255],
      [133, 222, 213, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [105, 147, 228, 255],
      [51, 33, 218, 255],
    ],
  },
  {
    steps: [0.0, 48.7],
    colors: [
      [239, 229, 171, 255],
      [127, 199, 178, 255],
    ],
  },
  {
    steps: [9.8, 26.3, 43.8, 59.2, 74.6, 89.4],
    colors: [
      [255, 150, 197, 255],
      [255, 190, 94, 255],
      [246, 255, 94, 255],
      [113, 255, 129, 255],
      [187, 255, 255, 255],
      [214, 146, 255, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [252, 207, 207, 255],
      [255, 243, 228, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [252, 253, 137, 255],
      [72, 243, 215, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [233, 14, 14, 255],
      [63, 47, 47, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [253, 76, 186, 255],
      [246, 72, 72, 255],
    ],
  },
  {
    steps: [5.5, 100],
    colors: [
      [239, 252, 250, 255],
      [154, 192, 206, 255],
    ],
  },
  {
    steps: [0.0, 74.7],
    colors: [
      [216, 247, 229, 255],
      [249, 238, 238, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [249, 172, 251, 255],
      [180, 68, 244, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [129, 125, 254, 255],
      [111, 167, 254, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [81, 109, 179, 255],
      [178, 98, 152, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [252, 71, 71, 255],
      [255, 131, 131, 255],
    ],
  },
  {
    steps: [0.0, 90.1],
    colors: [
      [242, 235, 243, 255],
      [234, 241, 249, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [236, 253, 170, 255],
      [135, 247, 115, 255],
    ],
  },
  {
    steps: [0.0, 90.2],
    colors: [
      [0, 0, 0, 255],
      [64, 64, 64, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [26, 20, 74, 255],
      [59, 52, 120, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [246, 211, 252, 255],
      [253, 246, 254, 255],
    ],
  },
  {
    steps: [28.1, 100],
    colors: [
      [251, 204, 235, 255],
      [221, 221, 221, 38],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [255, 0, 0, 255],
      [0, 0, 255, 255],
    ],
  },
  {
    steps: [0.2, 89.5],
    colors: [
      [121, 206, 212, 255],
      [101, 167, 191, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [206, 176, 213, 255],
      [132, 120, 181, 255],
    ],
  },
  {
    steps: [5.3, 90.0],
    colors: [
      [209, 234, 205, 255],
      [159, 219, 233, 255],
    ],
  },
  {
    steps: [1.3, 90.0],
    colors: [
      [65, 199, 58, 255],
      [28, 86, 25, 255],
    ],
  },
  {
    steps: [0.1, 90.0],
    colors: [
      [255, 200, 124, 255],
      [252, 251, 121, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [0, 249, 243, 255],
      [14, 113, 159, 255],
    ],
  },
  {
    steps: [0.0, 42.9, 88.8],
    colors: [
      [234, 131, 131, 255],
      [244, 152, 152, 255],
      [245, 209, 151, 255],
    ],
  },
  {
    steps: [0.0, 72.3],
    colors: [
      [83, 113, 245, 255],
      [107, 228, 184, 255],
    ],
  },
  {
    steps: [79.0, 100],
    colors: [
      [244, 123, 198, 255],
      [139, 108, 234, 255],
    ],
  },
  {
    steps: [0.3, 90.5],
    colors: [
      [113, 213, 225, 255],
      [73, 146, 226, 255],
    ],
  },
  {
    steps: [6.2, 100],
    colors: [
      [241, 235, 156, 255],
      [5, 134, 142, 255],
    ],
  },
  {
    steps: [0.0, 93.5],
    colors: [
      [0, 150, 165, 255],
      [0, 153, 102, 168],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [61, 172, 172, 255],
      [21, 195, 197, 255],
    ],
  },
  {
    steps: [0.0, 90.1],
    colors: [
      [98, 50, 122, 255],
      [56, 0, 92, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [206, 155, 243, 255],
      [142, 229, 247, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [255, 15, 15, 255],
      [228, 0, 228, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [11, 218, 81, 255],
      [0, 191, 255, 255],
    ],
  },
  {
    steps: [0.1, 100],
    colors: [
      [249, 233, 251, 255],
      [131, 3, 131, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [223, 91, 91, 255],
      [255, 209, 110, 255],
    ],
  },
  {
    steps: [0.0, 85.6],
    colors: [
      [246, 232, 249, 255],
      [231, 93, 196, 84],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [248, 126, 232, 255],
      [185, 147, 251, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [238, 9, 121, 255],
      [255, 106, 0, 255],
    ],
  },
  {
    steps: [9.1, 76.8],
    colors: [
      [161, 234, 239, 255],
      [125, 137, 251, 255],
    ],
  },
  {
    steps: [4.0, 51.1, 78.2, 100],
    colors: [
      [246, 88, 88, 255],
      [247, 250, 52, 255],
      [16, 215, 255, 255],
      [255, 10, 142, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [206, 18, 18, 255],
      [122, 21, 21, 255],
    ],
  },
  {
    steps: [0.0, 90.1],
    colors: [
      [117, 205, 217, 255],
      [1, 111, 126, 255],
    ],
  },
  {
    steps: [0.0, 90.0],
    colors: [
      [10, 64, 88, 255],
      [15, 164, 102, 255],
    ],
  },
  {
    steps: [0.0, 100],
    colors: [
      [215, 75, 80, 255],
      [243, 146, 32, 255],
    ],
  },
];
