@if (editor?.sequences?.length > 0) {
  <confect-split-view
    #split
    (specChange)="grid.setCanvas(); update()"
    [spec]="editorMode === CreativeEditorMode.VIDEO ? videoSpec : imageSpec"
  >
    <ng-template split-content-tmp let-view="view">
      <div split-content [show]="view === 'canvas'" class="relative pt-14">
        @if (view === "canvas") {
          <div
            id="canvas"
            class="z-10 relative h-full w-full"
            [ngClass]="{
              'video-mode': editorMode === CreativeEditorMode.VIDEO,
              'image-mode': editorMode !== CreativeEditorMode.VIDEO,
            }"
            #canvas
            (wheel)="scrollZoom($event)"
          >
            <ngx-creatives-edit-grid-v3
              #grid
              [editor]="editor"
              [live]="live"
              [aspect]="selectedResolution"
              [resolution]="currentLiveResolution"
              [zoomFactor]="currentZoom"
              (context)="
                rightClick($event, editor.layer ?? editor.multiSelectedLayers())
              "
              [showBox]="dropDown?.open || groupDropDown?.open"
              [editorMode]="editorMode"
              [time]="editor.selectedTime"
              [scrollOffset]="scrollOffset"
              [allowSnap]="allowSnap"
            />
          </div>
          <div
            class="footer"
            [ngClass]="{
              'video-mode': editorMode === CreativeEditorMode.VIDEO,
              'image-mode': editorMode !== CreativeEditorMode.VIDEO,
            }"
          >
            <div class="flex items-center justify-between mx-5 h-full">
              <div class="flex items-center space-x-2">
                @if (allowModeSwitching) {
                  <div
                    tooltip
                    [tooltipDisable]="videoSupport || !subscriptionV2"
                    tooltipPosition="above"
                    [tooltipTemplate]="noVideoSupport"
                    [tooltipExtra]="{ plans: toPlans, markets: toMarkets }"
                    class="shadow-sm rounded-md"
                    [ngClass]="{ 'opacity-50': !videoSupport }"
                  >
                    <button
                      [disabled]="!videoSupport"
                      type="button"
                      class="relative inline-flex items-center px-2 py-1 rounded-l-md border-l border-t border-b border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                      [ngClass]="{
                        'bg-gray-400 text-white':
                          editorMode === CreativeEditorMode.IMAGE,
                        'bg-white text-black':
                          editorMode !== CreativeEditorMode.IMAGE,
                      }"
                      (click)="changeMode(CreativeEditorMode.IMAGE)"
                    >
                      <confect-icon
                        icon="photo_landscape_outlined"
                        iconClass="text-xl"
                      />
                    </button>
                    <button
                      [disabled]="!videoSupport"
                      type="button"
                      class="relative inline-flex items-center px-2 py-1 rounded-r-md border-r border-t border-b border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                      [ngClass]="{
                        'bg-gray-400 text-white':
                          editorMode === CreativeEditorMode.VIDEO,
                        'bg-white text-black':
                          editorMode !== CreativeEditorMode.VIDEO,
                      }"
                      (click)="changeMode(CreativeEditorMode.VIDEO)"
                    >
                      <confect-icon
                        icon="video_horizontal"
                        iconClass="text-xl"
                      />
                    </button>
                  </div>
                }
                @if (allowResolutionChange && viewInit) {
                  <confect-select
                    class="w-40 cursor-pointer"
                    [options]="resOptions"
                    [(ngModel)]="selectedResolution"
                    [searchable]="false"
                    bindLabel="label"
                    bindValue="label"
                    (ngModelChange)="
                      resolutionChanged(); selectedResolutionChange.emit($event)
                    "
                  />
                }
              </div>
              <div class="flex items-center space-x-2">
                <confect-slider
                  class="w-60"
                  tooltip
                  tooltipText="Zoom canvas"
                  tooltipPosition="above"
                  [sliderInfo]="{
                    interval: { start: minZoom * 100, end: maxZoom * 100 },
                    stepSize: 1,
                    default: 100,
                  }"
                  [writable]="false"
                  [decimals]="0"
                  type="light"
                  [ngModel]="currentZoom * 100"
                  (ngModelChange)="
                    currentZoom = $event / 100; scrollOffset = { x: 0, y: 0 }
                  "
                />
              </div>
            </div>
          </div>
        }
      </div>
      <div
        split-content
        [show]="view === 'properties'"
        class="pr-2 pl-1 pb-2"
        [ngClass]="{
          'pt-16': editorMode === CreativeEditorMode.VIDEO,
          'pt-1': editorMode !== CreativeEditorMode.VIDEO,
        }"
      >
        @if (view === "properties") {
          <ngx-creative-edit-properties
            class="w-full h-full z-[400]"
            #propertiesBox
            [editorMode]="editorMode"
            [editor]="editor"
            [live]="live"
            [specs]="specs"
            [helpEnabled]="helpEnabled"
            [extraSettings]="extraSettings"
            [(selectedPreviewFilters)]="selectedPreviewFilters"
            (selectedPreviewFiltersChange)="
              selectedPreviewFiltersChange.emit($event)
            "
            [previewFilterOptions]="previewFilterOptions"
            [(allowSnap)]="allowSnap"
            [showPreview]="showPreview"
          />
        }
      </div>
      <div
        split-content
        [show]="view === 'layers'"
        class="pr-2 pl-1 pt-16 pb-1"
      >
        @if (view === "layers") {
          <confect-card
            class="timeline-container image-mode shadow overflow-y-auto w-full h-full"
            [fullSize]="true"
          >
            <confect-card-body>
              <ngx-creatives-edit-timeline
                [editor]="editor"
                [live]="live"
                [specs]="specs"
                [showTimeline]="false"
                [raisedError]="raisedError"
                [allowProductActions]="!forceSingleProduct"
                (context)="rightClick($event.event, $event.layer)"
              />
            </confect-card-body>
          </confect-card>
        }
      </div>
      <div
        split-content
        [show]="view === 'timeline'"
        class="pl-2 pb-2 pr-1 pt-1"
      >
        @if (view === "timeline") {
          <confect-card
            class="timeline-container video-mode shadow overflow-y-auto w-full h-full"
            [withoutPadding]="true"
            [fullSize]="true"
          >
            <confect-card-body>
              <div class="w-full h-full">
                <ngx-creatives-edit-timeline
                  #timeline
                  [editor]="editor"
                  [live]="live"
                  [specs]="specs"
                  [showTimeline]="true"
                  [raisedError]="raisedError"
                  [allowProductActions]="!forceSingleProduct"
                  (context)="rightClick($event.event, $event.layer)"
                />
              </div>
            </confect-card-body>
          </confect-card>
        }
      </div>
    </ng-template>
  </confect-split-view>
}

<ng-template #previewWindow let-close="close">
  @if (previewShow) {
    <div class="h-[70vh] w-[80vh]">
      <ngx-creatives-preview-window
        #previewer
        [editor]="editor"
        [selectedFilters]="selectedPreviewFilters"
        [mode]="editorMode"
        [resolution]="currentPreviewResolution"
        (renderError)="onRenderError($event)"
        (closeWindow)="close()"
      />
    </div>
  }
</ng-template>

<!-- <confect-slide-over
  title="Preview products"
  #previewProducts
  (cancel)="resetProductFilter()"
>
  <div class="flex flex-col items-center space-y-4 mx-4">
    <ngx-product-filter
      style="max-width: 100%"
      [filterOptions]="previewFilterOptions"
      [(selectedFilters)]="selectedPreviewFilters"
      (selectedFiltersChange)="productFiltersChanged()"
    />

    <confect-button
      class="w-full"
      [block]="true"
      type="white"
      size="large"
      (click)="resetProductFilter()"
      >Reset</confect-button
    >

    <confect-button
      class="w-full"
      [block]="true"
      size="large"
      (click)="applyProductFilter()"
      >Apply</confect-button
    >
  </div>
</confect-slide-over> -->
<confect-modal #customResolutionModal>
  <div class="text-center">
    <div class="grid grid-cols-2 gap-8">
      <div class="text-center">Width</div>
      <div class="text-center">Height</div>
      <confect-input [(ngModel)]="resolutions.custom.size[0]" type="number" />
      <confect-input [(ngModel)]="resolutions.custom.size[1]" type="number" />
    </div>
  </div>
  <div class="flex items-center justify-center space-x-8 mt-5">
    <confect-button (click)="customResolutionModal.hide()" type="white"
      >Cancel</confect-button
    >
    <confect-button
      (click)="configureResolution(); customResolutionModal.hide()"
      type="secondary"
      >Apply</confect-button
    >
  </div>
</confect-modal>

<ng-template #noVideoSupport let-extra="extra" let-close="close">
  <div class="text-white w-52">
    @if (hasLicense) {
      Upgrade to the <span class="font-bold">Pro</span> plan or better to get
      access to video designs and catalogs.
      <div class="w-full flex justify-center mt-5">
        <confect-button type="white" (click)="extra.plans()">
          See Plans
        </confect-button>
      </div>
    } @else {
      This market is inactive and needs to be assigned a license for this
      feature to be available.
      <div class="w-full flex justify-center mt-5">
        <confect-button type="white" (click)="extra.markets()">
          Go to Markets
        </confect-button>
      </div>
    }
  </div>
</ng-template>

<ng-template #noVideoSupportRes let-extra="extra" let-close="close">
  <div class="text-white w-52">
    @if (hasLicense) {
      Upgrade to the <span class="font-bold">Pro</span> plan or better to get
      access to video designs and catalogs.
    } @else {
      This market is inactive and needs to be assigned a license for this
      feature to be available.
    }
  </div>
</ng-template>
