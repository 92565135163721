export interface AcademyArticle {
  title: string;
  href: string;
  image: string;
}

export interface AcademySection {
  title: string;
  subtitle: string;
  articles: AcademyArticle[];
}

const performanceSection: AcademySection = {
  title: "Performance insights and examples",
  subtitle: "Cheat sheets on how different designs perform",
  articles: [
    {
      title: "Performance insights",
      image:
        "https://cdn.confect.io/uploads/media/design-catalog-ads-and-dynamic-product-ads-on-meta-and-facebook%20(1).jpg",
      href: "https://confect.io/tactics/catalog-ads-designs-the-big-cheat-sheet",
    },
    {
      title: "Black Friday designs",
      image:
        "https://cdn.confect.io/uploads/media/black_friday_catalog_ads%202.jpg",
      href: "https://confect.io/tactics/catalog-ads-black-friday-week-cyber-monday",
    },
  ],
};

const tipsTricksSection: AcademySection = {
  title: "Catalog Ads tips & tricks",
  subtitle: "Learn how to use Catalog Ads the best in different scenarios.",
  articles: [
    {
      title: "Always-on Campaigns",
      image:
        "https://cdn.confect.io/uploads/media/always%20on%20catalog%20ads%20campaigns.jpg",
      href: "https://confect.io/tactics/always-on-catalog-ads",
    },
    {
      title: "Advantage+ Shopping",
      image: "https://cdn.confect.io/uploads/media/Advantage+-1.jpg",
      href: "https://confect.io/tactics/advantage-shopping-campaigns-best-setup",
    },

    {
      title: "Sales & Campaigns",
      image: "https://cdn.confect.io/uploads/media/Campaigns%20(1).jpg",
      href: "https://confect.io/tactics/best-campaigns-for-catalog-ads",
    },

    {
      title: "Your first Video Catalog Ad",
      image:
        "https://cdn.confect.io/uploads/media/video_catalog_ad_on_meta%20(1).jpg",
      href: "https://confect.io/tactics/getting-started-with-video-catalog-ads-meta",
    },
  ],
};

const industrySection: AcademySection = {
  title: "Catalog Ads for your industry",
  subtitle:
    "We have collected different tactics that some of the best brands in each industry are using for their Catalog Ads.",
  articles: [
    {
      href: "https://confect.io/tactics/meta-facebook-catalog-ads-for-beauty-brands",
      image:
        "https://cdn.confect.io/uploads/media/catalog%20ads%20for%20beauty%20brands.jpg",
      title: "Beauty brands",
    },
    {
      href: "https://confect.io/tactics/meta-facebook-catalog-ads-for-electronics-brands",
      image: "https://cdn.confect.io/uploads/media/Electronics.jpg",
      title: "Electronic brands",
    },
    {
      href: "https://confect.io/tactics/meta-facebook-catalog-ads-for-fashion-brands",
      image: "https://cdn.confect.io/uploads/media/Fashion.jpg",
      title: "Fashion brands",
    },
    {
      href: "https://confect.io/tactics/meta-facebook-catalog-ads-for-food-brands",
      image: "https://cdn.confect.io/uploads/media/Food.jpg",
      title: "Food and Drinks brands",
    },
    {
      href: "https://confect.io/tactics/meta-facebook-catalog-ads-for-home-brands",
      image: "https://cdn.confect.io/uploads/media/Home-1.jpg",
      title: "Home & Lifestyle brands",
    },
    {
      href: "https://confect.io/tactics/meta-facebook-catalog-ads-for-sports-brands",
      image: "https://cdn.confect.io/uploads/media/sport.jpg",
      title: "Sport & Fitness brands",
    },
  ],
};
const inspirationSection: AcademySection = {
  title: "Design examples & inspiration",
  subtitle: "Learn by example from more than 400 different designs.",
  articles: [
    {
      href: "https://academy.confect.io/articles/read/packshot-product-designs-in-catalog-ads",
      image:
        "https://cdn.confect.io/uploads/media/packshots-dynamic-product-ads-header.jpg",
      title: "Packshot designs",
    },
    {
      href: "https://academy.confect.io/articles/read/lifestyle-and-photograph-product-images-in-catalog-ads",
      image:
        "https://cdn.confect.io/uploads/media/lifestyle-model-dynamic-product-ads-header.jpg",
      title: "Lifestyle & models",
    },
  ],
};

const designChoiceSection: AcademySection = {
  title: "Deep dive into design choices",
  subtitle:
    "Guides on specific different design choices, how well they perform on average, and examples of how to design them better.",
  articles: [
    {
      href: "https://confect.io/blog/showing-prices-in-dynamic-product-ads",
      image:
        "https://cdn.confect.io/uploads/media/price_blog_header%20(1)%20(1).png",
      title: "Showing prices",
    },
    {
      href: "https://confect.io/blog/showing-discounts-in-dynamic-product-ads",
      image:
        "https://cdn.confect.io/uploads/media/savings_blog_header%20(1)%20(1).png",
      title: "Discounts & savings",
    },
    {
      href: "https://confect.io/blog/brand-in-dynamic-product-ads",
      image:
        "https://cdn.confect.io/uploads/media/product%20brand_blog_header%20(3)%20(1).png",
      title: "Showing the brand",
    },
    {
      href: "https://confect.io/blog/custom-labels-dynamic-product-ads",
      image:
        "https://cdn.confect.io/uploads/media/features_blog_header%20(2)%20(1).png",
      title: "Attributes & Features",
    },
    {
      href: "https://confect.io/blog/new-products-in-dynamic-product-ads",
      image: "https://cdn.confect.io/uploads/media/news_blog_header%20(1).png",
      title: "New products",
    },
    {
      href: "https://confect.io/blog/logos-in-dynamic-product-ads",
      image:
        "https://cdn.confect.io/uploads/media/shoplogo_blog_header%20(4)%20(1).png",
      title: "Showing your logo",
    },
    {
      href: "https://confect.io/blog/urgency-in-dynamic-product-ads",
      image:
        "https://cdn.confect.io/uploads/media/limited_blog_header%20(3)%20(1).png",
      title: "Urgency & limited time",
    },
    {
      href: "https://confect.io/blog/campaigns-in-dynamic-product-ads",
      image:
        "https://cdn.confect.io/uploads/media/overall%20campaign_blog_header%20(1).png",
      title: "Campaigns",
    },
    {
      href: "https://confect.io/blog/product-name-in-dynamic-product-ads",
      image:
        "https://cdn.confect.io/uploads/media/product%20brand_blog_header%20(4).png",
      title: "Product name",
    },
    {
      href: "https://confect.io/blog/reviews-and-ranking-in-dynamic-product-ads",
      image:
        "https://cdn.confect.io/uploads/media/reviews_blog_header%20(1)%20(1).png",
      title: "Reviews & rankings",
    },
    {
      href: "https://confect.io/blog/product-assets-in-dynamic-product-ads",
      image:
        "https://cdn.confect.io/uploads/media/prodassets_blog_header%20(2).png",
      title: "Product assets",
    },
    {
      href: "https://confect.io/blog/additional-images-in-dynamic-product-ads",
      image:
        "https://cdn.confect.io/uploads/media/additionalimgs_blog_header%20(1).png",
      title: "Additional images",
    },
  ],
};

export const ACADEMY_ARTICLES: AcademySection[] = [
  performanceSection,
  inspirationSection,
  tipsTricksSection,
  industrySection,
  designChoiceSection,
];
