import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { ConfectModule } from "@theme/@confect/confect.module";
import { ThemeModule } from "@theme/theme.module";

import { EffectEditComponent } from "./effects/effect-edit/effect-edit.component";
import { EffectsComponent } from "./effects/effects.component";

const COMPONENTS = [EffectsComponent, EffectEditComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, RouterModule, ConfectModule, ThemeModule],
  exports: [...COMPONENTS],
})
export class EffectsModule {}
