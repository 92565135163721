export const SPEC = [
  {
    function: "intro",
    name: "Intro",
    spec: [
      {
        name: "duration",
        display: "Duration",
        default: 0.2,
        value_type: "number",
        icon: "stopwatch",
      },
      {
        name: "offset",
        display: "Start delay",
        default: 0,
        value_type: "number",
        icon: "clock",
      },
      {
        name: "slide_in_direction",
        display: "Slide direction",
        default: null,
        value_type: "selection",
        icon: "arrows-alt",
        options: ["top", "right", "bottom", "left"],
      },
      {
        name: "initial_position_x",
        display: "Horizontal offset",
        default: 0,
        value_type: "percentage",
        icon: "arrows-alt-h",
      },
      {
        name: "initial_position_y",
        display: "Vertical offset",
        default: 0,
        value_type: "percentage",
        icon: "arrows-alt-v",
      },
      {
        name: "initial_opacity",
        display: "Opacity",
        default: 100,
        value_type: "percentage",
        icon: "adjust",
      },
      {
        name: "initial_scale",
        display: "Scale",
        default: 100,
        value_type: "percentage",
        icon: "compress-alt",
      },
      {
        name: "initial_rotation",
        display: "Rotation",
        default: 0,
        value_type: "number",
        icon: "sync",
      },
      {
        name: "speed_curve",
        display: "Speed Curve",
        default: null,
        value_type: "selection",
        icon: "spiral",
        options: [
          "QuadEaseInOut",
          "QuadEaseIn",
          "QuadEaseOut",
          "CubicEaseInOut",
          "CubicEaseIn",
          "CubicEaseOut",
          "QuarticEaseInOut",
          "QuarticEaseIn",
          "QuarticEaseOut",
          "QuinticEaseInOut",
          "QuinticEaseIn",
          "QuinticEaseOut",
          "SineEaseInOut",
          "SineEaseIn",
          "SineEaseOut",
          "CircularEaseIn",
          "CircularEaseInOut",
          "CircularEaseOut",
          "ExponentialEaseInOut",
          "ExponentialEaseIn",
          "ExponentialEaseOut",
          "ElasticEaseIn",
          "ElasticEaseInOut",
          "ElasticEaseOut",
          "BackEaseIn",
          "BackEaseInOut",
          "BackEaseOut",
          "BounceEaseIn",
          "BounceEaseInOut",
          "BounceEaseOut",
        ],
      },
    ],
  },
  {
    function: "outro",
    name: "Outro",
    spec: [
      {
        name: "duration",
        display: "Duration",
        default: 0.2,
        value_type: "number",
        icon: "stopwatch",
      },
      {
        name: "offset",
        display: "Start delay",
        default: 0,
        value_type: "number",
        icon: "clock",
      },
      {
        name: "slide_in_direction",
        display: "Slide direction",
        default: null,
        value_type: "selection",
        icon: "arrows-alt",
        options: ["top", "right", "bottom", "left"],
      },
      {
        name: "initial_position_x",
        display: "Horizontal offset",
        default: 0,
        value_type: "percentage",
        icon: "arrows-alt-h",
      },
      {
        name: "initial_position_y",
        display: "Vertical offset",
        default: 0,
        value_type: "percentage",
        icon: "arrows-alt-v",
      },
      {
        name: "initial_opacity",
        display: "Opacity",
        default: 100,
        value_type: "percentage",
        icon: "adjust",
      },
      {
        name: "initial_scale",
        display: "Scale",
        default: 100,
        value_type: "percentage",
        icon: "compress-alt",
      },
      {
        name: "initial_rotation",
        display: "Rotation",
        default: 0,
        value_type: "number",
        icon: "sync",
      },
      {
        name: "speed_curve",
        display: "Speed Curve",
        default: null,
        value_type: "selection",
        icon: "spiral",
        options: [
          "QuadEaseInOut",
          "QuadEaseIn",
          "QuadEaseOut",
          "CubicEaseInOut",
          "CubicEaseIn",
          "CubicEaseOut",
          "QuarticEaseInOut",
          "QuarticEaseIn",
          "QuarticEaseOut",
          "QuinticEaseInOut",
          "QuinticEaseIn",
          "QuinticEaseOut",
          "SineEaseInOut",
          "SineEaseIn",
          "SineEaseOut",
          "CircularEaseIn",
          "CircularEaseInOut",
          "CircularEaseOut",
          "ExponentialEaseInOut",
          "ExponentialEaseIn",
          "ExponentialEaseOut",
          "ElasticEaseIn",
          "ElasticEaseInOut",
          "ElasticEaseOut",
          "BackEaseIn",
          "BackEaseInOut",
          "BackEaseOut",
          "BounceEaseIn",
          "BounceEaseInOut",
          "BounceEaseOut",
        ],
      },
    ],
  },
  {
    function: "move",
    name: "Move",
    spec: [
      {
        name: "duration",
        display: "Duration",
        default: 0.2,
        value_type: "number",
        icon: "stopwatch",
      },
      {
        name: "offset",
        display: "Start delay",
        default: 0,
        value_type: "number",
        icon: "clock",
      },
      {
        name: "x",
        display: "Horizontal",
        default: 0,
        value_type: "percentage",
        icon: "arrows-alt-h",
      },
      {
        name: "y",
        display: "Vertical",
        default: 0,
        value_type: "percentage",
        icon: "arrows-alt-v",
      },
      {
        name: "speed_curve",
        display: "Speed Curve",
        default: "QuadEaseInOut",
        value_type: "selection",
        icon: "spiral",
        options: [
          "QuadEaseInOut",
          "QuadEaseIn",
          "QuadEaseOut",
          "CubicEaseInOut",
          "CubicEaseIn",
          "CubicEaseOut",
          "QuarticEaseInOut",
          "QuarticEaseIn",
          "QuarticEaseOut",
          "QuinticEaseInOut",
          "QuinticEaseIn",
          "QuinticEaseOut",
          "SineEaseInOut",
          "SineEaseIn",
          "SineEaseOut",
          "CircularEaseIn",
          "CircularEaseInOut",
          "CircularEaseOut",
          "ExponentialEaseInOut",
          "ExponentialEaseIn",
          "ExponentialEaseOut",
          "ElasticEaseIn",
          "ElasticEaseInOut",
          "ElasticEaseOut",
          "BackEaseIn",
          "BackEaseInOut",
          "BackEaseOut",
          "BounceEaseIn",
          "BounceEaseInOut",
          "BounceEaseOut",
        ],
      },
    ],
  },
  {
    function: "rotation2d",
    name: "Rotation 2D",
    spec: [
      {
        name: "duration",
        display: "Duration",
        default: 0.2,
        value_type: "number",
        icon: "stopwatch",
      },
      {
        name: "offset",
        display: "Start delay",
        default: 0,
        value_type: "number",
        icon: "clock",
      },
      {
        name: "gamma",
        display: "Angle",
        default: 0,
        value_type: "number",
        icon: "sync",
      },
      {
        name: "speed_curve",
        display: "Speed Curve",
        default: null,
        value_type: "selection",
        icon: "spiral",
        options: [
          "QuadEaseInOut",
          "QuadEaseIn",
          "QuadEaseOut",
          "CubicEaseInOut",
          "CubicEaseIn",
          "CubicEaseOut",
          "QuarticEaseInOut",
          "QuarticEaseIn",
          "QuarticEaseOut",
          "QuinticEaseInOut",
          "QuinticEaseIn",
          "QuinticEaseOut",
          "SineEaseInOut",
          "SineEaseIn",
          "SineEaseOut",
          "CircularEaseIn",
          "CircularEaseInOut",
          "CircularEaseOut",
          "ExponentialEaseInOut",
          "ExponentialEaseIn",
          "ExponentialEaseOut",
          "ElasticEaseIn",
          "ElasticEaseInOut",
          "ElasticEaseOut",
          "BackEaseIn",
          "BackEaseInOut",
          "BackEaseOut",
          "BounceEaseIn",
          "BounceEaseInOut",
          "BounceEaseOut",
        ],
      },
    ],
  },
  {
    function: "scale",
    name: "Scale",
    spec: [
      {
        name: "duration",
        display: "Duration",
        default: 0.2,
        value_type: "number",
        icon: "stopwatch",
      },
      {
        name: "offset",
        display: "Start delay",
        default: 0,
        value_type: "number",
        icon: "clock",
      },
      {
        name: "factor",
        display: "Factor",
        default: 100,
        value_type: "percentage",
        icon: "compress-alt",
      },
      {
        name: "speed_curve",
        display: "Speed Curve",
        default: null,
        value_type: "selection",
        icon: "spiral",
        options: [
          "QuadEaseInOut",
          "QuadEaseIn",
          "QuadEaseOut",
          "CubicEaseInOut",
          "CubicEaseIn",
          "CubicEaseOut",
          "QuarticEaseInOut",
          "QuarticEaseIn",
          "QuarticEaseOut",
          "QuinticEaseInOut",
          "QuinticEaseIn",
          "QuinticEaseOut",
          "SineEaseInOut",
          "SineEaseIn",
          "SineEaseOut",
          "CircularEaseIn",
          "CircularEaseInOut",
          "CircularEaseOut",
          "ExponentialEaseInOut",
          "ExponentialEaseIn",
          "ExponentialEaseOut",
          "ElasticEaseIn",
          "ElasticEaseInOut",
          "ElasticEaseOut",
          "BackEaseIn",
          "BackEaseInOut",
          "BackEaseOut",
          "BounceEaseIn",
          "BounceEaseInOut",
          "BounceEaseOut",
        ],
      },
      {
        name: "with_previous",
        display: "Start with previous",
        default: false,
        value_type: "boolean",
        icon: "clear",
      },
    ],
  },
];
