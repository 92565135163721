<div
  cdkDropList
  (cdkDropListDropped)="drop($event)"
  class="flex flex-col space-y-2"
>
  <!-- <ng-container > -->

  @for (effect of value; track effect; let i = $index) {
    <div cdkDrag class="flex flex-col rounded border p-2">
      <div class="flex flex-col">
        <div class="flex items-center justify-left space-x-2">
          <confect-icon-button
            class="hover:text-slate-600"
            cdkDragHandle
            icon="move_page"
          />
          <confect-icon-button
            class="hover:text-red-300"
            iconClass="text-red-400"
            icon="delete_outlined"
            (click)="removeEffect(i)"
          />
          <confect-icon-button
            icon="copy"
            (click)="duplicateEffect(effect)"
            class="hover:text-slate-600"
          />
        </div>
        <confect-select
          bindLabel="name"
          bindValue="function"
          [options]="_specItem.data"
          (ngModelChange)="effectChanged(effect, $event)"
          [(ngModel)]="effect.effect"
          style="width: 50%; flex-grow: 2"
          placeholder="None"
          [clearable]="true"
        />
      </div>
      <div>
        <!-- Show the rest of settings if effectType is selected -->
        @if (effect.effect != null && effect.effect !== "") {
          <confect-input
            title="Duration"
            [(ngModel)]="effect.duration"
            [compact]="true"
            icon="time"
          />
          <confect-input
            title="Start time"
            [(ngModel)]="effect.offset"
            [compact]="true"
            icon="remaining_time"
          />
          <confect-checkbox
            title="Start with previous"
            icon="history"
            [(ngModel)]="effect.with_previous"
          />
          <ngx-spec-handler
            [specGroup]="{ items: effectSpecs[i] }"
            [specSettings]="effect.settings"
            [editor]="editor"
            [parentSelection]="effect.effect"
            [helpEnabled]="helpEnabled"
          />
        }
      </div>
    </div>
  }

  <!-- </ng-container> -->
</div>

<div class="flex items-center justify-center mt-5">
  <confect-button type="secondary" (click)="addEffect()">
    Add Effect
  </confect-button>
</div>
