<div fullscreen [backgroundColor]="'rgb(243, 244, 246)'" [allowOverflow]="true">
  <div class="m-8">
    <div
      class="block rounded-md shadow-md fixed top-8 left-8 bottom-8 right-8 bg-white"
      aria-hidden="true"
    >
      <header
        class="relative border-b border-gray-200 text-sm font-medium text-gray-700"
      >
        <div class="py-8 px-8">
          <div class="relative flex justify-end sm:justify-center">
            <a routerLink="/" class="absolute left-0 top-1/2 -mt-4">
              <img src="assets/logo.svg" alt="" class="h-8 w-auto" />
            </a>
            <div class="text-center">
              <span class="font-bold text-xl text-gray-700">New design</span>
            </div>
            <div class="absolute right-0 top-1/2 -mt-4">
              <confect-button type="white" routerLink="/designs/edit"
                >Start from a blank template</confect-button
              >
            </div>
          </div>
        </div>
      </header>

      <main class="absolute top-24 bottom-0 left-0 right-0">
        <ngx-creatives-templates [hideStaticDesigns]="true" />
      </main>
    </div>
  </div>
</div>
