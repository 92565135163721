<confect-tab-menu [tabs]="tabs">
  <ng-template tab-content-tmp let-currentTab="currentTab">
    @for (specGroup of _specGroups; track specGroup; let i = $index) {
      <div tab-content [show]="currentTab === specGroup.group_id" class="mx-5">
        @if (currentTab === specGroup.group_id) {
          <div class="mb-40">
            <ngx-spec-handler
              [limitOptions]="_specLimiter[specGroup.group_name]"
              [specGroup]="specGroup"
              [editor]="editor"
              [specSettings]="specSettings"
              [helpEnabled]="helpEnabled"
              [extraSettings]="extraSettings"
              [raisedError]="raisedError"
              [editorMode]="_editorMode"
            />
          </div>
        }
      </div>
    }
    <div tab-content [show]="currentTab === 'layer'" class="mx-5">
      @if (currentTab === "layer") {
        <div class="mb-40">
          <div class="flex items-center my-2">
            <!-- <div class="w-1/2 text-sm">Opacity</div> -->
            <confect-icon
              icon="opacity"
              iconClass="text-xl font-medium text-gray-700 mr-2"
              class="w-1/2 text-sm"
              >Opacity</confect-icon
            >
            <div class="w-1/2">
              <confect-slider
                [ngModel]="editor.layer.LAYER.opacity ?? 100"
                (ngModelChange)="
                  editor.setOpacity(editor.layer.identifier, $event)
                "
                [sliderInfo]="{ interval: { start: 0, end: 100 }, stepSize: 1 }"
              />
            </div>
          </div>
          <ngx-percentage-spec
            [specItem]="minorSpecItem('Left')"
            [value]="editor.layer.position.x"
            (valueChange)="setPosition('x', $event)"
            placeholderKey="x"
          />

          <ngx-percentage-spec
            [specItem]="minorSpecItem('Top')"
            [value]="editor.layer.position.y"
            (valueChange)="setPosition('y', $event)"
            placeholderKey="y"
          />

          <ngx-percentage-spec
            [specItem]="minorSpecItem('Width')"
            [value]="editor.layer.position.width"
            (valueChange)="setPosition('width', $event)"
            placeholderKey="width"
          />

          <ngx-percentage-spec
            [specItem]="minorSpecItem('Height')"
            [value]="editor.layer.position.height"
            (valueChange)="setPosition('height', $event)"
            placeholderKey="height"
          />
        </div>
      }
    </div>
  </ng-template>
</confect-tab-menu>
