<div class="flex flex-col space-y-7">
  @if (this.elements) {
    @for (section of elements; track section.category.key) {
      <div class="flex-col w-full">
        <div class="flex justify-between items-center px-3">
          <div
            class="flex items-center font-medium text-sm text-gray-700 hover:text-gray-900 cursor-pointer"
            (click)="openElements(section.type, section.category)"
          >
            {{ section.category.display }}
          </div>
          <div
            (click)="openElements(section.type, section.category)"
            class="text-xs text-gray-700 hover:text-gray-900 select-none cursor-pointer"
          >
            See All
          </div>
        </div>
        <div class="rounded-md overflow-hidden">
          <confect-side-scroll #scroll>
            <div class="flex mx-1">
              @for (element of section.elements; track element.id) {
                <div
                  class="flex h-28 element-width rounded-md hover:bg-slate-100 cursor-pointer select-none"
                  (click)="selectElement(element)"
                >
                  @if (element.thumbnail != null) {
                    <img
                      class="w-full h-full object-contain object-center pointer-events-none"
                      [src]="element.thumbnail"
                    />
                  } @else {
                    <div class="w-28 h-28 flex items-center justify-center">
                      <div class="h-24 w-24 bg-gray-100 rounded-full"></div>
                    </div>
                  }
                </div>
              }
              @if (scroll.viewWidth() < scroll.contentWidth()) {
                <div
                  class="flex h-28 w-20 flex-shrink-0 items-center justify-center"
                  (click)="openElements(section.type, section.category)"
                >
                  <div
                    class="w-10 h-10 rounded-full bg-slate-100 flex items-center justify-center hover:bg-slate-200 group"
                  >
                    <confect-icon
                      icon="arrow_forward"
                      iconClass="text-2xl text-gray-700 group-hover:text-gray-900"
                    />
                  </div>
                </div>
              }
            </div>
          </confect-side-scroll>
        </div>
      </div>
    }
  }
</div>
<ng-template #sharedElements let-close="close" let-extra="extra">
  <ngx-shared-elements
    [category]="extra.category"
    (selectedElementEvent)="selectElement($event); close(true)"
  />
</ng-template>

<ng-template #privateElements let-close="close">
  <ngx-design-elements-overview
    [radioMode]="true"
    mode="submit"
    [header]="true"
    (selected)="selectElement($event); close(true)"
/></ng-template>
