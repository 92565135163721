<div class="sm:grid sm:grid-cols-8 sm:gap-4 sm:items-start sm:pb-3">
  <div class="sm:mt-px sm:col-span-6 flex items-center h-full flex-grow">
    <label class="block">
      <confect-icon
        [icon]="specItem.icon"
        iconClass="text-xl font-medium text-gray-700 mr-2"
      >
        <span class="text-sm">{{ specItem.display }}</span>
      </confect-icon>
    </label>
  </div>
  <div class="sm:col-span-2 h-full">
    <div class="flex items-center justify-end h-full">
      <input
        class="w-9 h-9 rounded-full ring-primary border border-primary z-[500]"
        [colorPicker]="currentColor"
        (colorPickerChange)="colorPickerChange($event)"
        [style.background]="currentColor"
        [cpOutputFormat]="'rgba'"
        [cpPresetColors]="presetColors"
        [cpPosition]="'auto'"
        [cpUseRootViewContainer]="true"
        [cpAlphaChannel]="'disabled'"
        [cpPresetLabel]="'Brand colors'"
        readonly
      />
    </div>
  </div>
</div>
