<ngx-creatives-previewer
  #previewer
  [delegate]="this"
  [previewerSettings]="{
    autoLoadInitial: true,
    qualityToggle: false,
    showShareButton: true,
    selectedFilters: selectedFilters,
    creativePreviewMode: _mode === 'image' ? 'default' : 'videoPreview',
    showQualityMessage: true,
  }"
  [resolution]="resolution"
  [cols]="cols"
  (closeWindow)="closeWindow.emit(true)"
/>

<confect-modal #shareLinkModal>
  <div style="width: 520px; max-width: 520px" class="p-6 rounded bg-white">
    <h2 class="mb-4 text-center">Share link</h2>
    <p class="text-base text-center">
      Allow others to see the output of this automation by sharing the link
      below.
    </p>
    <div class="my-10">
      <div
        style="max-width: 100%; overflow-x: auto"
        class="rounded-md bg-gray-100 p-2 m-2"
      >
        {{ currentPreviewUrl }}
      </div>
      <confect-button
        class="w-full"
        [block]="true"
        size="large"
        type="primary"
        (click)="copyText(currentPreviewUrl)"
      >
        <confect-icon
          icon="copy"
          iconClass="text-white-900 group-hover:text-gray-500 text-xl mr-3"
        />{{ copyButtonText }}</confect-button
      >
    </div>
    <div class="flex justify-center">
      <confect-button size="large" type="white" (click)="shareLinkModal.hide()"
        >Close</confect-button
      >
    </div>
  </div>
</confect-modal>
