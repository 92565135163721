export const LINEAR_GRADIENTS = [
  {
    steps: [0.0, 100.0],
    colors: [
      [242, 112, 156, 255],
      [255, 148, 114, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [182, 244, 146, 255],
      [51, 139, 147, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [156, 252, 248, 255],
      [110, 123, 251, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [161, 140, 209, 255],
      [251, 194, 235, 255],
    ],
  },
  {
    steps: [11.3, 100],
    colors: [
      [253, 199, 141, 255],
      [249, 143, 253, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [218, 185, 252, 255],
      [125, 89, 252, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [173, 83, 137, 255],
      [60, 16, 83, 255],
    ],
  },
  {
    steps: [0.0, 100.0, 100.0, 100.0],
    colors: [
      [12, 52, 131, 255],
      [162, 182, 223, 255],
      [107, 140, 206, 255],
      [162, 182, 223, 255],
    ],
  },
  {
    steps: [0.0, 50.0],
    colors: [
      [214, 76, 127, 255],
      [238, 71, 88, 255],
    ],
  },
  {
    steps: [0.0, 50.0, 100.0],
    colors: [
      [198, 255, 221, 255],
      [251, 215, 134, 255],
      [247, 121, 125, 255],
    ],
  },
  {
    steps: [0.0, 0.0, 21.0, 52.0, 78.0, 100.0],
    colors: [
      [255, 129, 119, 255],
      [255, 134, 122, 255],
      [255, 140, 127, 255],
      [249, 145, 133, 255],
      [207, 85, 108, 255],
      [177, 42, 91, 255],
    ],
  },
  {
    steps: [0.0, 50.0, 100.0],
    colors: [
      [58, 28, 113, 255],
      [215, 109, 119, 255],
      [255, 175, 123, 255],
    ],
  },
  {
    steps: [0, 71.5],
    colors: [
      [23, 205, 205, 255],
      [23, 25, 95, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [94, 252, 232, 255],
      [115, 110, 254, 255],
    ],
  },
  {
    steps: [],
    colors: [],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [82, 229, 231, 255],
      [19, 12, 183, 255],
    ],
  },
  {
    steps: [0.0, 50.0, 100.0],
    colors: [
      [18, 194, 233, 255],
      [196, 113, 237, 255],
      [246, 79, 89, 255],
    ],
  },
  {
    steps: [0, 91.6],
    colors: [
      [59, 158, 255, 255],
      [246, 135, 255, 255],
    ],
  },
  {
    steps: [11.1, 81.3],
    colors: [
      [255, 104, 192, 255],
      [104, 84, 249, 255],
    ],
  },
  {
    steps: [0.5, 49.0, 99.8],
    colors: [
      [248, 182, 204, 255],
      [192, 198, 230, 255],
      [225, 246, 240, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [255, 65, 108, 255],
      [255, 75, 43, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [255, 248, 134, 255],
      [240, 114, 182, 255],
    ],
  },
  {
    steps: [11.4, 84.5],
    colors: [
      [86, 238, 225, 255],
      [176, 255, 39, 255],
    ],
  },
  {
    steps: [16.9, 43.7, 66.9, 94.8],
    colors: [
      [211, 133, 176, 255],
      [249, 116, 146, 255],
      [255, 158, 136, 255],
      [254, 228, 172, 255],
    ],
  },
  {
    steps: [0.3, 88.7],
    colors: [
      [162, 247, 241, 255],
      [199, 146, 255, 255],
    ],
  },
  {
    steps: [2.9, 100],
    colors: [
      [242, 168, 168, 255],
      [94, 46, 165, 255],
    ],
  },
  {
    steps: [1.6, 51.2, 99.8],
    colors: [
      [197, 120, 234, 255],
      [120, 220, 234, 255],
      [119, 249, 176, 255],
    ],
  },
  {
    steps: [0.4, 100],
    colors: [
      [20, 100, 196, 255],
      [33, 152, 214, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [14, 11, 56, 255],
      [239, 37, 37, 255],
    ],
  },
  {
    steps: [3.6, 50.3, 97.4],
    colors: [
      [111, 71, 133, 255],
      [232, 129, 166, 255],
      [237, 237, 183, 255],
    ],
  },
  {
    steps: [27.4, 92.7],
    colors: [
      [2, 108, 223, 255],
      [0, 255, 255, 255],
    ],
  },
  {
    steps: [2.2, 96.2],
    colors: [
      [253, 240, 233, 255],
      [255, 194, 203, 255],
    ],
  },
  {
    steps: [12.9, 77.6, 100],
    colors: [
      [18, 38, 170, 255],
      [0, 187, 180, 255],
      [255, 255, 255, 255],
    ],
  },
  {
    steps: [0.4, 49.8, 98.6],
    colors: [
      [129, 252, 255, 255],
      [255, 175, 207, 255],
      [255, 208, 153, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [255, 168, 168, 255],
      [252, 255, 0, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [5, 117, 230, 255],
      [2, 27, 121, 255],
    ],
  },
  {
    steps: [19.8, 92.1],
    colors: [
      [0, 104, 155, 255],
      [0, 173, 239, 255],
    ],
  },
  {
    steps: [13.2, 29.8, 48.9, 68.2, 86.4],
    colors: [
      [29, 173, 235, 255],
      [137, 149, 250, 255],
      [229, 109, 212, 255],
      [255, 68, 128, 255],
      [255, 94, 0, 255],
    ],
  },
  {
    steps: [0, 44.4, 100],
    colors: [
      [57, 31, 105, 255],
      [115, 43, 155, 255],
      [231, 75, 184, 255],
    ],
  },
  {
    steps: [6.2, 100],
    colors: [
      [3, 79, 135, 255],
      [0, 184, 214, 255],
    ],
  },
  {
    steps: [],
    colors: [],
  },
  {
    steps: [21.9, 92.2],
    colors: [
      [228, 247, 255, 255],
      [255, 216, 194, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [185, 212, 242, 255],
      [244, 210, 226, 255],
    ],
  },
  {
    steps: [11.1, 100],
    colors: [
      [187, 232, 255, 255],
      [4, 78, 116, 255],
    ],
  },
  {
    steps: [9.3, 89.5],
    colors: [
      [254, 3, 104, 255],
      [103, 3, 255, 255],
    ],
  },
  {
    steps: [4.6, 26.0, 49.1, 86.5],
    colors: [
      [249, 206, 183, 255],
      [234, 174, 202, 255],
      [210, 182, 226, 255],
      [173, 209, 242, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [121, 241, 164, 255],
      [14, 92, 173, 255],
    ],
  },
  {
    steps: [0, 55.0, 100],
    colors: [
      [41, 196, 255, 255],
      [255, 158, 211, 255],
      [255, 182, 138, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [50, 25, 79, 255],
      [122, 101, 149, 255],
    ],
  },
  {
    steps: [],
    colors: [],
  },
  {
    steps: [1.8, 100],
    colors: [
      [252, 165, 241, 255],
      [181, 255, 255, 255],
    ],
  },
  {
    steps: [
      0.0, 7.1, 13.4, 18.0, 23.9, 28.8, 33.3, 38.0, 44.2, 52.4, 59.7, 67.3,
      74.4, 82.2, 88.2, 92.8, 98.4,
    ],
    colors: [
      [73, 235, 221, 255],
      [83, 222, 219, 255],
      [105, 191, 217, 255],
      [127, 157, 214, 255],
      [155, 113, 208, 255],
      [178, 73, 201, 255],
      [200, 45, 192, 255],
      [213, 42, 180, 255],
      [232, 44, 145, 255],
      [239, 45, 128, 255],
      [249, 66, 107, 255],
      [252, 105, 98, 255],
      [252, 105, 98, 255],
      [254, 145, 92, 255],
      [255, 189, 86, 255],
      [254, 227, 80, 255],
      [254, 248, 75, 255],
    ],
  },
  {
    steps: [12.1, 63.2],
    colors: [
      [151, 150, 240, 255],
      [255, 206, 236, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [233, 222, 250, 255],
      [251, 252, 219, 255],
    ],
  },
  {
    steps: [44.9, 94.5],
    colors: [
      [176, 174, 225, 255],
      [135, 197, 235, 255],
    ],
  },
  {
    steps: [2.4, 28.3, 46.2, 79.4, 100],
    colors: [
      [251, 198, 6, 255],
      [224, 82, 95, 255],
      [194, 78, 154, 255],
      [32, 173, 190, 255],
      [22, 158, 95, 255],
    ],
  },
  {
    steps: [5.5, 90.2],
    colors: [
      [221, 221, 221, 255],
      [110, 136, 161, 255],
    ],
  },
  {
    steps: [9.3, 75.1],
    colors: [
      [175, 104, 254, 255],
      [101, 223, 255, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [137, 191, 221, 255],
      [150, 144, 204, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [9, 32, 63, 255],
      [83, 120, 149, 255],
    ],
  },
  {
    steps: [11.2, 47.3, 100],
    colors: [
      [238, 164, 179, 255],
      [212, 153, 234, 255],
      [150, 121, 255, 255],
    ],
  },
  {
    steps: [0.0, 100.0, 100],
    colors: [
      [102, 64, 123, 255],
      [252, 41, 119, 255],
      [251, 168, 214, 255],
    ],
  },
  {
    steps: [0.3, 55.8, 100],
    colors: [
      [172, 30, 255, 255],
      [65, 35, 251, 255],
      [35, 251, 224, 255],
    ],
  },
  {
    steps: [0.3, 100],
    colors: [
      [221, 49, 49, 255],
      [0, 8, 119, 255],
    ],
  },
  {
    steps: [9.4, 78.4],
    colors: [
      [13, 11, 136, 255],
      [86, 255, 248, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [252, 92, 125, 255],
      [106, 130, 251, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [252, 74, 26, 255],
      [247, 183, 51, 255],
    ],
  },
  {
    steps: [0.3, 18.2, 36.4, 52.5, 68.8, 99.9],
    colors: [
      [35, 210, 255, 255],
      [74, 104, 247, 255],
      [133, 80, 255, 255],
      [198, 59, 243, 255],
      [250, 84, 118, 255],
      [255, 223, 67, 255],
    ],
  },
  {
    steps: [8.1, 91.2],
    colors: [
      [224, 235, 213, 255],
      [37, 148, 141, 255],
    ],
  },
  {
    steps: [30.1, 100],
    colors: [
      [245, 239, 249, 255],
      [207, 211, 236, 255],
    ],
  },
  {
    steps: [0.0, 50.0, 100.0],
    colors: [
      [84, 51, 255, 255],
      [32, 189, 255, 255],
      [165, 254, 203, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [101, 78, 163, 255],
      [234, 175, 200, 255],
    ],
  },
  {
    steps: [6.3, 90.6],
    colors: [
      [9, 154, 151, 255],
      [21, 205, 168, 255],
    ],
  },
  {
    steps: [8.3, 41.6, 93.4],
    colors: [
      [34, 34, 34, 255],
      [0, 40, 60, 255],
      [0, 143, 213, 255],
    ],
  },
  {
    steps: [15.2, 99.3],
    colors: [
      [202, 73, 118, 255],
      [255, 84, 84, 255],
    ],
  },
  {
    steps: [34.9, 82.5],
    colors: [
      [252, 255, 26, 255],
      [66, 240, 233, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [255, 194, 48, 255],
      [255, 124, 0, 255],
    ],
  },
  {
    steps: [2.3, 100],
    colors: [
      [133, 255, 159, 255],
      [0, 172, 249, 255],
    ],
  },
  {
    steps: [8.1, 79.5],
    colors: [
      [13, 198, 180, 255],
      [33, 198, 138, 255],
    ],
  },
  {
    steps: [12.4, 78.7],
    colors: [
      [246, 220, 111, 255],
      [222, 104, 104, 255],
    ],
  },
  {
    steps: [0, 47.7, 100],
    colors: [
      [7, 3, 98, 255],
      [179, 26, 132, 255],
      [239, 186, 83, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [151, 150, 240, 255],
      [251, 199, 212, 255],
    ],
  },
  {
    steps: [14.0, 80.5, 100],
    colors: [
      [254, 140, 48, 255],
      [253, 75, 101, 255],
      [255, 53, 109, 255],
    ],
  },
  {
    steps: [6.3, 98.9],
    colors: [
      [75, 207, 250, 255],
      [25, 159, 249, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [220, 196, 252, 255],
      [150, 199, 253, 255],
    ],
  },
  {
    steps: [0, 46.4, 86.4],
    colors: [
      [245, 167, 191, 255],
      [201, 218, 238, 255],
      [164, 240, 229, 255],
    ],
  },
  {
    steps: [2.8, 97.8],
    colors: [
      [0, 32, 95, 255],
      [132, 53, 142, 255],
    ],
  },
  {
    steps: [],
    colors: [],
  },
  {
    steps: [11.2, 59.3, 92.7],
    colors: [
      [80, 154, 175, 255],
      [125, 216, 199, 255],
      [245, 255, 195, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [80, 35, 217, 255],
      [217, 35, 189, 255],
    ],
  },
  {
    steps: [10.4, 56.2, 86.1],
    colors: [
      [163, 217, 221, 255],
      [174, 250, 182, 255],
      [238, 243, 220, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [170, 255, 169, 255],
      [17, 255, 189, 255],
    ],
  },
  {
    steps: [31.8, 56.2, 89.0],
    colors: [
      [0, 55, 79, 255],
      [9, 93, 134, 255],
      [15, 155, 217, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [0, 37, 84, 255],
      [0, 37, 84, 81],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [90, 209, 243, 255],
      [254, 135, 245, 255],
    ],
  },
  {
    steps: [0, 55.2, 84.4],
    colors: [
      [28, 28, 224, 255],
      [153, 68, 230, 255],
      [235, 18, 180, 255],
    ],
  },
  {
    steps: [13.0, 98.0],
    colors: [
      [0, 215, 206, 255],
      [0, 132, 255, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [120, 143, 251, 255],
      [133, 235, 255, 255],
    ],
  },
  {
    steps: [0, 22.8, 50.3, 75.5, 100],
    colors: [
      [252, 185, 125, 255],
      [245, 231, 106, 255],
      [160, 236, 208, 255],
      [57, 178, 209, 255],
      [183, 174, 193, 255],
    ],
  },
  {
    steps: [25.0, 38.1, 55.5, 67.3, 85.2, 100],
    colors: [
      [253, 183, 220, 255],
      [240, 213, 190, 255],
      [249, 240, 206, 255],
      [253, 244, 210, 255],
      [222, 248, 226, 255],
      [200, 247, 242, 255],
    ],
  },
  {
    steps: [0.2, 27.3, 85.5],
    colors: [
      [247, 204, 0, 255],
      [247, 0, 0, 255],
      [161, 0, 156, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [193, 98, 253, 255],
      [0, 49, 165, 255],
    ],
  },
  {
    steps: [0.4, 100],
    colors: [
      [217, 111, 111, 255],
      [34, 14, 215, 255],
    ],
  },
  {
    steps: [0.9, 88.6],
    colors: [
      [30, 161, 239, 255],
      [49, 46, 252, 255],
    ],
  },
  {
    steps: [0.4, 100],
    colors: [
      [246, 4, 26, 255],
      [251, 139, 34, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [247, 182, 182, 255],
      [173, 94, 244, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [245, 203, 255, 255],
      [195, 70, 194, 255],
    ],
  },
  {
    steps: [10.5, 74.4],
    colors: [
      [80, 255, 207, 255],
      [22, 185, 255, 255],
    ],
  },
  {
    steps: [3.8, 20.8, 51.9, 73.1, 94.1],
    colors: [
      [15, 209, 165, 255],
      [15, 157, 209, 255],
      [133, 13, 230, 255],
      [230, 13, 202, 255],
      [242, 180, 107, 255],
    ],
  },
  {
    steps: [0, 10.2, 34.3, 54.0, 62.5, 77.2, 91.2, 100, 100],
    colors: [
      [0, 98, 186, 255],
      [88, 40, 178, 255],
      [234, 39, 119, 255],
      [255, 80, 152, 255],
      [255, 101, 74, 255],
      [254, 166, 34, 255],
      [255, 227, 99, 255],
      [58, 243, 156, 255],
      [58, 243, 156, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [74, 77, 103, 255],
      [119, 125, 165, 255],
    ],
  },
  {
    steps: [7.6, 99.8],
    colors: [
      [132, 222, 255, 255],
      [8, 30, 64, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [221, 214, 243, 255],
      [250, 172, 168, 255],
    ],
  },
  {
    steps: [0, 21.1, 46.0, 64.2, 81.0, 97.2],
    colors: [
      [2, 250, 70, 255],
      [2, 238, 250, 255],
      [174, 8, 237, 255],
      [237, 8, 117, 255],
      [237, 8, 117, 255],
      [248, 136, 0, 255],
    ],
  },
  {
    steps: [0, 97.4],
    colors: [
      [251, 0, 0, 255],
      [3, 10, 252, 255],
    ],
  },
  {
    steps: [19.9, 95.0],
    colors: [
      [165, 41, 185, 255],
      [80, 177, 225, 255],
    ],
  },
  {
    steps: [6.3, 61.7, 90.6],
    colors: [
      [255, 237, 50, 255],
      [255, 102, 114, 255],
      [145, 75, 143, 255],
    ],
  },
  {
    steps: [17.4, 52.4, 91.0],
    colors: [
      [128, 144, 233, 255],
      [171, 88, 238, 255],
      [255, 129, 246, 255],
    ],
  },
  {
    steps: [44.2, 95.6],
    colors: [
      [61, 237, 253, 255],
      [3, 136, 238, 255],
    ],
  },
  {
    steps: [7.2, 57.5, 90.7],
    colors: [
      [235, 225, 188, 255],
      [232, 188, 234, 255],
      [203, 209, 244, 255],
    ],
  },
  {
    steps: [3.2, 97.6],
    colors: [
      [76, 79, 106, 255],
      [118, 124, 163, 255],
    ],
  },
  {
    steps: [2.6, 97.9],
    colors: [
      [255, 90, 109, 255],
      [119, 76, 231, 255],
    ],
  },
  {
    steps: [0, 53.8, 100],
    colors: [
      [183, 248, 193, 255],
      [169, 212, 250, 255],
      [148, 231, 255, 255],
    ],
  },
  {
    steps: [3.8, 91.1],
    colors: [
      [83, 200, 244, 255],
      [162, 79, 206, 255],
    ],
  },
  {
    steps: [0, 99.0],
    colors: [
      [66, 255, 132, 255],
      [124, 216, 255, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [148, 233, 194, 255],
      [224, 235, 186, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [84, 13, 110, 255],
      [238, 66, 102, 255],
    ],
  },
  {
    steps: [100],
    colors: [[97, 176, 242, 255]],
  },
  {
    steps: [24.0, 92.4],
    colors: [
      [253, 70, 90, 255],
      [31, 42, 147, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [69, 179, 224, 255],
      [102, 51, 153, 255],
    ],
  },
  {
    steps: [8.5, 100],
    colors: [
      [0, 128, 128, 255],
      [174, 206, 100, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [253, 223, 168, 255],
      [246, 165, 254, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [250, 112, 154, 255],
      [254, 225, 64, 255],
    ],
  },
  {
    steps: [23.2, 77.0],
    colors: [
      [248, 205, 205, 255],
      [149, 170, 211, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [52, 102, 174, 255],
      [83, 144, 241, 255],
    ],
  },
  {
    steps: [19.0, 91.7],
    colors: [
      [92, 30, 249, 255],
      [14, 198, 183, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [187, 0, 212, 255],
      [32, 38, 238, 255],
    ],
  },
  {
    steps: [7.1, 14.7, 28.0, 43.4, 60.2, 76.8],
    colors: [
      [109, 118, 150, 255],
      [89, 72, 79, 255],
      [69, 92, 79, 255],
      [204, 85, 67, 255],
      [237, 181, 121, 255],
      [219, 230, 175, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [238, 156, 167, 255],
      [255, 221, 225, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [27, 27, 79, 255],
      [120, 201, 244, 255],
    ],
  },
  {
    steps: [4.0, 49.3, 96.0],
    colors: [
      [30, 59, 94, 255],
      [253, 29, 62, 255],
      [255, 224, 94, 255],
    ],
  },
  {
    steps: [14.6, 100],
    colors: [
      [42, 132, 201, 255],
      [47, 249, 233, 255],
    ],
  },
  {
    steps: [22.3, 56.6, 90.9],
    colors: [
      [234, 234, 234, 255],
      [201, 234, 211, 255],
      [255, 180, 189, 255],
    ],
  },
  {
    steps: [11.3, 91.1],
    colors: [
      [61, 121, 176, 255],
      [35, 66, 164, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [52, 232, 158, 255],
      [15, 52, 67, 255],
    ],
  },
  {
    steps: [3.6, 100],
    colors: [
      [58, 62, 88, 255],
      [119, 127, 148, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [102, 51, 153, 255],
      [69, 179, 224, 255],
    ],
  },
  {
    steps: [6.4, 100],
    colors: [
      [247, 112, 15, 255],
      [248, 50, 88, 255],
    ],
  },
  {
    steps: [0, 17.9, 81.6],
    colors: [
      [252, 154, 154, 255],
      [190, 70, 102, 234],
      [58, 13, 48, 255],
    ],
  },
  {
    steps: [10.5, 93.7],
    colors: [
      [235, 62, 62, 255],
      [101, 46, 229, 255],
    ],
  },
  {
    steps: [38.1, 93.1],
    colors: [
      [57, 67, 248, 255],
      [192, 0, 255, 255],
    ],
  },
  {
    steps: [1.9, 49.7, 100],
    colors: [
      [255, 241, 165, 255],
      [200, 125, 76, 255],
      [83, 54, 54, 255],
    ],
  },
  {
    steps: [6.8, 100],
    colors: [
      [30, 33, 48, 255],
      [74, 98, 110, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [209, 158, 246, 255],
      [93, 219, 241, 255],
    ],
  },
  {
    steps: [0.0, 99.8],
    colors: [
      [254, 99, 132, 255],
      [49, 52, 56, 255],
    ],
  },
  {
    steps: [0.3, 87.8],
    colors: [
      [3, 195, 195, 255],
      [37, 84, 112, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [183, 248, 219, 255],
      [80, 167, 194, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [247, 108, 243, 255],
      [173, 64, 254, 255],
    ],
  },
  {
    steps: [20.5, 100],
    colors: [
      [243, 0, 79, 255],
      [255, 236, 68, 255],
    ],
  },
  {
    steps: [3.0, 93.9],
    colors: [
      [12, 166, 180, 255],
      [100, 51, 161, 255],
    ],
  },
  {
    steps: [6.3, 90.9],
    colors: [
      [23, 41, 77, 255],
      [243, 113, 154, 255],
    ],
  },
  {
    steps: [11.2, 91.7],
    colors: [
      [0, 204, 130, 255],
      [58, 181, 46, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [9, 154, 151, 255],
      [21, 205, 168, 255],
    ],
  },
  {
    steps: [11.3, 100],
    colors: [
      [215, 109, 119, 255],
      [255, 175, 123, 255],
    ],
  },
  {
    steps: [0, 14.1, 34.7, 53.0, 74.7, 95.6],
    colors: [
      [0, 186, 217, 255],
      [121, 134, 204, 255],
      [150, 117, 206, 255],
      [186, 105, 200, 255],
      [240, 71, 112, 255],
      [239, 162, 92, 255],
    ],
  },
  {
    steps: [8.0, 55.4, 91.1],
    colors: [
      [235, 217, 221, 255],
      [216, 174, 211, 255],
      [145, 130, 196, 255],
    ],
  },
  {
    steps: [0, 76.7],
    colors: [
      [120, 85, 137, 255],
      [35, 9, 31, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [0, 204, 255, 255],
      [0, 221, 61, 255],
    ],
  },
  {
    steps: [11.2, 54.5, 99.6],
    colors: [
      [240, 228, 122, 255],
      [253, 145, 212, 255],
      [176, 222, 234, 255],
    ],
  },
  {
    steps: [6.5, 97.1],
    colors: [
      [249, 174, 240, 255],
      [139, 144, 250, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [135, 168, 254, 255],
      [254, 165, 197, 255],
    ],
  },
  {
    steps: [2.9, 97.1],
    colors: [
      [197, 214, 227, 255],
      [144, 175, 202, 255],
    ],
  },
  {
    steps: [3.2, 90.3],
    colors: [
      [251, 140, 0, 255],
      [98, 0, 234, 255],
    ],
  },
  {
    steps: [13.4, 100],
    colors: [
      [43, 1, 91, 255],
      [122, 2, 54, 255],
    ],
  },
  {
    steps: [10.9, 63.8],
    colors: [
      [175, 237, 255, 255],
      [255, 163, 252, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [221, 62, 84, 255],
      [156, 76, 186, 255],
    ],
  },
  {
    steps: [3.2, 89.1],
    colors: [
      [101, 132, 154, 255],
      [160, 197, 201, 255],
    ],
  },
  {
    steps: [15.2, 98.5],
    colors: [
      [251, 0, 83, 255],
      [179, 146, 231, 255],
    ],
  },
  {
    steps: [1.5, 100],
    colors: [
      [135, 174, 220, 255],
      [255, 255, 255, 255],
    ],
  },
  {
    steps: [31.2, 90.9],
    colors: [
      [146, 221, 219, 255],
      [236, 167, 219, 255],
    ],
  },
  {
    steps: [0, 92.2],
    colors: [
      [247, 0, 0, 255],
      [164, 50, 237, 255],
    ],
  },
  {
    steps: [0, 37.7, 100],
    colors: [
      [254, 255, 194, 255],
      [255, 210, 165, 255],
      [211, 140, 173, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [180, 214, 242, 255],
      [72, 67, 221, 183],
    ],
  },
  {
    steps: [36.0, 82.4],
    colors: [
      [251, 203, 159, 255],
      [244, 137, 233, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [161, 140, 209, 255],
      [251, 194, 235, 255],
    ],
  },
  {
    steps: [3.3, 100],
    colors: [
      [232, 245, 253, 255],
      [252, 253, 255, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [255, 230, 109, 255],
      [87, 232, 107, 255],
    ],
  },
  {
    steps: [35.7, 100],
    colors: [
      [247, 164, 216, 255],
      [115, 102, 252, 255],
    ],
  },
  {
    steps: [22.9, 69.0],
    colors: [
      [41, 116, 250, 255],
      [67, 212, 255, 255],
    ],
  },
  {
    steps: [23.9, 86.5],
    colors: [
      [216, 176, 216, 255],
      [53, 169, 215, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [217, 250, 158, 255],
      [128, 251, 174, 255],
    ],
  },
  {
    steps: [5.0, 28.2, 62.5, 89.1],
    colors: [
      [139, 98, 208, 255],
      [126, 124, 223, 255],
      [109, 207, 236, 255],
      [176, 239, 244, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [255, 235, 104, 255],
      [61, 247, 255, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [154, 227, 226, 255],
      [105, 210, 216, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [20, 30, 48, 255],
      [36, 59, 85, 255],
    ],
  },
  {
    steps: [7.4, 100],
    colors: [
      [67, 202, 177, 255],
      [255, 255, 255, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [248, 205, 205, 255],
      [149, 170, 211, 255],
    ],
  },
  {
    steps: [2.6, 100],
    colors: [
      [159, 131, 216, 255],
      [27, 245, 241, 255],
    ],
  },
  {
    steps: [11.0, 83.4],
    colors: [
      [195, 67, 129, 255],
      [90, 43, 186, 255],
    ],
  },
  {
    steps: [0.2, 68.5],
    colors: [
      [19, 126, 57, 255],
      [8, 65, 91, 255],
    ],
  },
  {
    steps: [5.7, 92.9],
    colors: [
      [243, 31, 105, 255],
      [249, 233, 47, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [151, 213, 255, 255],
      [255, 155, 185, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [116, 255, 217, 255],
      [88, 200, 223, 255],
    ],
  },
  {
    steps: [3.3, 97.8],
    colors: [
      [5, 120, 190, 255],
      [119, 217, 211, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [6, 183, 249, 255],
      [25, 74, 236, 255],
    ],
  },
  {
    steps: [5.3, 100],
    colors: [
      [99, 192, 227, 255],
      [98, 72, 194, 255],
    ],
  },
  {
    steps: [11.2, 91.2],
    colors: [
      [251, 186, 186, 255],
      [200, 122, 245, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [100, 178, 249, 255],
      [114, 231, 159, 204],
    ],
  },
  {
    steps: [11.2, 51.2, 98.6],
    colors: [
      [36, 45, 57, 255],
      [16, 37, 60, 255],
      [0, 0, 0, 255],
    ],
  },
  {
    steps: [2.8, 97.8],
    colors: [
      [25, 49, 108, 255],
      [1, 179, 201, 255],
    ],
  },
  {
    steps: [2.3, 97.6],
    colors: [
      [40, 48, 68, 255],
      [1, 179, 201, 255],
    ],
  },
  {
    steps: [0, 44.9, 96.1],
    colors: [
      [247, 238, 238, 255],
      [247, 202, 201, 255],
      [145, 168, 208, 255],
    ],
  },
  {
    steps: [12.6, 91.1],
    colors: [
      [243, 118, 118, 255],
      [191, 159, 237, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [72, 203, 217, 255],
      [135, 218, 149, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [255, 246, 183, 255],
      [246, 65, 108, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [249, 119, 148, 255],
      [98, 58, 162, 255],
    ],
  },
  {
    steps: [11.2, 90.4],
    colors: [
      [235, 173, 254, 255],
      [254, 150, 150, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [247, 97, 161, 255],
      [140, 27, 171, 255],
    ],
  },
  {
    steps: [5.0, 95.8],
    colors: [
      [116, 253, 210, 255],
      [0, 191, 247, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [151, 171, 255, 255],
      [18, 53, 151, 255],
    ],
  },
  {
    steps: [20.5, 100],
    colors: [
      [89, 233, 162, 255],
      [29, 209, 185, 255],
    ],
  },
  {
    steps: [0, 16.4, 68.2, 99.1],
    colors: [
      [156, 116, 129, 255],
      [67, 54, 74, 255],
      [47, 48, 67, 255],
      [27, 23, 36, 255],
    ],
  },
  {
    steps: [11.7, 81.7],
    colors: [
      [252, 56, 56, 255],
      [237, 13, 81, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [255, 95, 109, 255],
      [255, 195, 113, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [178, 69, 146, 255],
      [241, 95, 121, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [248, 87, 166, 255],
      [255, 88, 88, 255],
    ],
  },
  {
    steps: [0.0, 50.0, 100.0],
    colors: [
      [0, 82, 212, 255],
      [67, 100, 247, 255],
      [111, 177, 252, 255],
    ],
  },
  {
    steps: [48.8, 98.7],
    colors: [
      [249, 100, 100, 255],
      [146, 166, 250, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [255, 8, 68, 255],
      [255, 177, 153, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [243, 67, 67, 255],
      [2, 38, 208, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [207, 150, 207, 255],
      [107, 116, 179, 255],
    ],
  },
  {
    steps: [30.2, 61.4],
    colors: [
      [157, 147, 247, 255],
      [117, 176, 247, 255],
    ],
  },
  {
    steps: [11.2, 47.5, 100],
    colors: [
      [251, 250, 225, 255],
      [206, 240, 185, 255],
      [100, 163, 111, 255],
    ],
  },
  {
    steps: [27.1, 77.8],
    colors: [
      [30, 144, 231, 255],
      [67, 101, 225, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [3, 195, 187, 255],
      [180, 196, 255, 255],
    ],
  },
  {
    steps: [11.3, 69.9],
    colors: [
      [25, 170, 209, 255],
      [21, 65, 249, 255],
    ],
  },
  {
    steps: [4.3, 100],
    colors: [
      [39, 115, 255, 255],
      [62, 211, 232, 255],
    ],
  },
  {
    steps: [7.4, 51.4, 82.6, 100],
    colors: [
      [209, 231, 235, 255],
      [238, 219, 199, 255],
      [255, 159, 122, 255],
      [255, 109, 58, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [56, 24, 40, 255],
      [23, 148, 185, 255],
    ],
  },
  {
    steps: [14.2, 84.0],
    colors: [
      [0, 158, 218, 255],
      [5, 104, 174, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [55, 168, 192, 255],
      [103, 187, 125, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [206, 159, 252, 255],
      [115, 103, 240, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [133, 253, 174, 255],
      [114, 173, 255, 255],
    ],
  },
  {
    steps: [22.3, 59.1, 89.7],
    colors: [
      [217, 115, 115, 255],
      [234, 245, 165, 255],
      [165, 245, 242, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [128, 0, 128, 255],
      [255, 192, 203, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [255, 153, 102, 255],
      [255, 94, 98, 255],
    ],
  },
  {
    steps: [11.2, 91.2],
    colors: [
      [229, 68, 121, 255],
      [157, 55, 206, 255],
    ],
  },
  {
    steps: [0, 71.6],
    colors: [
      [10, 34, 231, 255],
      [223, 9, 142, 255],
    ],
  },
  {
    steps: [0, 85.0],
    colors: [
      [33, 72, 89, 255],
      [130, 216, 229, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [17, 94, 199, 255],
      [63, 241, 152, 201],
    ],
  },
  {
    steps: [0.6, 90.0],
    colors: [
      [248, 52, 246, 255],
      [152, 38, 252, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [254, 102, 125, 255],
      [255, 163, 117, 255],
    ],
  },
  {
    steps: [76.4, 100],
    colors: [
      [252, 224, 115, 255],
      [255, 255, 255, 76],
    ],
  },
  {
    steps: [3.4, 23.5, 45.0, 65.3, 87.2],
    colors: [
      [249, 244, 233, 255],
      [243, 220, 193, 255],
      [224, 171, 165, 255],
      [161, 127, 161, 255],
      [115, 106, 152, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [78, 62, 255, 255],
      [164, 69, 255, 255],
    ],
  },
  {
    steps: [10.9, 27.8, 100],
    colors: [
      [253, 243, 243, 255],
      [248, 231, 231, 255],
      [160, 112, 161, 255],
    ],
  },
  {
    steps: [11.2, 65.0, 100],
    colors: [
      [78, 112, 157, 255],
      [137, 164, 199, 255],
      [205, 213, 224, 255],
    ],
  },
  {
    steps: [12.4, 62.4],
    colors: [
      [36, 242, 170, 255],
      [18, 245, 71, 160],
    ],
  },
  {
    steps: [0.0, 48.0, 100.0],
    colors: [
      [255, 226, 159, 255],
      [255, 169, 159, 255],
      [255, 113, 154, 255],
    ],
  },
  {
    steps: [2.0, 98.3],
    colors: [
      [75, 68, 229, 255],
      [97, 212, 202, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [51, 236, 236, 255],
      [24, 39, 231, 255],
    ],
  },
  {
    steps: [11.2, 74.6],
    colors: [
      [24, 175, 231, 255],
      [67, 82, 186, 255],
    ],
  },
  {
    steps: [19.9, 90.7],
    colors: [
      [253, 140, 117, 255],
      [225, 252, 117, 255],
    ],
  },
  {
    steps: [2.7, 97.2],
    colors: [
      [1, 179, 201, 255],
      [255, 199, 89, 255],
    ],
  },
  {
    steps: [2.2, 100],
    colors: [
      [184, 142, 252, 255],
      [104, 119, 244, 255],
    ],
  },
  {
    steps: [11.2, 92.2],
    colors: [
      [255, 221, 225, 255],
      [255, 255, 255, 255],
    ],
  },
  {
    steps: [6.9, 73.2],
    colors: [
      [139, 10, 130, 255],
      [73, 6, 70, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [246, 206, 236, 255],
      [217, 57, 205, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [255, 78, 78, 255],
      [253, 176, 71, 255],
    ],
  },
  {
    steps: [1.5, 17.9, 57.8, 89.9],
    colors: [
      [236, 62, 98, 255],
      [235, 108, 108, 255],
      [241, 163, 163, 255],
      [254, 217, 217, 255],
    ],
  },
  {
    steps: [11.2, 84.1],
    colors: [
      [252, 198, 41, 255],
      [209, 5, 5, 255],
    ],
  },
  {
    steps: [11.2, 75.1],
    colors: [
      [243, 136, 136, 255],
      [196, 157, 247, 255],
    ],
  },
  {
    steps: [27.1, 77.5],
    colors: [
      [26, 26, 29, 255],
      [111, 34, 50, 255],
    ],
  },
  {
    steps: [24.4, 53.7, 99.2],
    colors: [
      [251, 250, 205, 255],
      [247, 163, 205, 255],
      [141, 66, 243, 255],
    ],
  },
  {
    steps: [16.8, 100],
    colors: [
      [252, 190, 224, 255],
      [212, 254, 218, 255],
    ],
  },
  {
    steps: [7.7, 90.8],
    colors: [
      [253, 109, 104, 255],
      [248, 150, 105, 255],
    ],
  },
  {
    steps: [4.7, 22.9, 43.2, 63.9, 85.1, 100],
    colors: [
      [149, 0, 199, 255],
      [130, 0, 226, 255],
      [95, 0, 199, 255],
      [70, 0, 199, 255],
      [5, 0, 199, 255],
      [0, 95, 199, 255],
    ],
  },
  {
    steps: [1.3, 99.5],
    colors: [
      [149, 20, 255, 255],
      [225, 5, 219, 255],
    ],
  },
  {
    steps: [18.4, 100],
    colors: [
      [2, 255, 4, 255],
      [0, 232, 237, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [222, 95, 95, 255],
      [53, 88, 218, 255],
    ],
  },
  {
    steps: [0, 35.3, 67.2, 92.3],
    colors: [
      [248, 126, 126, 255],
      [251, 206, 143, 255],
      [184, 252, 233, 255],
      [118, 162, 229, 255],
    ],
  },
  {
    steps: [11.2, 91.3],
    colors: [
      [72, 200, 160, 255],
      [32, 40, 48, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [27, 255, 123, 255],
      [71, 83, 255, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [170, 0, 0, 255],
      [128, 0, 0, 255],
    ],
  },
  {
    steps: [2.3, 98.3],
    colors: [
      [43, 170, 96, 255],
      [129, 204, 104, 255],
    ],
  },
  {
    steps: [31.8, 69.1],
    colors: [
      [255, 236, 210, 255],
      [252, 182, 159, 255],
    ],
  },
  {
    steps: [15.4, 100],
    colors: [
      [174, 33, 255, 255],
      [255, 33, 33, 255],
    ],
  },
  {
    steps: [23.2, 100],
    colors: [
      [0, 238, 179, 255],
      [49, 100, 255, 255],
    ],
  },
  {
    steps: [5.8, 91.1],
    colors: [
      [255, 212, 4, 255],
      [255, 104, 8, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [23, 185, 230, 255],
      [23, 230, 126, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [249, 79, 146, 255],
      [253, 114, 114, 255],
    ],
  },
  {
    steps: [11.0, 48.6, 99.6],
    colors: [
      [240, 244, 247, 255],
      [22, 231, 236, 255],
      [29, 68, 124, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [11, 133, 234, 255],
      [34, 63, 201, 255],
    ],
  },
  {
    steps: [2.6, 99.1],
    colors: [
      [0, 29, 38, 255],
      [0, 191, 255, 255],
    ],
  },
  {
    steps: [],
    colors: [],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [239, 104, 207, 255],
      [255, 118, 118, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [224, 234, 252, 255],
      [207, 222, 243, 255],
    ],
  },
  {
    steps: [10.8, 57.9, 100],
    colors: [
      [245, 215, 161, 255],
      [240, 162, 142, 255],
      [186, 99, 117, 255],
    ],
  },
  {
    steps: [1.9, 39.3, 64.7, 100],
    colors: [
      [255, 253, 218, 255],
      [246, 210, 255, 255],
      [152, 222, 254, 255],
      [251, 255, 210, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [76, 161, 175, 255],
      [196, 224, 229, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [255, 78, 80, 255],
      [249, 212, 35, 255],
    ],
  },
  {
    steps: [19.1, 100],
    colors: [
      [73, 168, 255, 255],
      [0, 48, 249, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [255, 244, 27, 255],
      [169, 17, 146, 255],
    ],
  },
  {
    steps: [0.0, 48.0, 100.0],
    colors: [
      [82, 113, 196, 255],
      [177, 159, 255, 255],
      [236, 161, 254, 255],
    ],
  },
  {
    steps: [42.8, 83.3],
    colors: [
      [238, 9, 121, 255],
      [255, 106, 0, 255],
    ],
  },
  {
    steps: [34.2, 100],
    colors: [
      [241, 81, 104, 255],
      [196, 12, 183, 130],
    ],
  },
  {
    steps: [11.2, 91.2],
    colors: [
      [255, 251, 213, 255],
      [178, 10, 44, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [144, 247, 236, 255],
      [50, 204, 188, 255],
    ],
  },
  {
    steps: [10.0, 100.0],
    colors: [
      [252, 207, 49, 255],
      [245, 85, 85, 255],
    ],
  },
  {
    steps: [4.0, 49.0, 90.4],
    colors: [
      [234, 184, 163, 255],
      [247, 153, 130, 255],
      [255, 126, 119, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [164, 250, 245, 255],
      [4, 67, 163, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [196, 248, 180, 255],
      [160, 250, 254, 255],
    ],
  },
  {
    steps: [88.9],
    colors: [[192, 14, 47, 255]],
  },
  {
    steps: [0.7, 88.4],
    colors: [
      [55, 64, 77, 255],
      [66, 156, 193, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [255, 22, 229, 255],
      [254, 80, 119, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [188, 78, 156, 255],
      [248, 7, 89, 255],
    ],
  },
  {
    steps: [0.2, 93.3],
    colors: [
      [158, 143, 254, 255],
      [113, 202, 255, 255],
    ],
  },
  {
    steps: [33.7, 93.7],
    colors: [
      [51, 85, 255, 255],
      [0, 136, 255, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [244, 196, 243, 255],
      [252, 103, 250, 255],
    ],
  },
  {
    steps: [11.2, 89.0],
    colors: [
      [253, 145, 232, 255],
      [250, 42, 137, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [0, 212, 231, 255],
      [68, 139, 222, 255],
    ],
  },
  {
    steps: [11.2, 70.2],
    colors: [
      [61, 106, 239, 255],
      [122, 14, 190, 255],
    ],
  },
  {
    steps: [11.2, 100],
    colors: [
      [83, 231, 173, 255],
      [67, 209, 222, 255],
    ],
  },
  {
    steps: [30.0, 99.9],
    colors: [
      [7, 200, 129, 255],
      [7, 200, 129, 130],
    ],
  },
  {
    steps: [21.9, 92.2],
    colors: [
      [1, 22, 56, 255],
      [52, 33, 158, 255],
    ],
  },
  {
    steps: [21.1, 80.4],
    colors: [
      [255, 185, 0, 255],
      [255, 237, 0, 255],
    ],
  },
  {
    steps: [0.9, 95.5],
    colors: [
      [21, 21, 212, 255],
      [53, 220, 243, 255],
    ],
  },
  {
    steps: [3.3, 98.4],
    colors: [
      [253, 44, 56, 255],
      [176, 2, 12, 255],
    ],
  },
  {
    steps: [100],
    colors: [[0, 0, 0, 255]],
  },
  {
    steps: [0.0, 99.0, 100.0],
    colors: [
      [255, 154, 158, 255],
      [250, 208, 196, 255],
      [250, 208, 196, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [251, 194, 235, 255],
      [166, 193, 238, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [245, 28, 28, 255],
      [254, 134, 5, 255],
    ],
  },
  {
    steps: [32.4, 98.8],
    colors: [
      [6, 2, 2, 255],
      [137, 30, 47, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [218, 68, 83, 255],
      [137, 33, 107, 255],
    ],
  },
  {
    steps: [7.0, 77.4],
    colors: [
      [95, 182, 248, 255],
      [2, 89, 182, 255],
    ],
  },
  {
    steps: [27.9, 84.2],
    colors: [
      [0, 175, 255, 255],
      [0, 224, 254, 255],
    ],
  },
  {
    steps: [13.2, 81.3],
    colors: [
      [255, 100, 241, 255],
      [67, 220, 255, 255],
    ],
  },
  {
    steps: [0.7, 26.1, 95.7],
    colors: [
      [77, 238, 253, 255],
      [3, 151, 232, 255],
      [47, 31, 116, 255],
    ],
  },
  {
    steps: [],
    colors: [],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [240, 162, 142, 255],
      [186, 100, 118, 255],
    ],
  },
  {
    steps: [11.2, 53.5, 100],
    colors: [
      [252, 170, 250, 255],
      [255, 134, 169, 255],
      [234, 68, 68, 255],
    ],
  },
  {
    steps: [0, 77.3],
    colors: [
      [232, 10, 116, 255],
      [244, 79, 79, 255],
    ],
  },
  {
    steps: [2.6, 100],
    colors: [
      [239, 94, 91, 255],
      [216, 52, 49, 255],
    ],
  },
  {
    steps: [0, 100],
    colors: [
      [84, 165, 224, 255],
      [91, 226, 161, 214],
    ],
  },
  {
    steps: [3.6, 90.4],
    colors: [
      [121, 45, 129, 255],
      [36, 31, 98, 255],
    ],
  },
  {
    steps: [10.5, 86.8],
    colors: [
      [181, 239, 249, 255],
      [254, 254, 254, 255],
    ],
  },
  {
    steps: [11.2, 55.4, 100],
    colors: [
      [17, 151, 147, 255],
      [154, 214, 212, 255],
      [255, 233, 171, 255],
    ],
  },
  {
    steps: [11.8, 83.8],
    colors: [
      [20, 36, 50, 255],
      [124, 143, 161, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [151, 212, 209, 255],
      [8, 50, 160, 255],
    ],
  },
  {
    steps: [21.2, 92.1],
    colors: [
      [27, 13, 45, 255],
      [120, 64, 96, 255],
    ],
  },
  {
    steps: [11.3, 100],
    colors: [
      [75, 153, 245, 255],
      [177, 226, 254, 255],
    ],
  },
  {
    steps: [0, 0, 27.9, 60.1, 93.9],
    colors: [
      [141, 123, 228, 255],
      [147, 137, 246, 255],
      [83, 75, 197, 255],
      [54, 44, 165, 255],
      [39, 25, 78, 255],
    ],
  },
  {
    steps: [15.7, 74.1],
    colors: [
      [240, 73, 207, 255],
      [186, 90, 238, 255],
    ],
  },
  {
    steps: [11.2, 91.2],
    colors: [
      [231, 120, 120, 255],
      [246, 220, 111, 255],
    ],
  },
  {
    steps: [11.3, 37.6, 76.6, 100],
    colors: [
      [233, 53, 0, 255],
      [200, 19, 211, 255],
      [50, 142, 162, 255],
      [39, 138, 255, 255],
    ],
  },
  {
    steps: [1.4, 39.3, 68.4, 89.7, 99.9],
    colors: [
      [163, 197, 230, 255],
      [148, 150, 213, 255],
      [159, 139, 208, 255],
      [159, 121, 198, 255],
      [157, 101, 203, 255],
    ],
  },
  {
    steps: [13.0, 100],
    colors: [
      [255, 232, 27, 255],
      [255, 17, 17, 255],
    ],
  },
  {
    steps: [13.6, 65.3, 100],
    colors: [
      [141, 190, 134, 255],
      [230, 251, 198, 255],
      [255, 255, 255, 255],
    ],
  },
  {
    steps: [48.8, 94.9],
    colors: [
      [49, 75, 90, 255],
      [3, 155, 193, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [78, 84, 200, 255],
      [143, 148, 251, 255],
    ],
  },
  {
    steps: [13.8, 95.4],
    colors: [
      [0, 88, 143, 255],
      [21, 190, 240, 255],
    ],
  },
  {
    steps: [50.7, 100],
    colors: [
      [89, 0, 114, 255],
      [0, 124, 198, 255],
    ],
  },
  {
    steps: [90.8],
    colors: [[243, 237, 17, 255]],
  },
  {
    steps: [9.3, 100],
    colors: [
      [0, 166, 81, 255],
      [0, 209, 174, 255],
    ],
  },
  {
    steps: [7.8, 16.0, 32.7, 50.4, 71.5, 83.1],
    colors: [
      [78, 156, 226, 255],
      [62, 146, 69, 255],
      [251, 220, 23, 255],
      [242, 160, 35, 255],
      [253, 12, 12, 255],
      [192, 26, 129, 255],
    ],
  },
  {
    steps: [4.7, 38.2, 69.2, 100],
    colors: [
      [221, 91, 190, 255],
      [253, 96, 135, 255],
      [249, 172, 35, 255],
      [246, 249, 35, 255],
    ],
  },
  {
    steps: [1.4, 96.4],
    colors: [
      [55, 255, 226, 255],
      [0, 25, 167, 255],
    ],
  },
  {
    steps: [2.5, 31.5, 51.5, 69.8, 98.3],
    colors: [
      [0, 29, 137, 255],
      [0, 48, 165, 255],
      [0, 70, 195, 255],
      [0, 94, 224, 255],
      [0, 119, 255, 255],
    ],
  },
  {
    steps: [17.4, 34.7, 51.8, 67.4, 89.6],
    colors: [
      [68, 17, 28, 255],
      [95, 24, 40, 255],
      [122, 31, 51, 255],
      [149, 38, 62, 255],
      [163, 41, 68, 255],
    ],
  },
  {
    steps: [0.0, 100.0],
    colors: [
      [255, 78, 80, 255],
      [249, 212, 35, 255],
    ],
  },
  {
    steps: [12.3, 90.8],
    colors: [
      [64, 63, 111, 255],
      [69, 181, 170, 255],
    ],
  },
  {
    steps: [0.3, 0.3, 89.8],
    colors: [
      [242, 150, 150, 255],
      [242, 150, 150, 255],
      [255, 226, 173, 255],
    ],
  },
  {
    steps: [78.9],
    colors: [[63, 61, 61, 255]],
  },
  {
    steps: [0, 50.9, 99.8],
    colors: [
      [193, 145, 103, 255],
      [84, 20, 80, 244],
      [8, 52, 70, 255],
    ],
  },
  {
    steps: [17.4, 49.3, 83.4, 100],
    colors: [
      [68, 110, 92, 255],
      [107, 156, 120, 255],
      [154, 183, 130, 255],
      [247, 237, 191, 255],
    ],
  },
  {
    steps: [1.0, 70.9],
    colors: [
      [196, 214, 252, 255],
      [187, 187, 187, 0],
    ],
  },
  {
    steps: [],
    colors: [],
  },
  {
    steps: [27.3, 100],
    colors: [
      [216, 23, 100, 255],
      [63, 48, 48, 255],
    ],
  },
  {
    steps: [3.5, 96.6],
    colors: [
      [139, 166, 246, 255],
      [168, 240, 248, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [255, 180, 24, 255],
      [247, 49, 49, 255],
    ],
  },
  {
    steps: [4.7, 28.5, 70.8, 99.1],
    colors: [
      [14, 107, 147, 255],
      [69, 158, 170, 255],
      [150, 216, 203, 255],
      [217, 244, 239, 255],
    ],
  },
  {
    steps: [23.5, 91.3],
    colors: [
      [0, 81, 80, 255],
      [0, 134, 132, 255],
    ],
  },
  {
    steps: [11.1, 95.3],
    colors: [
      [255, 67, 5, 255],
      [245, 135, 0, 255],
    ],
  },
  {
    steps: [11.2, 91.1],
    colors: [
      [216, 78, 246, 255],
      [243, 77, 77, 255],
    ],
  },
  {
    steps: [0, 88.7],
    colors: [
      [34, 219, 231, 255],
      [52, 118, 246, 255],
    ],
  },
  {
    steps: [1.2, 100],
    colors: [
      [251, 166, 60, 255],
      [213, 57, 44, 255],
    ],
  },
  {
    steps: [1.2, 51.5, 95.7],
    colors: [
      [16, 136, 221, 255],
      [37, 11, 142, 255],
      [9, 17, 58, 255],
    ],
  },
];
