<div fullscreen [backgroundColor]="'rgb(243, 244, 246)'" [allowOverflow]="true">
  <div class="m-8">
    <div
      class="hidden lg:block rounded-md shadow-md fixed inset-8 bg-white overflow-y-auto"
      aria-hidden="true"
    >
      <header
        class="relative border-b border-gray-200 text-sm font-medium text-gray-700"
      >
        <div class="py-8 px-8">
          <div class="relative flex justify-end sm:justify-center">
            <a
              (click)="step = 0"
              class="absolute left-0 top-1/2 -mt-4 cursor-pointer"
            >
              <img src="assets/logo.svg" alt="" class="h-8 w-auto" />
            </a>

            @if (step === 1) {
              <div class="text-xl font-bold">Pick a design template</div>
            }
          </div>
        </div>
      </header>

      <main class="absolute top-24 bottom-0 left-0 right-0">
        @if (step === 0) {
          <div class="grid grid-cols-2 gap-4 2xl:px-24">
            <div
              class="col-span-2 text-center flex flex-col space-y-12 items-center justify-center mb-12"
            >
              <div class="flex flex-col space-y-2 items-center justify-center">
                <div class="text-2xl font-bold">
                  Edit the Dynamic Ads of {{ this.as.current.account.name }}
                </div>
                <div class="text-lg" style="max-width: 500px">
                  Optimize the design of your remarketing on Facebook and other
                  platforms using catalogs.
                </div>
              </div>
              <confect-button size="xxlarge" (click)="step = 1"
                >Try it live</confect-button
              >
            </div>
            <div class="max-h-full p-4">
              <h5 class="mb-4 text-center">Your current Dynamic Ads:</h5>
              <div class="flex flex-wrap justify-evenly">
                @for (p of products; track p) {
                  <div
                    class="w-5/12 aspect-square mb-12 cursor-pointer bg-white bg-no-repeat bg-contain bg-center shadow-md rounded-md border border-gray-200"
                    [lazyLoad]="p.images[0]"
                  ></div>
                }
              </div>
            </div>
            <div class="max-h-full p-4">
              <h5 class="mb-4 text-center">How they could look:</h5>
              <div class="flex flex-wrap justify-evenly">
                @for (t of templates; track t) {
                  <div
                    class="w-5/12 aspect-square mb-12 cursor-pointer bg-white bg-no-repeat bg-contain bg-center shadow-md rounded-md border border-gray-200"
                    [lazyLoad]="t.thumbnail"
                  ></div>
                }
              </div>
            </div>
          </div>
        }

        @if (step === 1) {
          <ngx-creatives-templates />
        }
      </main>
    </div>

    <div
      class="lg:hidden block rounded-md shadow-md fixed inset-0 bg-white overflow-y-auto"
    >
      <header
        class="relative border-b border-gray-200 text-sm font-medium text-gray-700"
      >
        <div class="py-8 px-8">
          <div class="relative flex justify-end sm:justify-center">
            <a
              (click)="step = 0"
              class="absolute left-0 top-1/2 -mt-4 cursor-pointer"
            >
              <img src="assets/logo.svg" alt="" class="h-8 w-auto" />
            </a>
          </div>
        </div>
      </header>

      <main class="absolute top-24 bottom-0 left-0 right-0">
        <div
          class="text-center flex flex-col space-y-12 items-center justify-center mb-12 px-4"
        >
          <div class="flex flex-col space-y-2 items-center justify-center">
            <div class="text-2xl font-bold">
              Edit the Dynamic Ads of {{ this.as.current.account.name }}
            </div>

            <div class="text-lg" style="max-width: 500px">
              Optimize the design of your remarketing on Facebook and other
              platforms supporting catalogs.
            </div>
          </div>
        </div>

        <div class="max-h-full">
          <h5 class="mb-4 text-center">How your ads could look:</h5>
          <div class="flex flex-wrap justify-evenly">
            @for (t of templates; track t) {
              <div
                class="w-5/12 aspect-square mb-4 cursor-pointer bg-white bg-no-repeat bg-contain bg-center shadow-md rounded-md border border-gray-200"
                [lazyLoad]="t.thumbnail"
              ></div>
            }
          </div>
        </div>

        <div class="bg-gray-100 sm:rounded-lg my-8">
          <div class="px-4 py-5 text-center">
            <p class="text-lg font-bold text-gray-700">
              Try it live on your computer by going to:
            </p>
            <p class="text-xl font-bold text-primary">
              confect.io/try/{{ this.as.current.account.name }}
            </p>
          </div>
        </div>

        <div class="max-h-full">
          <h5 class="mb-4 text-center">Your current Dynamic Ads:</h5>
          <div class="flex flex-wrap justify-evenly">
            @for (p of products; track p) {
              <div
                class="w-5/12 aspect-square mb-4 cursor-pointer bg-white bg-no-repeat bg-contain bg-center shadow-md rounded-md border border-gray-200"
                [lazyLoad]="p.images[0]"
              ></div>
            }
          </div>
        </div>
      </main>
    </div>
  </div>
</div>
