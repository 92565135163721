import { Component, Input, OnInit } from "@angular/core";

import {
  CreativeEditorMode,
  CreativeSpecItem,
} from "@core/models/creative.types";
import { CreativesEditService } from "@core/services/creatives/creatives-edit.service";

import * as uuid from "uuid";

import { CreativeError } from "../../types/creative-error";
import { SpecGroup } from "../spec-group-handler/spec-group-handler.component";

export interface SpecChangeEvent {
  newValue: any;
  shouldSnapshot: boolean;
}

@Component({
  selector: "ngx-spec-handler",
  templateUrl: "./spec-handler.component.html",
})
export class SpecHandlerComponent implements OnInit {
  effects = new Set<string>([
    // 'EffectAction',
    "EffectTransition",
    "Shadow",
    "Color",
    "AdvancedColor",
    "Gradients",
  ]);

  knownTypes = new Set<string>([
    "number",
    "percentage",
    "bool",
    "color",
    "string",
    "font",
    "position",
    "media",
    "product_subscription",
    "media_subscription",
    "EffectAction",
    "EffectTransition",
    "Shadow",
    "Color", // Color reference
    "AdvancedColor",
    "selection",
    "html",
    "Gradients",
    "gradient",
    "pattern",
    "product_asset",
  ]);

  CreativeEditorMode = CreativeEditorMode;

  videoOnlySpecs = new Set<string>(["media_mute"]);

  _specItems: CreativeSpecItem<any>[] = [];
  _specGroup: SpecGroup = null;
  @Input() set specGroup(to: SpecGroup) {
    this._specGroup = to;
    this.calculateSpec();
  }

  @Input() parentSelection: any = null;

  @Input() editor: CreativesEditService;

  @Input() raisedError: CreativeError = null;

  // The currently configured settings of the spec
  @Input() specSettings: any = {};

  @Input() helpEnabled = true;

  @Input() editorMode: CreativeEditorMode = CreativeEditorMode.IMAGE;

  hiddenItems = new Set(["total_duration", "layer_offset", "product_rounds"]);

  _limitOptions?: Set<string> = null;
  @Input() set limitOptions(to: Set<string>) {
    this._limitOptions = to;
    this.calculateSpec();
  }

  @Input() extraSettings: any = {};

  snapshotDebounceTimeout: NodeJS.Timeout;

  constructor() {}

  ngOnInit() {}

  calculateSpec() {
    const rawItems = this._specGroup?.items;

    if (!rawItems) {
      this._specItems = [];
      return;
    }

    // Has limiter if any options are given, otherwise no limits
    const hasLimiter = this._limitOptions != null;

    // Filters a specgroup to only consist of specs with a known type + are not hidden for legacy
    this._specItems = rawItems.filter((si) => {
      // When limiting to few options, exclude those not explicitly included
      if (hasLimiter) {
        if (!this._limitOptions.has(si.name)) {
          return false;
        }
      }

      // Ugly monkeypatch to hide border radius on all but squares
      let show = true;
      if (si.name === "border_radius") {
        show = this.specSettings["shape"] === "Square";
      }

      return this.knownTypes.has(si.value_type) && si.hidden !== true && show;
    });
  }

  getDefault(specItem) {
    // Clones the default if it's an object
    if (typeof specItem.default === "object") {
      return JSON.parse(JSON.stringify(specItem.default));
    }
    return specItem.default;
  }

  checkKeyedSettingsValue(specItem, key) {
    // Checks if a specItem we want to change by key has an existing default
    // Otherwise sets in config as the default

    // If not key, we're good
    if (key === null) {
      return;
    }

    // If some kind of value already exists at this positon, we're good
    const v = this.specSettings[specItem.name];
    // const v = this.extractSettingsValue(specItem);

    if (v !== undefined) {
      return;
    }

    // Set the default
    this.specSettings[specItem.name] = this.getDefault(specItem);
  }

  getSettingsValue(specItem) {
    const v = this.specSettings[specItem.name];
    if (v !== undefined) {
      // Return the set value
      return v;
    }

    if (specItem.value_type === "font") {
      return ["", ""];
    }

    return specItem.default;
  }

  setSettingsValue(specItem, value, key = null, snapshot = true) {
    if (snapshot) {
      if (!this.editor.history.prevState) {
        this.editor.history.beginLayerSnapshot();
      }
    }

    // Make sure a default exists if we're going to modify a key of an object
    this.checkKeyedSettingsValue(specItem, key);

    if (specItem.value_type === "font") {
      if (typeof this.specSettings[specItem.name] === "string") {
      }
    }

    // Edit either using key or just directly
    if (key !== null) {
      this.specSettings[specItem.name][key] = value;
    } else {
      this.specSettings[specItem.name] = value;
    }

    this.editor.specValueChanged(specItem);

    if (snapshot) {
      // Don't finish snapshotting the old value, as we are doing a new
      if (this.snapshotDebounceTimeout) {
        clearInterval(this.snapshotDebounceTimeout);
        this.snapshotDebounceTimeout = null;
      }

      // Debounced snapshot in x ms
      this.snapshotDebounceTimeout = setTimeout(() => {
        this.editor.history.finishLayerSnapshot();
      }, 250);
    }
  }

  setSettingsValueV2(specItem, event: SpecChangeEvent, key = null) {
    this.setSettingsValue(specItem, event.newValue, key, event.shouldSnapshot);
  }

  getCellInLayer(layer, row, col, create = false) {
    // Check if current cell exists, otherwise create
    const gc = layer.config.grid_config;
    const c = gc.filter((cell) => cell.pos[0] === row && cell.pos[1] === col);

    if (c.length === 0) {
      return null;
    }

    // Return cell
    return c[0];
  }

  didSelectRefGridItem(specItem, layer, row, col) {
    const c = this.getCellInLayer(layer, row, col);

    if (!c) {
      return;
    }

    if (layer["identifier"] === undefined) {
      layer["identifier"] = uuid.v4();
    }

    // Make the ref
    this.setSettingsValue(specItem, {
      layer: layer.identifier,
      pos: [row, col],
    });
  }
}
