<confect-select
  [placeholder]="'None'"
  [ngModel]="value"
  (ngModelChange)="valueChange.emit($event)"
  [name]="specItem.key"
  [title]="specItem.display"
  [options]="specItem.options"
  [clearable]="!specItem.default"
  [icon]="specItem.icon"
/>
