<div class="flex items-center justify-center space-x-4 mb-5">
  <span class="relative z-0 inline-flex shadow-sm rounded-md">
    <button
      type="button"
      class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      [ngClass]="{
        'bg-secondary text-white': value.pos.includes('LEFT'),
        'bg-white hover:bg-gray-100': !value.pos.includes('LEFT'),
      }"
      (click)="alignmentChange('h', 'LEFT')"
    >
      <confect-icon icon="align_to_left_vs" />
    </button>
    <button
      type="button"
      class="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      [ngClass]="{
        'bg-secondary text-white': value.pos.includes('CENTER'),
        'bg-white hover:bg-gray-100': !value.pos.includes('CENTER'),
      }"
      (click)="alignmentChange('h', 'CENTER')"
    >
      <confect-icon icon="align_to_center_vs" />
    </button>
    <button
      type="button"
      class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      [ngClass]="{
        'bg-secondary text-white': value.pos.includes('RIGHT'),
        'bg-white hover:bg-gray-100': !value.pos.includes('RIGHT'),
      }"
      (click)="alignmentChange('h', 'RIGHT')"
    >
      <confect-icon icon="align_to_right_vs" />
    </button>
  </span>

  <span class="relative z-0 inline-flex shadow-sm rounded-md">
    <button
      type="button"
      class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      [ngClass]="{
        'bg-secondary text-white': value.pos.includes('TOP'),
        'bg-white hover:bg-gray-100': !value.pos.includes('TOP'),
      }"
      (click)="alignmentChange('v', 'TOP')"
    >
      <confect-icon icon="align_to_top_vs" />
    </button>
    <button
      type="button"
      class="-ml-px relative inline-flex items-center px-2 py-2 border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      [ngClass]="{
        'bg-secondary text-white': value.pos.includes('MIDDLE'),
        'bg-white hover:bg-gray-100': !value.pos.includes('MIDDLE'),
      }"
      (click)="alignmentChange('v', 'MIDDLE')"
    >
      <confect-icon icon="align_to_middle_vs" />
    </button>
    <button
      type="button"
      class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      [ngClass]="{
        'bg-secondary text-white': value.pos.includes('BOTTOM'),
        'bg-white hover:bg-gray-100': !value.pos.includes('BOTTOM'),
      }"
      (click)="alignmentChange('v', 'BOTTOM')"
    >
      <confect-icon icon="align_to_bottom_vs" />
    </button>
  </span>
</div>
