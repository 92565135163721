import { Injectable } from "@angular/core";

import { DashboardUserInformation } from "@core/api-models/sharing.models";

import { Observable } from "rxjs";

import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class SharingService extends ApiService {
  // Sharing
  getSharingInfo(code): Observable<any> {
    return this.http.get(`${this.baseURL}/meta/sharing/info`, {
      params: { code: code },
    });
  }

  generateCreativeShareLink(spec, settings): Observable<any> {
    return this.http.post(
      `${this.baseURL}/meta/sharing/generate-creative-link`,
      {
        spec: spec,
        settings: settings,
      },
    );
  }

  generateFlowShareLink(flowId): Observable<any> {
    return this.http.post(`${this.baseURL}/meta/sharing/generate-flow-link`, {
      flow_id: flowId,
    });
  }

  analyticsAccessed(
    info: DashboardUserInformation,
  ): Observable<{ success: boolean }> {
    return this.http.post<{ success: boolean }>(
      `${this.endpoint}/sharing/analytics`,
      info,
    );
  }
}
