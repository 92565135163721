<!-- <ngx-media-library
  #mediaLayerLibrary
  (mediaSelected)="mediaLayerSelect($event)"
  [enabledMediaSources]="[
    { key: 'uploaded_images', scope: 'private' },
    { key: 'uploaded_videos', scope: 'private' }
  ]"
></ngx-media-library> -->

<ng-template #mediaLibrary let-close="close">
  <div class="h-[65vh] w-[65vw] divide-y">
    <div class="flex justify-end w-full mb-2 h-[7%]">
      <confect-button size="xlarge" (click)="uploadMedia()"
        >Upload</confect-button
      >
    </div>
    <div class="h-[93%] overflow-y-auto overflow-x-hidden">
      <div class="my-2">
        <ngx-media-overview
          #overview
          [radioMode]="false"
          mode="submit"
          folderType="media_visual"
          type="visual"
          (mediaSelect)="mediaLayerSelect($event); close(true)"
        />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #effectAdd let-close="close">
  <div class="min-w-[760px] min-h-[800px]">
    <ngx-effects
      [select]="effectSelected"
      [duration]="$any(_editor.layer.LAYER.total_duration) ?? 6"
      [animationConfig]="_editor.layer.config.grid_config[0].config"
      (save)="
        this._editor.updateLayerEffects(_editor.layer.identifier, $event);
        close(true)
      "
      [socket]="_editor?.live?.socketService"
    />
  </div>
</ng-template>

<ng-template #layerAdd>
  <div class="layer-type-template">
    @for (cellType of cellTypes; track cellType) {
      <div class="w-full" style="margin-bottom: 4px; margin-top: 4px">
        <button mat-stroked-button (click)="addLayer(cellType)">
          <confect-icon
            [icon]="cellType.icon"
            style="margin-right: 6px; margin-top: -2px"
          />{{ cellType.name }}
        </button>
      </div>
    }
  </div>
</ng-template>

<ng-template #settings let-close="close">
  <div class="flex flex-col space-y-2 w-[400px]">
    <div class="flex items-center">
      <div class="w-1/2">Duration</div>
      <div class="w-1/2">
        <confect-slider
          #duration
          [ngModel]="editor.layer.LAYER['total_duration'] ?? 6"
          [sliderInfo]="{
            interval: { start: 0, end: 20 },
            stepSize: 0.2,
          }"
        />
      </div>
    </div>
    <div class="flex items-center">
      <div class="w-1/2">Offset</div>
      <div class="w-1/2">
        <confect-slider
          #offset
          [ngModel]="_editor.layer.LAYER['layer_offset'] ?? 0"
          [sliderInfo]="{
            interval: {
              start: 0,
              end: 19.8,
            },
            stepSize: 0.2,
          }"
        />
      </div>
    </div>

    <div class="flex justify-end items-center">
      <confect-button
        type="secondary"
        (click)="
          _editor.updateLayerTimes(
            _editor.layer.identifier,
            offset.actValue(),
            duration.actValue()
          );
          close()
        "
        >Apply</confect-button
      >
    </div>
  </div>
</ng-template>

<div class="flex w-full justify-center h-full" style="padding-bottom: 0px">
  <div
    class="w-full"
    [ngClass]="{
      hidden: showTimeline,
      block: !showTimeline,
    }"
  >
    <div class="flex items-center justify-center h-7 mb-3 w-full">
      @for (cell of cellTypes; track cell) {
        @if (cell.type !== "product" || allowProductActions) {
          <confect-icon
            [icon]="cell.icon"
            tooltip
            [tooltipText]="'Add ' + cell.name"
            tooltipPosition="above"
            iconClass="mr-1 text-xl text-gray-600 cursor-pointer hover:bg-gray-200 rounded-full p-2"
            (click)="addLayer(cell)"
          />
        }
      }
    </div>

    <ngx-creatives-edit-timeline-layer-handler
      [editor]="_editor"
      [live]="live"
      [layers]="_editor.sequence.layers"
      [specs]="specs"
      [raisedError]="raisedError"
      [showTimeline]="showTimeline"
      [allowProductActions]="allowProductActions"
      (context)="context.emit($event)"
    />
  </div>

  <div
    class="flex w-full h-full items-center justify-center pl-2"
    [ngClass]="{ block: showTimeline, hidden: !showTimeline }"
  >
    <div class="h-full flex items-center justify-center">
      <div class="flex flex-col bg-white h-full items-center justify-center">
        @for (cell of cellTypes; track cell) {
          @if (cell.type !== "product" || allowProductActions) {
            <confect-button
              type="subtle"
              size="xsmall"
              (click)="addLayer(cell)"
            >
              <confect-icon
                [icon]="cell.icon"
                tooltip
                [tooltipText]="'Add ' + cell.name"
                tooltipPosition="right"
                iconClass="text-lg 2xl:text-2xl"
            /></confect-button>
          }
        }
      </div>
    </div>
    <div class="w-full h-full pl-2 pr-5 py-5">
      <div
        class="bg-slate-50 p-2 shadow-inner rounded-md w-full h-full overflow-hidden"
      >
        @if (showTimeline) {
          <ngx-timeline
            [timelineSpec]="timelineSpecs"
            [selection]="selectedLayers"
            (selectChange)="
              _editor.selectLayerClick($event.clickEvent, $event.id)
            "
            (commit)="commitLayerTimes($event)"
            (cursorChange)="_editor.setTime($event)"
            (context)="
              _editor.selectLayerClick($event.event, $event.block);
              context.emit({ event: $event.event, layer: _editor.layer })
            "
            (effect)="effectSelected = $event; addEffect()"
            (layerHover)="
              _editor.layerHoverLayerListSubject.next(
                $event == null ? null : _editor.getLayer($event)
              )
            "
            (deselect)="allowDeselect && _editor.deselectLayer()"
            (moveLayerDown)="
              _editor.moveLayer(_editor.getLayer($event), 'down')
            "
            (moveLayerUp)="_editor.moveLayer(_editor.getLayer($event), 'up')"
            (groupLayers)="groupLayers($event.ids, $event.dir)"
            (ungroupLayer)="
              _editor.ungroupLayer(_editor.getLayer($event.id), $event.dir)
            "
            (settings)="openSettings()"
            [hoverObservable]="_editor.canvasHoverLayerListSubject"
          />
        }
      </div>
    </div>
  </div>
</div>
