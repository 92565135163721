<div class="flex flex-col w-full h-full">
  <h2 class="flex items-center justify-start">Gradient Library</h2>
  <div class="w-full overflow-auto" (scroll)="onScroll($event)">
    <div class="grid grid-cols-4 gap-5 overflow-auto">
      @for (m of gradients; track m; let i = $index) {
        <div
          class="flex rounded group aspect-square"
          [style.background]="toCSSGradient(m) | safe: 'style'"
        >
          <div
            class="group-hover:block hidden mt-5 ml-5 opacity-70 hover:opacity-100"
          >
            <confect-button type="white" (click)="selectGradientClicked(m)">
              <confect-icon icon="checkmark_outlined"> Select </confect-icon>
            </confect-button>
          </div>
        </div>
      }
    </div>
  </div>
  <div></div>
</div>
