<div class="form-group row">
  <label for="inputSpec" class="col-sm-4 col-form-label">
    @if (specItem.icon) {
      <i class="fas fa-{{ specItem.icon }}"></i>
    }
    {{ specItem.display }}
  </label>
  <div class="col-sm-8">
    <input
      type="text"
      class="form-control"
      [placeholder]="specItem.default"
      [value]="value"
      (input)="valueChange.emit($any($event.target).value)"
    />
  </div>
</div>
