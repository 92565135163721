export const resolutions = {
  "1:1": {
    name: "1:1",
    size: [1080, 1080],
    preview: false,
  },

  /*    {
            'name': '4:5',
            'size': [1080, 1350],
            'preview': true,
        },*/
  "4:5": {
    name: "4:5",
    size: [1080, 1350],
    preview: false,
  },

  /*    {
            'name': '16:9',
            'size': [1920, 1080],
            'preview': true,
        },*/
  "16:9": {
    name: "16:9",
    size: [1920, 1080],
    preview: false,
  },

  /*    {
            'name': '9:16',
            'size': [1080, 1920],
            'preview': true,
        },*/
  "9:16": {
    name: "9:16",
    size: [1080, 1920],
    preview: false,
  },

  "2:3": {
    name: "9:16",
    size: [1000, 1500],
    preview: false,
  },

  custom: {
    name: "Custom",
    size: [500, 500],
    preview: false,
  },
};
